.accordion-pdp-item__item {
    padding: 20px 0;
}

.accordion-pdp-item__column {
    padding: 16px 0;
}

.ratings-item {
    @include mq($from: tablet) {
        padding: 16px 72px;
    }
}

.ratings-item__holder {
    display: block;
    @include mq($from: tablet) {
        display: flex;
        flex-direction: row;
    }
    width: 100%;
    border-bottom: 1px solid var(--theme-attention);
}

.ratings-item__total-no {
    color: var(--theme-attention);
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 0;
}

.ratings-item__first-holder {
    padding: 0;
    width: 100%;
    @include mq($from: tablet) {
        width: 15%;
        flex-shrink: 0;
        padding-left: 0;
    }
}

.ratings-item__second-holder {
    margin-top: 12px;
    width: 84%;
}

.ratings-item__total-reviews {
    color: var(--theme-attention);
    font-weight: 500;
    font-size: 15px;
}

.ratings-item__top-review {
    font-size: 18px;
    font-weight: 500;
    color: var(--theme-attention);
}

.ratings-item__show-more-holder {
    text-align: center;
    margin-top: 21px;
}

.ratings-item__show-more {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--theme-attention);
    padding-bottom: 8px;
    font-size: 16px;
    color: var(--theme-attention);
    margin-bottom: 30px;
}

.ratings-item__show-more:hover {
    cursor: pointer;
}

.ratings-item__pagination {
    margin-top: 25px;
}

.ratings-item__loading {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30%;
    z-index: 9999;
}

.ratings-item__loader {
    border: 16px solid var(--theme-tertiary);
    border-top: 16px solid var(--theme-primary);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
