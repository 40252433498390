.content-slider {
    padding: 20px 0;

    @include mq($from: tablet) {
        padding: 40px 0;
    }
}

.content-slider__slide {
    overflow: hidden;
}
