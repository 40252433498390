.select-button {
    display: flex;
    background: var(--theme-attention);
}

.select-button--disabled {
    opacity: 0.5;
}

.select-button--loading {
    opacity: 0.7;
}

.select-button__select {
    flex: 0 0 auto;
    min-width: 94px;
}

.select-button__button {
    appearance: none;
    background: none;
    border: none;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 4px;
    cursor: pointer;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--color-select-button);

    .select-button--disabled &,
    .select-button--loading & {
        cursor: default;
    }
}

.select-button__icon {
    margin-left: 15px;
    font-size: 17px;
}
