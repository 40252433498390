.checkbox {
    display: grid;
    grid-template-areas:
        'box label'
        '. error';
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    position: relative;
    color: var(--color-checkbox);
}

.checkbox__input {
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    position: absolute;
}

.checkbox__box--small {
    width: 15px;
    height: 15px;
    border: 1px solid var(--color-border-checkbox);
    padding: 3px;
}

.checkbox__box--medium {
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-border-checkbox);
    padding: 3px;
}

.checkbox__box--large {
    width: 40px;
    height: 40px;
    border: 2px solid var(--color-border-checkbox);
    padding: 5px;
}

.checkbox__box--error {
    border-color: var(--input-error);
}

.checkbox__box {
    grid-area: box;
    margin-right: 20px;
    background: var(--bg-checkbox);

    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox__checked {
    width: 100%;
    height: 100%;
    color: var(--color-checkbox);
}

.checkbox__label {
    grid-area: label;
    color: var(--color-text);

    p:first-of-type {
        margin: 4px 0;
    }

    a {
        text-decoration: underline;
        color: var(--color-checkbox-label);
        font-weight: var(--fw-bold);
    }
}

.checkbox__error-message {
    grid-area: error;
    color: var(--input-error);
    font-size: var(--fs-sm);
}
