.cart-summary {
    padding: 15px;
    border-top: 2px solid var(--bg-cart-summary);

    @include mq($from: tablet) {
        border-top: 0;
        background: var(--bg-cart-summary);
    }
}

.cart-summary__content {
    padding: 15px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.cart-summary__subtotal {
    @extend .cart-summary__total;
    margin-bottom: 4px;
}

.cart-summary__subtotal-label {
    font-size: var(--fs-l);
    margin-right: 1ch;
}

.cart-summary__subtotal-price {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
    color: var(--color-cart-summary-total);
}

.cart-summary__delivery {
    @extend .cart-summary__total;
    margin-bottom: 10px;
}

.cart-summary__delivery-label {
    font-size: var(--fs-l);
    font-weight: var(--fw-regular);
    color: var(--color-checkout-delivery-cost);
    margin-right: 1ch;
}

.cart-summary__delivery-price {
    font-size: var(--fs-l);
    font-weight: var(--fw-regular);
    color: var(--color-cart-summary-total);
}

.cart-summary__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.cart-summary__savings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-cart-summary-savings);
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
    margin-bottom: 10px;
}

.cart-summary__savings-price {
    color: var(--color-cart-summary-savings);
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
}

.cart-summary__label {
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
    line-height: 1;
    word-break: break-word;
    hyphens: auto;
}

.cart-summary__total-label {
    margin-right: 1ch;
}

.cart-summary__tax-info {
    display: inline-block;
    font-size: var(--fs-s);
    font-weight: var(--fw-regular);
    white-space: nowrap;
    hyphens: auto;
}

.cart-summary__price {
    white-space: nowrap;
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    color: var(--color-cart-summary-total);
    font-family: var(--font-product-price);
}

.cart-summary__shipping-cost {
    width: calc(100% - 19px);
    margin-bottom: 10px;
}

.cart-summary__checkout-button {
    width: 100%;
}

.cart-summary--sticky {
    padding: 0;
    background: var(--bg-cart-product);

    .cart-summary__content {
        padding: 4px 15px;
    }

    .cart-summary__total {
        .cart-summary__price {
            font-size: var(--fs-l);
        }
    }

    .cart-summary__shipping-cost {
        width: 100%;
        padding: 4px 15px;
    }
}
