/* stylelint-disable declaration-no-important */
.select-box {
    position: relative;
    width: 100%;

    @include mq($from: tablet) {
        width: 250px;
    }
}

.select-box__selected-value-wrap {
    position: relative;
    display: flex;
    cursor: pointer;
    border: 1px solid #b3b3b3;
    justify-content: space-between;
    align-items: center;

    &--disabled {
        cursor: auto;

        .select-box__selected-value-icon {
            display: none;
        }
    }
}

.select-box__selected-value {
    padding: 8px 0 8px 16px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.select-box__selected-value-icon {
    border-left: 1px solid #b3b3b3;
    width: 36px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        color: #b3b3b3;
        font-size: 12px !important;
    }
}

.select-box__options {
    position: absolute;
    max-height: 288px;
    width: 100%;
    background: var(--color-white-true);
    z-index: 10;
    overflow-y: auto;
    border: 1px solid #b3b3b3;
    border-top: none;
}

.select-box__options-item {
    cursor: pointer;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        background: var(--bg-select-box-hover);
    }

    &--selected {
        background: var(--bg-select-box-selected) !important;
        color: var(--color-white-true);
    }
}
