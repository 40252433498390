.image-banner {
    img {
        width: 100%;
        height: auto;
    }
}

.image-banner__teaser-box {
    padding: 20px;

    &--with-box-shadow {
        box-shadow: var(--box-shadow-default);
    }
}

.image-banner__teaser-box--mobile {
    width: 100%;
}

.image-banner__teaser-box--desktop {
    position: absolute;
    overflow-y: auto;
}

.image-banner__teaser-box--mobile-container {
    display: flex;
    flex-direction: column;
}

.image-banner__teaser-box--mobile-container-reverse {
    display: flex;
    flex-direction: column-reverse;
}
