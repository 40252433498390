h1,
.h1,
.title-h1 {
    font-size: var(--fs-h1-mobile);
    font-weight: var(--fw-semibold);
    line-height: var(--lh-small);

    @include mq($from: tablet) {
        font-size: var(--fs-h1);
    }
}

.title-h1-sub {
    font-size: var(--fs-h1-sub-mobile);
    font-weight: var(--fw-semibold);

    @include mq($from: tablet) {
        font-size: var(--fs-h1-sub);
    }
}

h2,
.h2,
.title-h2 {
    font-size: var(--fs-h2-mobile);
    font-weight: var(--fw-semibold);

    @include mq($from: tablet) {
        font-size: var(--fs-h2);
    }
}

.title-h2-sub {
    font-size: var(--fs-h2-sub-mobile);
    font-weight: var(--fw-semibold);

    @include mq($from: tablet) {
        font-size: var(--fs-h2-sub);
    }
}

h3,
.h3,
.title-h3 {
    font-size: var(--fs-h3-mobile);
    font-weight: var(--fw-semibold);

    @include mq($from: tablet) {
        font-size: var(--fs-h3);
    }
}

.title-h3-sub {
    font-size: var(--fs-h3-sub-mobile);
    font-weight: var(--fw-medium);

    @include mq($from: tablet) {
        font-size: var(--fs-h3-sub);
    }
}

h4,
.h4,
.title-h4 {
    font-size: var(--fs-h4-mobile);
    font-weight: var(--fw-medium);

    @include mq($from: tablet) {
        font-size: var(--fs-h4);
    }
}

.title-h4-sub {
    font-size: var(--fs-h4-sub-mobile);
    font-weight: var(--fw-medium);

    @include mq($from: tablet) {
        font-size: var(--fs-h4-sub);
    }
}

h5,
.h5,
.title-h5 {
    font-size: var(--fs-h5-mobile);
    font-weight: var(--fw-normal);

    @include mq($from: tablet) {
        font-size: var(--fs-h5);
    }
}

.title-h5-sub {
    font-size: var(--fs-h5-sub-mobile);
    font-weight: var(--fw-medium);

    @include mq($from: tablet) {
        font-size: var(--fs-h5-sub);
    }
}

h6,
.h6,
.title-h6 {
    font-size: var(--fs-h6-mobile);
    font-weight: var(--fw-light);

    @include mq($from: tablet) {
        font-size: var(--fs-h6);
    }
}
