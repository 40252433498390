.personal-info-password__title {
    margin-bottom: 15px;
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.personal-info-password__text {
    margin-bottom: 15px;

    p {
        margin-top: 0;
    }
}
