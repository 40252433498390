.express-checkout {
    padding: 0 0 50px;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin: 10px auto auto;

    @include mq($from: desktop) {
        width: 800px;
    }
}

.express-checkout__panel {
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin-top: 20px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.express-checkout__headline-banner {
    padding: 10px;
    background-color: var(--color-gray-300);
    font-size: var(--fs-l);
    font-weight: var(--fw-semibold);
    margin-bottom: 20px;
}

.express-checkout__shipping-address-form {
    padding: 10px;
    border-bottom: 1px solid var(--color-gray-400);
}

.express-checkout__shipping-address-title {
    font-weight: var(--fw-bold);
}

.express-checkout__payment-info,
.express-checkout__products {
    margin-bottom: 20px;
}

.express-checkout__footer {
    margin-top: 15px;
    color: var(--color-gray-500);
}

.express-checkout__footer-legal-text {
    padding-bottom: 15px;
    font-size: 0.9em;
}

.express-checkout__footer-legal-text a {
    text-decoration: underline;
    color: var(--color-gray-500);
    font-weight: 600;
}

.express-checkout__legal-opt-in {
    font-size: 0.9em;
}

.express-checkout__legal-opt-in .checkbox__label {
    color: var(--color-gray-500);
}

.express-checkout__legal-opt-in .checkbox__box {
    border-color: var(--color-gray-500);
}

.express-checkout__delivery-info {
    font-size: var(--fs-18);
    font-weight: var(--fw-semibold);
    margin-top: 25px;
}
