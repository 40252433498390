.login-form {
    grid-area: loginForm;
    margin-top: 1rem;
}

.login-form__email,
.login-form__password {
    min-height: 70px;
}

.login-form__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(138, 138, 138, 0.3);
    z-index: var(--zi-login-form-loading);
}

.login-form__loader {
    border: 16px solid var(--theme-tertiary); /* Light grey */
    border-top: 16px solid var(--theme-primary); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.login-form__login-cta {
    grid-area: loginLinks;
    display: flex;
    flex-direction: column;
}

.login-form__login-forgot a {
    text-decoration: underline;
    color: var(--color-black);
}

.login-form__login-problems {
    margin-top: 10px;

    a {
        text-decoration: underline;
        color: var(--color-black);
    }
}

.login-form__login-button {
    grid-area: loginCta;
    width: 100%;
}

.login-form__full-name {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
