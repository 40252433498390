.download-asset {
    padding: 10px;
}

.download-asset__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 175px;
    color: var(--theme-primary);

    &:visited {
        color: var(--theme-primary);
    }
}

.download-asset__image-loading {
    background-color: #ededed;
    // stylelint-disable-next-line declaration-block-no-shorthand-property-overrides
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

.download-asset__image {
    margin-bottom: 10px;
    aspect-ratio: 2/3;
    object-fit: cover;
    width: 175px;
    border-radius: var(--border-radius-default);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.download-asset__name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
