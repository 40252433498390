.burger-menu-navigation-item {
    font-size: var(--burger-menu-font-size);
    user-select: none;
    list-style: none;
    padding: 4px 0;
    margin: 0;
}

.burger-menu-navigation-item__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--theme-headlines);
    font-weight: var(--desktop-navigation-font-weight);

    &--is-open {
        .burger-menu-navigation-item__arrow {
            transform: rotate(180deg);
        }
    }
}

.burger-menu-navigation-item__title--has-children {
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: var(--main-navigation-custom-active-color);

            .burger-menu-navigation-item__link {
                color: var(--main-navigation-custom-active-color);
            }
        }
    }
}

.burger-menu-navigation-item__link {
    color: var(--main-navigation-custom-font-color);

    &:hover,
    &--active {
        color: var(--main-navigation-custom-active-color);
    }
}

.burger-menu-navigation-item__arrow {
    font-size: 18px;
    transition: transform 0.3s ease;
}
