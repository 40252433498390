.chevron-button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 17px;

    &:focus {
        outline: none;
    }
}
