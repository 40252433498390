.price-box {
    display: flex;
    flex-direction: column;
    font-weight: var(--fw-semibold);
    gap: 8px;
}

.price-box--align-right {
    align-items: flex-end;
}

.price-box--align-left {
    align-items: flex-start;
}

.price-box__price {
    font-size: var(--fs-xxl);
    color: var(--theme-primary);
    font-family: var(--font-product-price);
    white-space: nowrap;
}

.price-box--small {
    font-size: var(--fs-s);

    .price-box__price {
        font-size: var(--fs-xl);
    }
}
