.burger-menu-sub-navigation {
    display: none;
    background: var(--main-navigation-custom-dropdown-background-color);
    list-style: none;
    margin: 4px -14px 0;
    padding: 10px 24px;

    &--open {
        display: block;
    }
}

.burger-menu-sub-navigation__item {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    font-weight: var(--desktop-navigation-item-font-weight);

    &--headline {
        font-weight: var(--desktop-navigation-headline-font-weight);
    }


    &--indented {
        .burger-menu-sub-navigation__link::before {
            content: '›';
            padding-right: 4px;
            color: var(--main-navigation-custom-font-color);
        }
    }
}

.burger-menu-sub-navigation__link {
    color: var(--main-navigation-custom-font-color);

    &:hover{
        color: var(--main-navigation-custom-active-color);

        &::before {
            color: var(--main-navigation-custom-active-color);
        }
    }
}
