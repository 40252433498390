.club-modal__banner {
    width: 100%;
    padding: 16px 24px;
    background: var(--theme-secondary);
    color: var(--theme-primary);
    border-radius: var(--border-radius-product-image-container);
}

.club-modal__banner-headline {
    font-size: var(--fs-xl);
    font-weight: 500;
}

.club-modal__banner-sub-headline {
    font-size: var(--fs-s);
}

.club-modal__banner-line {
    background: var(--theme-primary);
    height: 16px;
}

.club-modal__headline {
    font-size: var(--fs-l);
    margin: 16px 0;
}

.club-modal__text {
    font-size: var(--fs-base);
    margin-bottom: 10px;
}

.club-modal__button {
    color: var(--theme-primary);
    background: var(--white);
    border: 2px solid var(--theme-primary);
    border-radius: 0;
    padding-left: 14px;
    padding-right: 14px;
}
