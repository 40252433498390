.user-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    gap: 8px;

    > * {
        padding: 4px;
    }

    .country-switch {
        margin: 0;
        padding: 0;

        @include mq($until: tablet) {
            position: inherit;
        }

        .dropdown {
            margin: 10px 0;

            @include mq($from: tablet) {
                margin: 0;
            }
        }

        /* stylelint-disable-next-line selector-class-pattern */
        .dropdown__header .icon--ChevronDown {
            display: none;

            @include mq($from: tablet) {
                display: inline-block;
            }
        }
    }

    .country-switch__select-container {
        left: -14px;

        @include mq($until: tablet) {
            top: unset;
            left: 0;
            width: 100vw;
            border-left: none;
            border-right: none;
        }
    }

    .icon {
        fill: var(--color-icons);
        color: var(--color-icons);
        font-size: 25px;
        display: block;
    }
}

.user-menu__account {
    position: relative;
    z-index: var(--zi-login-dropdown);

    &:hover .login-dropdown {
        display: block;
    }
}

.user-menu__cart-link {
    position: relative;
    padding: 4px;
}

.user-menu__cart-item-amount {
    position: absolute;
    top: 16px;
    left: 18px;
    width: 20px;
    height: 20px;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--theme-primary);
    color: var(--top-header-cta-color);
}
