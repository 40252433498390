.shop-switch-modal__body {
    overflow: initial;
    @include mq($until: tablet) {
        margin: 0 20px;
        padding: 10px;
    }
}

.shop-switch-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.shop-switch-modal__headline {
    font-size: var(--fs-h1-mobile);
    font-weight: var(--fw-shop-switch-headline);
    line-height: 40px;
    margin-bottom: 20px;
}

.shop-switch-modal__sub-headline {
    font-size: var(--fs-h3-mobile);
    font-weight: var(--fw-shop-switch-sub);
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.shop-switch-modal__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    @include mq($from: tablet) {
        width: 70%;
    }

    .select-box {
        .icon {
            font-size: 25px;
        }
    }

    .select-box__selected-value-wrap {
        background: var(--color-gray-300);
        border-color: var(--color-gray-500);
    }
}

.shop-switch-modal__content-container--button {
    margin: 20px;
    background: var(--color-black-true);
    color: var(--color-white-true);
    border-radius: 4px;
    width: 100%;
}

.shop-switch-modal__disable-scroll {
    overflow: hidden;
}

.shop-switch-modal__flag {
    margin: 0 5px;
    position: relative;
    top: 2px;
    font-size: var(--fs-shop-switch-flag);
}

.shop-switch-modal__width {
    max-width: 450px;
}

.shop-switch-modal__close-modal-text {
    font-weight: var(--fw-shop-switch-link);
    cursor: pointer;
}

.shop-switch-modal__select-disable {
    cursor: default;

    .select__control {
        pointer-events: none;
    }

    .select__indicators {
        display: none;
    }
}
