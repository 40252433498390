.before-after-image {
    position: relative;
    overflow: hidden;
    user-select: none;
    touch-action: pan-y;

    &__image {
        &-before {
            position: absolute;
            overflow: hidden;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            border-right: 6px solid var(--color-white-true);

            .responsive-image__img {
                height: 100%;
                width: auto;
                object-fit: cover;
                object-position: left;
            }
        }

        &-after {
            z-index: 1;

            .responsive-image__img {
                width: 100%;
            }
        }
    }

    &__slider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        outline: none;
        z-index: 3;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 6px;
            height: 800px;
            background: transparent;
            cursor: grab;
        }

        &::-moz-range-thumb {
            width: 6px;
            height: 800px;
            background: transparent;
            cursor: grab;
        }
    }

    &__slider-button {
        pointer-events: none;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--color-white-true);
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        z-index: 3;

        &::after, &::before {
            content: '';
            padding: 3px;
            display: inline-block;
            border: solid var(--theme-dark);
            border-width: 0 2px 2px 0;
            transform: rotate(-45deg);
        }

        &::before {
            transform: rotate(135deg);
        }
    }
}
