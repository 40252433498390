.mpc-summary-products__line-item {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: var(--fs-s);
}

.mpc-summary-products__line-item-change {
    cursor: pointer;
    text-decoration: underline;
}

.mpc-summary-products__divider {
    border-bottom: 1px solid var(--color-gray-400);
}

.mpc-summary-products__image {
    width: 80px;
    height: 80px;
    border-radius: var(--border-radius-default);
    margin-right: 15px;
}

.mpc-summary-products__product-name {
    color: var(--theme-primary);
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.mpc-summary-products__price-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 3px;
}
