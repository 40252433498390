.product-data__details-container {
    display: flex;
    flex-direction: column;
    min-height: 270px;
    height: 100%;
    padding: 8px 12px;

    @include mq($from: tablet) {
        min-height: 285px;
        padding: 16px;
    }

    .product-data__old-price + .product-data__price {
        margin-top: -8px;
    }
}

.product-data__reviews-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
}

.product-data__review-count {
    color: var(--color-star-text);
    margin-left: 4px;
    font-size: 12px;
    margin-top: 2px;
}

.product-data__delivery {
    font-size: 12px;
    font-weight: var(--fw-normal);
    display: flex;
    align-items: center;
    margin: 4px 0;
    word-wrap: break-word;

    .product-data__delivery-days::before {
        @include mq($until: 400px) {
            content: '\a';
            white-space: pre-wrap;
        }
    }

    .product-data__delivery-days {
        color: var(--theme-success);
    }

    @include mq($until: tablet) {
        align-items: flex-end;
    }

    .product-data__icon {
        min-width: 13px;
        height: 13px;
        color: var(--color-white-true);
        background: var(--color-green-500);
        border-radius: 50%;
        padding: 3px;
        font-size: 13px;
        margin-left: 4px;
    }
}

.product-data__details {
    margin-top: auto;
}

.product-data__infos {
    margin-top: auto;
}

.product-data__price {
    font-family: var(--font-product-price);
    font-weight: 500;
    font-size: 20px;
    color: var(--theme-dark);

    &--sale {
        color: var(--color-red);
    }

    @include mq($from: tablet) {
        font-size: 23px;
    }
}

.product-data__marketing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 23px;

    @include mq($until: 370px) {
        white-space: pre-wrap;
        text-align: left;
    }

    &--variants {
        .catalog-badge {
            margin-bottom: 8px;

            @include mq($until: 400px) {
                white-space: pre-wrap;
            }

            @include mq($from: desktop, $until: wide) {
                white-space: pre-wrap;
            }
        }
    }

    .catalog-badge {
        margin-bottom: 8px;
        text-align: left;
    }
}

.product-data__details-container--has-delivery {
    @include mq($from: tablet) {
        padding: 8px 16px 16px;
    }
}
