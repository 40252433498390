.cart-product {
    position: relative;
    display: grid;
    grid-template-areas:
        'productImage productDetail'
        'productImage orderInfo';
    grid-template-columns: 25% auto;
    grid-gap: 15px;
    margin: 0;
    background: var(--color-white-true);

    padding: 6px 6px 0;

    @include mq($from: tablet) {
        background: var(--color-white-true);
        padding: 6px;
    }

    .price-box__unit-price {
        font-size: var(--fs-xs);
        margin-top: 5px;
    }

    @include mq($until: tablet) {
        grid-template-areas:
            'productImage productDetail'
            'orderInfo orderInfo';
        grid-template-columns: 25% auto;
    }
}

.cart-product__left {
    position: relative;
    grid-area: productImage;
    cursor: pointer;
}

.cart-product__image-container {
    overflow: hidden;
    aspect-ratio: 1/1;
    display: flex;
    align-items: stretch;
}

.cart-product__image {
    object-fit: cover;
    // safari fix
    min-height: 100%;
}

.cart-product__detail {
    grid-area: productDetail;
}

.cart-product__order-info {
    grid-area: orderInfo;
    align-self: end;

    @include mq($until: tablet) {
        padding: 0 6px;
    }

    .delivery-info {
        margin: -8px 0 8px;
    }
}

.cart-product__detail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cart-product__title {
    cursor: pointer;
    font-family: var(--font-base);
    font-weight: var(--fw-semibold);
    color: var(--color-product-title);
    font-size: var(--fs-product-title-desktop);

    @include mq($until: 420px) {
        word-break: break-word;
        hyphens: auto;
    }
}

.cart-product__remove-button {
    grid-area: removeButton;
    cursor: pointer;
    padding-left: 20px;
    border: none;
    background: none;

    &:hover:enabled {
        color: var(--theme-primary);
    }
}

.cart-product__description {
    grid-area: description;
    font-size: var(--fs-product-description-mobile);
    margin-top: 4px;

    @include mq($from: tablet) {
        font-size: var(--fs-product-description-desktop);
    }

    li {
        margin-bottom: 0;
    }
}

.cart-product__price-info-container {
    display: grid;
    grid-template-areas: 'priceBox quantityInfo';
    align-items: flex-end;
    margin-bottom: 0;
}

.cart-product__price-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 5px;
}

.cart-product__quantity-info {
    grid-area: quantityInfo;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    select {
        border-radius: var(--border-radius-default);
    }
}

.cart-product__price {
    grid-area: priceBox;

    .price-box__price {
        color: var(--cart-product-price);
        font-size: 22px;
        margin-bottom: 4px;

        &--sale {
            color: var(--color-red);
        }
    }

    .price-box__old-price {
        font-size: var(--fs-xs);
        font-weight: var(--fw-semibold);
    }
}

.cart-product__promotion {
    margin-bottom: 12px;
    padding: 3px 16px;
    border-radius: 5px 5px 5px 0;
    font-weight: 600;
    font-size: 13px;
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}

.cart-product__quantity {
    margin-right: 0;
    font-weight: 400;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius-default);

    @include mq($until: 375px) {
        width: auto;
    }

    select {
        border-color: var(--border-cart-product-select);
        font-size: var(--fs-base);
        padding: 12px 50px 12px 24px;

        @include mq($until: 375px) {
            padding: 12px 36px 12px 18px;
        }

        &:focus-visible {
            outline: 0;
        }
    }

    .select__arrow {
        font-size: 16px;
    }

    .select--medium {
        height: 40px;
    }
}

.cart-product__badge-wrapper {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 1px;

    @include mq($from: desktop) {
        display: flex;
    }
}

.cart-product__badge-container--top {
    display: flex;
    flex-direction: row;
    width: inherit;
    position: absolute;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;
    margin-top: -1px;

    .catalog-badge {
        border-radius: 0;
    }
}

.cart-product__badge-container--bottom {
    position: absolute;
    right: 0;
    bottom: 1px;
}

.cart-product__free-item {
    background: var(--color-badge-gratis-text);
    color: var(--color-white-true);
    font-size: var(--fs-badge-small);
    border-radius: var(--border-radius-badge);
    width: fit-content;
    line-height: 22px;
    font-family: var(--font-base);
    padding: 0 12px 0 8px;
}

.cart-product__delivery {
    font-size: var(--fs-s);
    font-weight: var(--fw-normal);
    display: flex;
    align-items: center;
    margin: 4px 0;
    gap: 4px;

    .cart-product__delivery-days::before {
        @include mq($until: 400px) {
            content: '\a';
            white-space: pre-wrap;
        }
    }

    .cart-product__delivery-days {
        color: var(--color-green-500);
    }

    @include mq($until: tablet) {
        align-items: flex-end;
    }

    .cart-product__icon {
        min-width: 13px;
        height: 13px;
        color: var(--color-white-true);
        background: var(--color-green-500);
        border-radius: 50%;
        padding: 3px;
        font-size: 13px;
        margin-left: 4px;
    }
}
