.product-details-content__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mq($until: tablet) {
        padding: 8px;
    }
}

.product-details-content__product-number {
    font-size: 16px;
}

.product-details-content__description {
    overflow: hidden;
    margin-bottom: 8px;
}

.product-details-content__description-item {
    margin-left: 0;
    margin-bottom: 4px;

    &::before {
        border-radius: 100%;
        width: 6px;
        height: 6px;
        top: 10px;
    }

    h3 {
        font-size: 16px;
        font-weight: 300;
        /* stylelint-disable-next-line declaration-no-important */
        font-family: var(--font-base) !important;
    }

    a {
        border-bottom: 1px solid;
        text-decoration: none;
        color: inherit;
    }
}

.product-details-content__show-more-btn {
    color: var(--color-gray-800);
    border: none;
    text-decoration: none;
    border-bottom: 1px solid var(--color-black);
    margin-right: auto;
    background-color: unset;
    cursor: pointer;
    padding: 0;
    text-align: left;
}

.product-details-content__price-container {
    display: flex;
    flex-flow: column wrap;
    font-family: var(--font-product-price);
}

.product-details-content__price-container-price {
    display: grid;
    grid-template-areas:
        'uvp uvp'
        'price badge'
        'shipment shipment';
    column-gap: 10px;
    width: fit-content;
}

.product-details-content__price-wrapper {
    grid-area: price;
}

.product-details-content__price-container-badge {
    grid-area: badge;
    flex-direction: column;
    display: flex;
    font-family: var(--font-base);
    width: fit-content;
    justify-content: center;
    margin-top: 4px;
}

.product-details-content__pay-once {
    width: fit-content;
    font-family: var(--font-base);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: pre;
    text-decoration: none;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
    color: var(--color-badge-raten-background);

    .icon {
        margin-left: 4px;
    }
}

.product-details-content__pay-once--clickable {
    cursor: pointer;
}

.product-details-content__price-container-original {
    grid-area: uvp;
    font-family: var(--font-base);
    margin-bottom: 0;
}

.product-details-content__price {
    font-size: 30px;
    font-weight: var(--fw-bold);

    &--sale {
        color: var(--color-red);
    }
}

.product-details-content__price-default {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-details-content__shipment {
    grid-area: shipment;
    font-size: 16px;
    font-weight: var(--fw-medium);
    font-family: var(--font-base);
    color: var(--color-gray-500);
}

.product-details-content__buy {
    position: relative;

    @include mq($from: tablet) {
        padding-top: 0;
    }
}

.product-details-content__buy--with-select {
    @include mq($from: tablet) {
        display: grid;
        grid-template-areas: 'select buy';
        grid-template-columns: 100%;
    }

    @include mq($from: desktop) {
        grid-template-columns: 120px calc(100% - 120px);
    }

    .product-details-content__buy-button {
        margin-left: 100px;

        @include mq($from: desktop) {
            margin-left: 0;
        }
    }
}

.product-details-content__buy-count-select {
    grid-area: select;
    position: absolute;
    top: 0;
    background: var(--color-white-true);
    border: 1px solid var(--color-black-true);
    border-radius: 20px;
    padding: 10px 20px;
    width: 90px;
    margin-right: 25px;
    height: fit-content;
    align-self: end;
}

.product-details-content__buy-button {
    display: flex;
    background: var(--pdp-background-buy-button);
    border-radius: var(--border-radius-default);
    color: var(--pdp-color-buy-button);
    height: 54px;

    @include mq($from: desktop) {
        grid-area: buy;
        height: 54px;
    }

    .select__select {
        border: 1px solid var(--color-black-true);
        border-radius: var(--border-radius-default);
        padding: 10px 20px;

        @include mq($from: desktop) {
            padding: 0 60px 0 30px;
        }
    }

    .select__arrow {
        font-size: 18px;
    }

    .select,
    .select-button__select {
        position: absolute;
        left: 0;
    }

    .select-button__button {
        color: var(--pdp-color-buy-button);
        text-transform: inherit;
        width: 200px;

        @include mq($from: desktop) {
            width: auto;
        }
    }
}

.product-details-content__hints {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.product-details-content__price-hint {
    font-size: 13px;
    color: var(--color-gray-500);
    font-weight: var(--fw-light);
}

.product-details-content__price-hint-link {
    text-decoration: none;
    border-bottom: 1px solid var(--color-gray-500);
    cursor: pointer;
}

.product-details-content__shipping-icon {
    margin-right: 20px;
    color: var(--color-black-true);
    font-size: 35px;
}

.product-details-content__delivery {
    display: flex;
    align-items: center;
    word-wrap: break-word;

    .product-details-content__delivery-days::before {
        @include mq($until: 400px) {
            content: '\a';
            white-space: pre-wrap;
        }
    }

    .product-details-content__delivery-days {
        color: var(--bg-button-success);
    }

    @include mq($until: tablet) {
        align-items: flex-end;
    }

    .product-details-content__icon {
        min-width: 13px;
        height: 13px;
        color: var(--color-white-true);
        background: var(--color-green-500);
        border-radius: 50%;
        padding: 3px;
        font-size: 13px;
        margin-left: 4px;
    }

    .product-details-content__shipping-checkmark {
        min-width: 13px;
        height: 13px;
        color: var(--color-white-true);
        background: var(--color-green-500);
        border-radius: 50%;
        padding: 3px;
        font-size: 13px;
        margin-left: 4px;
        position: relative;
        top: 2px;
    }
}

.product-details-content__variant-switcher {
    margin-top: 20px;

    @include mq($from: tablet) {
        margin-top: 0;
    }
}

.product-details-content__delivery-text {
    font-size: 16px;
    font-weight: var(--fw-medium);

    &.is--green {
        color: var(--bg-button-success);
        display: inline-block;
    }
}

.product-details-content__cheaper-set-box {
    background: #fff;
    position: relative;
    border: 1px solid var(--color-gray-80);
    margin-top: 20px;
    padding: 15px;

    @include mq($from: tablet) {
        width: fit-content;
    }
}

.product-details-content__cheaper-set-triangle {
    background: #fff;
    border: solid var(--color-gray-80);
    border-width: 1px 1px 0 0;
    transform: rotate(-45deg);
    position: absolute;
    left: 47px;
    top: -9px;
    width: 15px;
    height: 15px;
}

.product-details-content__cheaper-set-price {
    font-weight: 600;
}

.product-details-content__cheaper-set-text {
    font-size: 22px;
    display: flex;

    @include mq($until: desktop) {
        flex-direction: column;
    }
}

.product-details-content__cheaper-set-quantity-advantage-text {
    font-weight: 700;
}

.product-details-content__product-unavailable {
    margin: 4px 0 12px;

    .product-details-content__product-unavailable-headline {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: var(--fw-semibold);
    }

    .product-details-content__product-unavailable-indicator {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: var(--color-red);
    }
}
