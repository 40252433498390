.product-slider {
    padding-top: 30px;
    padding-bottom: 24px;
    font-family: var(--ff-menu);

    &:has(.headlines) {
        padding-top: 10px;
    }

    @include mq($from: desktop) {
        padding: 30px 0 24px;
    }

    @include mq($from: desktop, $until: 1100px) {
        padding: 30px 55px 24px;
    }

    @include mq($from: wide, $until: 1415px) {
        padding: 30px 55px 24px;
    }

    .slider-navigation-item--dot {
        border-radius: 50px;
    }

    .headlines {
        padding: 20px;

        @include mq($from: desktop) {
            padding: 20px 0;
        }
    }

    .headlines__headline {
        color: var(--color-headline);
        line-height: var(--lh-small);

        @include mq($from: tablet) {
            line-height: var(--lh-small);
        }
    }

    .headlines__subheadline {
        line-height: var(--lh-small);

        @include mq($from: tablet) {
            line-height: var(--lh-small);
        }
    }

    .arrow-button {
        display: none;

        @include mq($from: desktop) {
            display: flex;
        }
    }

    .arrow-button--left {
        @include mq($from: desktop) {
            left: -54px;
        }
    }

    .arrow-button--right {
        @include mq($from: desktop) {
            right: -54px;
        }
    }

    .product-slider__content-container {
        height: 100%;
        position: relative;
    }

    .product__image-container__image {
        margin-top: auto;
    }

    .swiper-wrapper {
        .swiper-slide {
            @include mq($until: tablet) {
                height: auto;
            }
        }
    }

    .product-slider--adjust-slider {
        @include mq($until: tablet) {
            margin-left: -40px;
        }
    }

    .swiper-slide-prev {
        @include mq($until: tablet) {
            margin-left: 20px;
        }
    }

    .swiper-slide-next,
    .swiper-slide-prev {
        @include mq($until: tablet) {
            opacity: 0.5;
        }
    }

    .product__marketing,
    .product__marketing--variants {
        .catalog-badge {
            white-space: normal;
        }
    }
}

.product-slider__slider-wrapper {
    --swiper-theme-color: var(--theme-primary);
    @include mq($from: tablet) {
        position: relative;
        display: flex;
        align-items: center;
    }

    &--items-1 {
        .swiper-wrapper {
            @include mq($until: tablet) {
                margin-left: 0;
            }
        }
    }

    .swiper-container {
        width: 100%;
    }

    .product {
        margin: 0 4px;
    }
}

.product-slider__swiper-server {
    .product {
        max-width: 250px;
    }
}
