.dynamic-input {
    display: block;
}

.dynamic-input__separator {
    position: absolute;
    top: 10px;
    right: -10px;
}

.dynamic-input--relative {
    position: relative;
}
