.content-list {
    margin: 32px 0;
}

.content-list__headlines {
    padding: 0 20px 10px 80px;
}

.content-list__container {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.content-list__number {
    background-color: var(--theme-primary);
    font-family: var(--ff-headline);
    font-size: var(--fs-xl);
    font-weight: var(--fw-semibold);
    color: var(--color-white-true);
    margin: 0 15px 0 0;
    height: 46px;
    line-height: 46px;
    text-align: center;
    min-width: 46px;
}

.content-list__text {
    font-family: var(--ff-headline);
    font-size: var(--fs-base);
    line-height: 1.5;

    p {
        margin-block-start: 4px;
    }
}

.content-list__article {
    display: flex;
    margin: 10px 20px;
}

.content-list__icon-container {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    height: 50px;
    width: 50px;
    margin-right: 30px;
}

.content-list__section {
    line-height: 1;
    flex-grow: 1;
}

.content-list__title {
    text-align: start;
    padding: 0;
}
