.checkout-login {
    border: 1px solid var(--color-gray-400);
    border-top: none;
    padding-top: 10px;

    .login__hint {
        margin: 0 0 20px 4px;
        font-size: var(--fs-s);
        color: var(--color-gray-500);
    }
}

.checkout-login__loading-indicator {
    padding: 50px;
}

.checkout-login__login-forgot {
    margin-top: 20px;

    a,
    a:visited {
        text-decoration: underline;
        color: inherit;
    }
}

.checkout-login__logout {
    text-align: left;
    padding: 10px 18px;

    @include mq($from: tablet) {
        text-align: center;
        padding: 10px 0;
    }
}

.checkout-login__login-success-label {
    font-weight: 600;
    color: var(--theme-success);
}

.checkout-login__logout-link {
    text-decoration: underline;
    cursor: pointer;
}

.checkout-login__form {
    padding: 20px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.checkout-login__read-only {
    padding: 20px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.checkout-login__shipping {
    margin-top: 50px;
}

.checkout-login__company-transactions {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
