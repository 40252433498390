.customer-club-suggestion {
    position: relative;
    display: grid;
    grid-template-areas:
        'productImage productDetail'
        'productImage orderInfo';
    grid-template-columns: 25% auto;
    grid-gap: 15px;
    padding: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    background: var(--color-white-true);

    @include mq($from: tablet) {
        background: var(--color-white-true);
        padding: 16px;
        margin: 0 0 26px;
    }
}

.customer-club-suggestion__image-container {
    grid-area: productImage;
    border-radius: var(--border-radius-default);
    overflow: hidden;
    aspect-ratio: 1/1;
    display: flex;
    align-items: stretch;
}

.customer-club-suggestion__image {
    object-fit: cover;
}

.customer-club-suggestion__detail {
    grid-area: productDetail;
}

.customer-club-suggestion__order-info {
    grid-area: orderInfo;
    align-self: end;
}

.customer-club-suggestion__detail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 10px 5px 0;
}

.customer-club-suggestion__title {
    font-size: 16px;
    color: var(--theme-primary);
    font-weight: 700;
}

.customer-club-suggestion__description {
    grid-area: description;
    font-size: 14px;
    text-align: start;

    li {
        margin-bottom: 0;
    }
}

.customer-club-suggestion-description__preview--oversized {
    overflow-y: hidden;
}

.customer-club-suggestion__price-info-container {
    display: flex;
    justify-content: space-between;

    @include mq($until: tablet) {
        display: block;
    }
}

.customer-club-suggestion__price {
    align-self: flex-end;

    .price-box__old-price {
        color: var(--re-checkout-product-old-price);
        margin-bottom: 10px;
    }

    .price-box__price {
        color: var(--pre-checkout-product-price);
        font-size: 25px;
    }
}

.customer-club-suggestion__promotion {
    margin-bottom: 12px;
    padding: 3px 16px;
    border-radius: var(--border-radius-precheckout);
    font-weight: 600;
    font-size: 13px;
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}

.customer-club-suggestion__cta-btn-cart {
    border-radius: var(--border-radius-default);
    padding: 11px;
    max-width: 100px;
    font-size: 18px;

    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);
}

.customer-club-suggestion__product-suggestion-title {
    text-align: center;
    padding: 12px;
    color: var(--theme-primary);
    background-color: var(--color-white-true);
}
