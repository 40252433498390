.product-stage-variant__variant-error {
    color: var(--theme-error);
    padding-top: 5px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.product-stage-variant__attention-icon {
    margin-right: 5px;
}

.product-stage-variant__select > select {
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 20px 0;
}
