.form {
    display: grid;
    align-items: center;
    justify-content: stretch;
    gap: 15px;
    grid-template-columns: auto;
}

.form-row {
    display: grid;
    justify-content: stretch;
    gap: 15px;

    @include mq($from: tablet) {
        grid-auto-flow: column;
    }
}

.form-row--split {
    display: grid;
    gap: 15px;
    grid-template-columns: auto;

    @include mq($from: tablet) {
        grid-template-columns: 1fr 1fr;
    }
}

.form-row--single {
    justify-content: stretch;
    max-width: 410px;
}

.form-row--split-group,
.form-row--split-group__input {
    display: grid;
    justify-content: stretch;
    gap: 15px;
    grid-auto-flow: column;
}

.form-row-spacer {
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}

.form__title {
    margin-bottom: 10px;
    margin-left: 4px;
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.form__title--empty {
    /* Fixed height is necessary in case two inputs in a row have to be aligned */
    height: 20px;
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}
