/* stylelint-disable selector-class-pattern */
.image-banner-slider {
    display: block;
    position: relative;

    .arrow-button {
        color: var(--pdp-slider-arrow-color);
        background: var(--pdp-slider-arrow-background);
        z-index: 1;

        &--left {
            left: 8px;
        }

        &--right {
            right: 8px;
        }
    }
}

.swiper-wrapper {
    padding-bottom: 0;
}

.image-banner-slider__swiper {
    margin: 0;

    .swiper-wrapper {
        align-items: center;
    }

    .slider__inner-wrapper {
        margin: 0;
    }
}

.image-banner-slider__toggle-play-button {
    position: absolute;
    bottom: 42px;
    left: 12px;

    @include mq($from: tablet) {
        display: none;
    }
}

.image-banner-slider__arrow-buttons--content-banner {
    @media only screen and (min-width: 1423px) {
        .arrow-button--left {
            left: -54px;
        }

        .arrow-button--right {
            right: -54px;
        }
    }
}

.swiper-wrapper > :not(.swiper-slide-active) > .image-banner--banner,
.swiper-wrapper > :not(.swiper-slide-active) > .image-banner--contentBanner {
    display: none;
}

.image-banner-slider:not(:has(.swiper-initialized)) {
    .swiper-wrapper > :first-child > .image-banner--banner,
    .swiper-wrapper > :first-child > .image-banner--contentBanner {
        display: block;
    }

    .image-banner-slider__toggle-play-button,
    .slider__inner-wrapper--arrows-wrapper {
        display: none;
    }
}
