.voucher-check-modal {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.voucher-check-modal__headline {
    border-bottom: 1px solid var(--theme-dark);
    padding-bottom: 8px;
}

.voucher-check-modal__button {
    padding: 8px 12px;
}

.voucher-check-modal__overlay {
    position: fixed;
    background: var(--modal-overlay-bg-color);
    inset: 0;
    z-index: 2000;
}

.voucher-check-modal__voucher-details {
    display: flex;
    gap: 12px;
    font-size: var(--fs-l);

    @include mq($until: tablet) {
        flex-direction: column;
        gap: 0;
    }
}

.voucher-check-modal__voucher-code {
    color: var(--theme-primary);
    font-weight: var(--fw-semibold);

    @include mq($until: tablet) {
        margin-bottom: 0;
    }
}

.voucher-check-modal__voucher-text {
    @include mq($until: tablet) {
        margin-top: 0;
    }
}

.voucher-check-modal__legal-text {
    font-size: var(--fs-s);
}
