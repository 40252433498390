.flyout {
    position: fixed;
    right: 0;
    transform: rotate(270deg);
    transform-origin: right bottom;
    filter: drop-shadow(-4px -2px 4px rgb(0 0 0 / 19%));
    z-index: var(--zi-flyout);
}

.flyout__button {
    transform: rotate(180deg);
    justify-content: flex-start;
    min-height: 55px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    height: auto;
    clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
}

.flyout__button-with-icon {
    min-width: 60px;
    padding-left: 20px;
    padding-right: 46px;
}

.flyout__button-icon {
    margin: 0 10px;
    width: 36px;
    height: 36px;
    transform: rotate(-90deg);
}
