@import '~normalize.css';

@import '~@mediashop/base/style';
@import 'settings';
@import 'elements';
@import '../pattern/**/*.scss';

body {
    scroll-behavior: smooth;
    position: relative;
}

input,
div.select {
    border-radius: var(--border-radius-default);
}

.toaster-wrapper {
    @include mq($from: tablet) {
        min-width: 250px;
    }

    button {
        font-size: 20px;
    }
}

.pointer-events--disabled {
    pointer-events: none;
}
