.youtube-video {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .uc-embedding-container {
        @include mq($until: tablet) {
            min-height: 200px;
        }
    }

    .uc-embedding-wrapper {
        @include mq($until: tablet) {
            top: 42%;
        }
    }
}

.youtube-video__video-headline {
    width: 80%;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    font-size: var(--fs-l);
    font-weight: var(--fw-semibold);

    @include mq($from: tablet) {
        font-size: var(--fs-ml);
        font-weight: var(--fw-semibold);
    }
}

.youtube-video__overlay-button {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    margin: 0;
}
