.background--primary {
    --color-subheadline: var(--theme-on-primary-subheadline);
}

.background--secondary {
    --color-subheadline: var(--theme-on-secondary-subheadline);
}

.background--tertiary {
    --color-subheadline: var(--theme-on-tertiary-subheadline);
}

.background--default {
    --color-subheadline: var(--theme-on-default-subheadline);
}

.background--white-true {
    --color-subheadline: var(--theme-on-default-subheadline);
}

body.hide-scrollbar {
    overflow-y: hidden;
}

.background--beige {
    background: var(--theme-beige);
}
