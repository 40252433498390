.pre-checkout-flyout__container {
    position: fixed;
    top: 0;
    right: 0;
    background: var(--color-gray-300);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 2001;

    /* stylelint-disable declaration-block-no-duplicate-properties */
    max-height: 100%;
    max-height: -webkit-fill-available;

    @include mq($from: tablet) {
        width: 380px;
    }

    @include mq($from: desktop) {
        width: 500px;
    }

    @include mq($until: desktop) {
        overflow-y: auto;
    }
}

.pre-checkout-flyout__backdrop {
    position: fixed;
    inset: 0;
    background-color: var(--modal-overlay-bg-color);
    z-index: var(--zi-precheckout);
    cursor: default;
}

.pre-checkout-flyout__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-color: var(--color-white-true);
    box-shadow: var(--box-shadow-default);
    gap: 4px;

    @include mq($from: tablet) {
        align-items: center;
        padding: 20px;
        gap: 0;
    }
}

.pre-checkout-flyout__price-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: var(--theme-cta);
    margin-top: 20px;
}

.pre-checkout-flyout__price {
    font-size: 20px;
}

.pre-checkout-flyout__cta-sentence {
    font-size: 12px;

    @include mq($from: tablet) {
        font-size: 16px;
    }
}

.pre-checkout-flyout__cta-sentence-bold {
    font-weight: var(--fw-bold);
}

.pre-checkout-flyout__product-suggestion-title {
    text-align: center;
    margin: 20px;
    color: var(--theme-primary);
}

.pre-checkout-flyout__buttons {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    height: 75px;
    box-shadow: var(--box-shadow-default);
    background-color: var(--color-white-true);
    position: relative;
    z-index: 1;

    @include mq($from: tablet) {
        height: 80px;
    }

    @include mq($until: 360px) {
        flex-direction: column;
        align-items: center;
        gap: 12px;
        height: 110px;
    }
}

.pre-checkout-flyout__product-suggestion-wrapper {
    height: 100%;
    padding: 0 10px;
    overflow-y: auto;
    padding-bottom: 400px;
    scrollbar-width: none;

    &--club-suggestion {
        .customer-club-suggestion,
        .customer-club-suggestion__product-suggestion-title {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}

.pre-checkout-flyout__product-suggestion-wrapper::-webkit-scrollbar {
    width: 0;
}

.pre-checkout-flyout__btn {
    width: 50%;
    border-radius: var(--border-radius-default);
    text-transform: none;
}

.pre-checkout-flyout--btn-go-back {
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);
    margin-left: 20px;
    margin-right: 10px;

    @include mq($until: 360px) {
        margin: 0;
    }
}

.pre-checkout-flyout--btn-go-to-cart {
    color: var(--pdp-color-buy-button);
    background: var(--pdp-background-buy-button);
    margin-left: 10px;
    margin-right: 20px;

    @include mq($until: 360px) {
        margin: 0;
    }
}
