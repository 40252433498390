.dynamic-select > select {
    border: 1px solid var(--border-input);
    font-size: var(--fs-sm);
}

.dynamic-select--error > select {
    border: 1px solid var(--input-error);
}

.dynamic-select__error-message {
    padding-top: 2px;
    color: var(--input-error);
    font-size: var(--fs-sm);
}
