.new-passwords {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 420px;
    margin: 16px;

    @include mq($from: tablet) {
        margin: 80px auto;
    }
}

.new-passwords__password-requirements {
    margin: 20px 0 10px;
}

.new-passwords__password {
    margin-bottom: 15px;
}
