.product-grid-category__wrapper {
    position: relative;
    padding-top: 20px;
}

.product-grid-category__headlines {
    padding: 0;

    > .headlines__headline {
        color: var(--plp-headline-color);
    }
}

.product-grid-category__filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;
    gap: 20px;

    @include mq($until: tablet) {
        flex-direction: column-reverse;
    }

    @include mq($from: desktop) {
        padding: 20px 0;
    }

    > span {
        font-size: 18px;
    }
}

.product-grid-category__product-amount {
    font-size: var(--fs-main-navigation-desktop);
    font-weight: inherit;
}

.product-grid-category__no-products {
    font-size: var(--fs-main-navigation-desktop);
    font-weight: inherit;
    box-shadow: var(--box-shadow-default);
    border-radius: var(--border-radius-default);
    padding: 20px 50px;
    margin: auto;
    color: var(--color-text);
    background: var(--color-white-true);
}

.product-grid-category__pagination-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;
}

.product-grid-category__above-the-fold {
    margin: 0;
    padding: 0 12px 10px;

    @include mq($from: desktop) {
        padding: 0 0 10px;
    }
}

.product-grid-category__above-the-fold--with-title {
    padding: 20px 12px 10px;
}

.product-grid-category__below-the-fold {
    margin: 0;
    padding: 20px 12px 10px;

    @include mq($from: desktop) {
        padding: 20px 0 10px;
    }
}

.product-grid-category-sort__select-box {
    > div:first-of-type {
        margin: 0;
        padding: 0;
        border: 1px solid var(--theme-primary);
        border-radius: var(--border-radius-default);
        background: var(--color-white-true);
        height: 46px;
    }

    &:has(.select-box__options) > div:first-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    > .select-box__options {
        border: 1px solid var(--theme-primary);
        border-top: none;
        border-bottom-right-radius: var(--border-radius-default);
        border-bottom-left-radius: var(--border-radius-default);
    }

    .select-box__selected-value-icon {
        border-color: var(--theme-primary);
        height: 30px;
        width: 40px;
    }
}
