.cart-summary-footer__payment-logos {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;

    @include mq($from: tablet) {
        padding-top: 10px;
        margin-bottom: 10px;
    }
}

.cart-summary-footer__payment-logos-item {
    padding: 0 10px;
    max-height: 80px;
    max-width: 80px;
}

.cart-summary-footer__usps {
    background: var(--theme-beige);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.cart-summary-footer__usp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    padding: 5px;
    color: var(--theme-primary);
    white-space: nowrap;
}

.cart-summary-footer__usp-icon {
    margin-right: 5px;
}
