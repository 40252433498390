.header-bar {
    display: grid;
    grid-template-columns: 50px auto 50px;
    grid-template-areas: 'start center end';
    height: 64px;
    line-height: 64px;
}

.header-bar__start {
    grid-area: start;
    margin-left: 30px;
}

.header-bar__center {
    grid-area: center;
    line-height: 64px;
    text-align: center;
}

.header-bar__end {
    grid-area: end;
    margin-right: 30px;
}

.header-bar__backlink-icon {
    font-weight: var(--fw-semibold);
    cursor: pointer;
    color: var(--color-header-bar);

    &:focus {
        outline: none;
    }
}

.header-bar__title {
    font-family: var(--ff-headline);
    font-size: var(--fs-xl);
    font-weight: var(--fw-semibold);
}
