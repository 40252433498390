.product-details--wrapper {
    display: block;
    margin: 30px 0;

    @include mq($from: tablet) {
        display: grid;
        grid-template-columns: 350px auto;
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        grid-template-areas:
            'slider content'
            '. content';
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        grid-gap: 20px;
    }

    @include mq($from: desktop) {
        grid-template-columns: 57px 350px auto;
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        grid-template-areas:
            'thumbnails slider content'
            '. . content';
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        padding: 0 16px;
    }

    @include mq($from: wide) {
        grid-template-columns: 87px 500px auto;
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        grid-template-areas:
            'thumbnails slider content'
            '. . content';
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    }
}

.product-details--content-wrapper {
    grid-area: content;
    position: relative;
    padding-left: 8px;

    @include mq($until: tablet) {
        padding-top: 20px;
        padding-right: 8px;
    }
}

.product-details--slider-container {
    @include mq($from: tablet) {
        position: relative;
    }
}

.product-details--slider-container:has(.catalog-badge--aktion, .catalog-badge--sparset, .catalog-badge--sale) {
    border: 2px solid var(--color-red);
    line-height: 9px;

    .catalog-badge--gratis {
        border-bottom: none;
    }
}

.product-details--blue-box {
    min-height: 58px;
    text-align: center;
    color: var(--pdp-color-caption);
    background: var(--pdp-background-caption);
    font-family: var(--ff-menu);
    font-size: var(--fs-17);
    font-weight: var(--fw-normal);
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @include mq($until: tablet) {
        min-height: 84px;
    }
}

.product-details__videomodal {
    .modal__body {
        position: relative;
        padding: 8px;
        @include mq($from: tablet) {
            padding: 46px;
        }
    }

    .modal__close {
        z-index: 10;

        @include mq($until: desktop) {
            position: absolute;
            top: -33px;
            right: 0;
            background: var(--color-white-true);
            padding: 6px;
        }

        @include mq($until: desktop, $and: '(orientation: landscape)') {
            right: -26px;
            top: 0;
        }
    }
}

.product-details--video-wrapper-mobile {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 85px;
    width: 85px;
    background: var(--pdp-video-thumbnail-background);

    &--badges {
        top: 26px;
    }

    @include mq($from: desktop) {
        display: none;
    }

    .product-details__playbutton {
        color: var(--pdp-video-thumbnail-icon);
        font-size: 36px;
        background: #fff;
        border-radius: 50%;
    }

    .product-details__videotext {
        position: relative;
        top: 6px;
        font-size: var(--fs-xs);
        font-weight: 400;
    }
}

.product-details--video-wrapper-mobile-hidden {
    display: none;
}

/* stylelint-disable selector-max-id */
#product-details--image-portal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: var(--color-white-true);

    img {
        height: 100%;
    }
}

.product-details__badge-wrapper {
    display: flex;
    flex-direction: column;
    top: 0;
    width: 100%;
    position: absolute;
    aspect-ratio: 1/1;
    pointer-events: none;
}

.product-details__badge-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.product-details__badge-container--bottom {
    margin-top: auto;
    align-items: flex-end;

    :first-child {
        z-index: 1;
        border-radius: 0;
    }
}

.product-details__badge-container--top {
    align-items: flex-start;
    z-index: 1;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :first-child {
        border-radius: 0;
    }

    :nth-child(2) {
        border-radius: 0;
    }
}

.product-details__mobile-header {
    padding: 0 16px;
    margin-bottom: 20px;
}

.product-details--slider-wrapper {
    grid-area: slider;
    position: relative;
    margin-bottom: 30px;

    @include mq($from: tablet) {
        position: sticky;
        top: 30px;
    }

    .product-details--blue-box {
        display: none;
    }

    &--has-usp {
        .product-details--blue-box {
            display: flex;
        }
    }

    @include mq($from: tablet) {
        margin-bottom: 0;
    }
}

.product-details__pagination {
    text-align: center;
}

body:has(.content-navigation) {
    @include mq($from: tablet) {
        .product-details--slider-wrapper {
            top: 72px;
        }
    }
}

/* stylelint-enable selector-max-id */
