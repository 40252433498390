.full-screen-loading-indicator {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: var(--zi-loading);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.full-screen-loading-indicator__loading-indicator {
    border: 16px solid var(--theme-tertiary);
    border-top: 16px solid var(--theme-primary);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
