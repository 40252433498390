.product-component-details {
    margin: 32px 0;
}

.product-component-details__container {
    margin: 30px 15px;

    @include mq($from: tablet) {
        margin: 30px 0;
        display: grid;
        grid-template-columns: 30% 40% 30%;
        gap: 30px;
        justify-content: center;
        align-items: center;
    }
}

.product-component-details__image-container {
    padding: 0 15px;
}

.product-component-details__image {
    margin: auto;
    width: auto;
}

.product-component-details-description {
    @include mq($from: desktop) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.product-component-details__image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    flex: 2 1 0;
}

.product-component-details__image-wrapper .marker {
    background-color: var(--theme-primary);
    position: absolute;
    cursor: pointer;
    text-align: center;
    color: #fff;
    z-index: 2;
    user-select: none;

    &--selected {
        background: var(--theme-tertiary);
        color: var(--theme-cta-font-color);
    }
}

.product-component-details__image-wrapper img {
    width: 100%;
    height: auto;
    z-index: 1;
}

.product-component-details__image-wrapper .tooltip-hide {
    opacity: 0;
    position: absolute;
}

.product-component-details__image-wrapper .tooltip {
    display: flex;
    opacity: 100%;
    transition: opacity 0.3s ease-in-out;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 162px;
    gap: 10px;
    position: absolute;
    border-radius: var(--border-radius-default);
    text-align: center;
    padding: 15px 0;
    z-index: 3;

    & a {
        text-decoration: underline;
        text-underline-offset: 5px;
        color: #008bd2;
    }

    & h4 {
        color: #008bd2;
    }
}
