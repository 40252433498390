.modal-discount__validity {
    font-size: 16px;
}

.modal-discount__details {
    width: 100%;
    padding: 12px;
    border-radius: var(--border-radius-default);
    border: 1px solid var(--color-gray-500);
    cursor: pointer;
}

.modal-discount__details--active {
    border: 2px solid var(--theme-primary);
    background: var(--theme-primary-opaque);
}

.modal-discount__info {
    font-size: 16px;
    display: flex;
    gap: 4px;
    flex-flow: column;
}

.modal-discount__info-details {
    display: flex;
    flex-flow: column;
}

.modal-discount__info-code {
    font-size: 18px;
    font-weight: 600;
    border-radius: var(--border-radius-default);
    color: var(--theme-primary);
}

.modal-discount__info-details-savings {
    font-weight: var(--fw-bold);
    font-size: 16px;
}

.modal-discount__show-more-container {
    display: flex;
}

.modal-discount__show-more {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
    text-decoration: underline;
}

.modal-discount__legal {
    font-size: 14px;
    margin-top: 6px;
    color: #999;
}
