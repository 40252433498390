.password-input__label {
    position: relative;
    display: block;
}

.password-input__input {
    width: 100%;
    padding: 18px 15px 8px;
    background-color: var(--bg-input);
    border: 1px solid var(--border-input);

    &::placeholder {
        color: transparent;
    }

    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        position: absolute;
        right: 0;
        display: none;
        pointer-events: none;
        width: 0;
    }

    &::-ms-reveal {
        display: none;
    }
}

.password-input__input--error {
    border: 1px solid var(--theme-error);
}

.password-input__title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 13px 15px 11px;
    color: var(--input-text);
    font-size: var(--fs-sm);
    pointer-events: none;
    transition: 0.2s ease transform;
}

.password-input__input:focus ~ .password-input__title,
.password-input__input:not(:placeholder-shown) ~ .password-input__title {
    pointer-events: none;
    transform: translate(-4px, -11px) scale(0.85);
}

.password-input__error {
    padding-top: 2px;
    color: var(--input-error);
    font-size: var(--fs-sm);
}

.password-input__input:not(:placeholder-shown):invalid ~ .password-input__error {
    display: block;
}

.password-input__show-hide {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
}

.password-input__show-hide-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
}
