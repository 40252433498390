.mpcheckout {
    padding: 0 0 50px;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin: 10px auto auto;

    @include mq($from: desktop) {
        width: 800px;
    }
}

.mpcheckout__guest-order {
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin-top: 10px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.mpcheckout__payment {
    border: 1px solid var(--color-gray-400);
    background: var(--color-white-true);
    padding: 20px;
    margin-top: 20px;
    width: 100%;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.mpcheckout__summary {
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin-top: 20px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.mpcheckout-cart__panel {
    width: 100%;
    margin-top: 30px;
}

.mpcheckout__cart {
    width: 100%;
    margin-bottom: 10px;
}

.mpcheckout__addresses {
    width: 100%;
    margin-top: 20px;
}

.mpcheckout__tabs {
    padding: 0 15px;

    @include mq($from: tablet) {
        padding: 0;
    }
}
