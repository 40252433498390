.op-checkout {
    display: grid;
    grid-template:
        'cart'
        'divider'
        'address'
        'payment'
        'summary';
    gap: 20px;
    padding: 0 0 50px;
    align-items: flex-start;

    @include mq($from: desktop) {
        grid-template:
            'cart cart'
            'address summary'
            'payment summary' / 1fr 448px;
        padding: 50px 0;
    }
}

.op-checkout__header {
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    margin-bottom: 15px;
    padding-left: 20px;
}

.op-checkout__guest-order {
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin-top: 10px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.op-checkout__payment {
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin-top: 10px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}
