.password-criteria__criteria-wrapper {
    margin-top: 0.5rem;
}

.password-criteria__criteria {
    display: block;
    transition: color 100ms ease-in-out;
}

.password-criteria__fulfills-criteria {
    color: var(--theme-success);
}
