.checkout-guest {
    border: 1px solid var(--color-gray-400);
    border-top: none;
    padding-top: 10px;
}

.checkout-guest__read-only {
    padding: 20px;

    @include mq($from: desktop) {
        padding: 40px 80px;
    }
}

.checkout-guest__required-hint {
    margin: 0 0 20px 4px;
    font-size: var(--fs-s);
    color: var(--color-gray-500);
}

.checkout-guest__info-text {
    padding: 5px 0 20px;
}

.checkout-guest__error {
    color: var(--theme-error);
    margin-top: -12px;
    margin-bottom: 12px;
}
