.text-with-image {
    clip-path: inset(0);
}

.text-with-image__content-container {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    grid-template-areas:
        'imageContainer'
        'textContainer';

    @include mq($from: tablet) {
        overflow: hidden;
    }
}

.text-with-image__image-container {
    grid-area: imageContainer;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    @include mq($from: tablet) {
        position: relative;
        overflow: hidden;
        height: 300px;

        img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
        }
    }

    @include mq($from: desktop) {
        height: 500px;
    }
}

.text-with-image__image-badge {
    position: absolute;
    display: inline-block;
    top: 10px;
    padding: 10px 40px;

    background: var(--bg-badge-info);
    color: var(--color-badge-info);
    font-weight: var(--fw-text-badge);
}

.text-with-image__content {
    grid-area: textContainer;
    padding: var(--sp-base) var(--sp-lg);

    @include mq($from: tablet) {
        padding: var(--sp-base) var(--sp-xl) var(--sp-base) var(--sp-lg);
    }
}

.text-with-image__headlines {
    padding: 0;
    text-align: start;
}

.text-with-image__icon-background {
    color: var(--theme-primary);
    position: absolute;
    width: 80%;
    height: 50%;
    opacity: 0.2;
    transform: translate(0%, -50%);
    z-index: -1;
}

.text-with-image--image-position-left {
    .text-with-image__image-badge {
        position: absolute;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
        left: 0;
    }

    @include mq($from: tablet) {
        grid-template-columns: 60% 40%;
        grid-template-areas: 'imageContainer textContainer';

        .text-with-image__image-container {
            position: relative;
            clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
        }
    }

    @include mq($from: desktop) {
        grid-template-columns: 60% 40%;
        grid-template-areas: 'imageContainer textContainer';
    }
}

.text-with-image--image-position-right {
    .text-with-image__image-badge {
        position: absolute;
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        right: 0;
    }

    @include mq($from: tablet) {
        grid-template-columns: 40% 60%;
        grid-template-areas: 'textContainer imageContainer';

        .text-with-image__content {
            padding-left: 3.7rem;
        }

        .text-with-image__image-container {
            position: relative;
            clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }

    @include mq($from: desktop) {
        grid-template-columns: 40% 60%;
        grid-template-areas: 'textContainer imageContainer';
    }
}
