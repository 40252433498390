.youtube-video-poster {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    inset: 0;
    cursor: pointer;
    z-index: 1;
}

.youtube-video-poster__play-button {
    background: var(--bg-play-button);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';

    position: relative;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    padding: 12px 12px 12px 15px;
    width: 75px;
    height: 65px;
}

.youtube-video-poster__arrow {
    width: 100%;
    height: 100%;
    color: var(--color-play-icon);
}
