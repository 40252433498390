.product-hazard-warning {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 8px;
    margin-top: 15px;

    @include mq($from: tablet) {
        margin-top: 30px;
    }
}

.product-hazard-warning__list-item {
    word-break: break-word;
    margin-bottom: 0;

    &::before {
        border-radius: 100%;
        width: 4px;
        height: 4px;
        top: 5px;
    }
}

.product-hazard-warning__image-container {
    display: flex;
    gap: 10px;
    width: 80px;
    margin: 5px 0;
}

.product-hazard-warning__list {
    line-height: var(--safety-list-line-height);
    font-size: var(--safety-list-fs);
}
