.splp-cart__container {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
        'products'
        'addon'
        'summary';
    padding: 50px 0;

    @include mq($from: tablet) {
        grid-template-columns: 70% auto;
        grid-template-areas:
            'products summary'
            'addon summary';
    }
}

.splp-cart__products {
    grid-area: products;
}

.splp-cart__addon {
    grid-area: addon;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 0 15px;

    @include mq($from: tablet) {
        padding: 15px;
        background: var(--bg-cart-addon);
    }
}

.splp-cart__addon-headline {
    font-size: var(--fs-h2);
}

.splp-cart__summary {
    grid-area: summary;
}

.splp-cart--has-sticky-summary {
    .splp-cart__summary {
        display: none;
    }

    .splp-cart__sticky-summary {
        display: block;
        position: sticky;
        bottom: 0;
        background: var(--bg-cart-sticky-footer);
    }
}
