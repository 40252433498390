.burger-menu-quick-links {
    margin-top: 14px;
    padding-top: 14px;
    border-top: 1px solid var(--main-navigation-custom-quick-links-separator-color);
}

.burger-menu-quick-links__list {
    list-style: none;
    padding: 0;
    margin: 0;
}
