.checkout-summary-address {
    padding: 10px;
    border-bottom: 1px solid var(--color-gray-400);

    @include mq($from: tablet) {
        display: flex;
        column-gap: 10px;
    }
}

.checkout-summary-address__header {
    flex-basis: 250px;

    @include mq($until: tablet) {
        display: flex;
        padding-bottom: 10px;
        justify-content: space-between;
    }
}

.checkout-summary-address__title {
    font-weight: var(--fw-bold);
}

.checkout-summary-address__change {
    cursor: pointer;
    text-decoration: underline;
}
