.product-safety {
    @include mq($from: tablet) {
        padding: 16px 72px;
    }
}

.product-safety__table-container {
    @include mq($from: desktop) {
        display: flex;
        flex-direction: row;
    }
}

.product-safety__table-container--margin-bottom {
    margin-bottom: 15px;

    @include mq($from: tablet) {
        margin-bottom: 40px;
    }
}

.product-safety__table {
    width: 100%;
    table-layout: fixed;

    @include mq($from: desktop) {
        width: 50%;
        table-layout: auto;
    }
}

.product-safety__row {
    background-color: var(--color-gray-300);
}

.product-safety__data-label,
.product-safety__data-value {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
    word-wrap: break-word;
}
