.newsletter {
    padding: 30px 20px;

    .headlines {
        padding: 0 0 20px;

        @include mq($from: desktop) {
            padding: 0;
            margin-bottom: 8px;
        }
    }
}

.newsletter__description,
.newsletter__form {
    margin-bottom: 16px;
}

.newsletter__description,
.newsletter__legal-text {
    p {
        margin: 0;
    }

    a {
        text-decoration: underline;
        color: currentcolor;
    }
}

.newsletter__form-input-wrapper {
    @include mq($from: tablet) {
        display: flex;
    }

    .newsletter__input input {
        height: 46px;
    }
}

.newsletter__input {
    width: 100%;

    input {
        text-overflow: ellipsis;

        @include mq($until: tablet) {
            border-bottom: none;
            border-radius: 10px 10px 0 0;
        }

        @include mq($from: tablet) {
            border-right: none;
            border-radius: var(--border-radius-bottom-right-badge);
        }
    }

    .input__title {
        right: 0;
        padding: 13px 5px 11px 15px;
    }
}

.newsletter__button {
    height: 46px;
    padding: 15px 25px;
    text-transform: none;
    min-width: auto;

    @include mq($until: tablet) {
        width: 100%;
        border-radius: 0 0 10px 10px;
    }

    @include mq($from: tablet) {
        border-radius: var(--border-radius-badge);
    }
}

.newsletter__legal-text {
    font-size: var(--fs-s);
}

.newsletter__company-transactions {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}

.newsletter__text-holder p a {
    color: var(--theme-cta);
    font-weight: 600;
}

.newsletter__text-holder p a:hover {
    cursor: pointer;
}
