.pre-checkout-added-product {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
}

.pre-checkout-added-product__image {
    width: 75px;
    height: 75px;
    border-radius: var(--border-radius-default);

    @include mq($until: tablet) {
        width: 50px;
        height: 50px;
    }
}

.pre-checkout-added-product__text {
    margin-left: 10px;
}

.pre-checkout-added-product__title {
    color: var(--color-black-true);
    text-overflow: ellipsis;
    font-weight: var(--fw-bold);
    font-size: 12px;

    @include mq($from: tablet) {
        color: var(--theme-primary);
        max-width: 200px;
        font-size: 16px;
    }
}

.pre-checkout-added-product__link {
    font-size: 14px;
    color: var(--color-text);
    text-decoration: underline;
}
