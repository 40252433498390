/* stylelint-disable no-duplicate-selectors */
.multi-page-checkout-steps__container {
    width: 100%;
    text-align: center;
}

.multi-page-checkout-steps {
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    align-items: baseline;

    // @include mq($until: desktop) {
    //     justify-content: space-around;
    // }

    &__step {
        @include mq($until: desktop) {
            flex-shrink: 2;
            flex-basis: min-content;
        }
    }

    &__step--active,
    &__step {
        font-size: var(--fs-xs);
        text-align: center;
        color: var(--color-gray-600);
        padding: 10px 0;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        flex: 1;

        @include mq($from: desktop) {
            width: 100%;
            font-size: var(--fs-s);
        }

        &::after {
            content: '';
            position: absolute;
            top: 30px;
            transform: translateX(calc(50% + 42px));
            width: calc(100% - 82px);
            height: 2px;
            z-index: 2;
            background: var(--color-gray-400);

            @include mq($until: tablet) {
                top: 26px;
                width: calc(100% - 52px);
                transform: translateX(calc(50% + 26px));
            }
        }

        &--clickable {
            cursor: pointer;
        }

        &__bubble,
        &__bubble--active,
        &__bubble--previous {
            background-color: var(--mp-checkout-steps-background-color);
            border-radius: 50%;
            color: var(--color-gray-600);
            font-size: var(--fs-base);
            font-weight: var(--fw-bold);
            height: 32px;
            text-align: center;
            width: 32px;
            line-height: 32px;

            @include mq($from: tablet) {
                height: 40px;
                width: 40px;
                font-size: var(--fs-l);
                line-height: 40px;
            }

            & .icon {
                position: relative;
                top: 5px;
            }
        }

        &__bubble--active {
            color: var(--mp-checkout-steps-text-color);
            background-color: var(--theme-primary);
        }

        &__bubble--previous {
            background-color: var(--theme-success);
        }

        &:last-child::after {
            display: none;
        }
    }

    &__step--active {
        color: var(--theme-primary);
    }

    &__step--previous {
        color: var(--theme-success);
        cursor: pointer;

        &::after {
            background-color: var(--theme-success);
            cursor: default;
        }

        & .icon {
            color: var(--mp-checkout-steps-text-color);
        }
    }

    &__headline {
        margin: 10px;
        font-size: var(--fs-xl);
        font-weight: var(--fw-semibold);

        @include mq($until: tablet) {
            font-size: var(--fs-l);
        }
    }
}
