// Custom Properties in catalog-base

:root {
    // Fonts
    /////////////////////////////////////////////
    --font-base: 'Nunito', sans-serif;
    --font-title: 'Nunito', sans-serif;
    --font-headlines: 'Nunito', sans-serif;
    --font-menu: 'Nunito', sans-serif;
    --font-product-price: 'Oswald', sans-serif;

    --theme-headlines: var(--font-headlines);
    --theme-subheadlines: var(--font-headlines);

    // Z Index
    --zi-video-badge: 100;
    --zi-search-overlay-portal: 99999;

    // Colors
    /////////////////////////////////////////////
    --color-gray-200: #f0f0f0;
    --color-gray-300: #f5f5f5;
    --color-gray-400: #dfdfdf;
    --color-gray-500: #aaa;
    --color-gray-600: #777;
    --color-gray-800: #222;
    --color-red: #e30613;
    --color-red-opacity-80: #e30613cc; /* 80% opacity */

    --theme-primary: #008bd2;
    --theme-secondary: #dff2fd;
    --theme-tertiary: #f9d100;

    --theme-primary-transparent: #008bd200;
    --theme-primary-opaque: rgba(0, 139, 210, 0.05);

    --theme-success: #26bb31;
    --theme-attention: #008bd2;
    --theme-promotion: #008bd2;
    --theme-dark: #544750;
    --theme-beige: #faf5ee;
    --theme-cta: #faf5ee;
    --theme-cta-font-color: var(--theme-dark);

    // Font Size
    --fs-base-px: 16;
    --fs-xs: 0.75rem; //calc(0.75rem / 16 * var(--fs-base-px)); // 12px
    --fs-s: 0.875rem; //calc(0.875rem / 16 * var(--fs-base-px)); // 14px
    --fs-sm: 0.9375rem; //calc(0.9375rem / 16 * var(--fs-base-px)); // 15px
    --fs-base: 1rem; //calc(1rem / 16 * var(--fs-base-px)); // 16px
    --fs-17: 1.0625rem; // calc(1.0625rem / 16 * var(--fs-base-px)); // 17px
    --fs-18: 1.125rem; // calc(1.125rem / 16 * var(--fs-base-px)); // 18px
    --fs-l: 1.25rem; //calc(1.25rem / 16 * var(--fs-base-px)); // 20px
    --fs-ml: 1.5rem; //calc(1.5rem / 16 * var(--fs-base-px)); // 24px
    --fs-xl: 1.75rem; //calc(1.75rem / 16 * var(--fs-base-px)); // 28px
    --fs-2xl: 2rem; //calc(2rem / 16 * var(--fs-base-px)); // 32px
    --fs-3xl: 2.5rem; // calc(2.5rem / 16 * var(--fs-base-px)); // 40px
    --fs-main-navigation-desktop: 18px;
    --fs-main-navigation-mobile: 25px;
    --fs-sub-navigation-desktop: 20px;
    --fs-badge-small: 13px;
    --fs-badge-medium: 16px;
    --fs-badge-large: 19px;

    // Headlines Font Sizes
    --fs-h1: 28px;
    --fs-h1-mobile: 23px;
    --fs-h1-sub: calc(var(--fs-h1) * 0.65);
    --fs-h1-sub-mobile: calc(var(--fs-h1-mobile) * 0.65);

    --fs-h2: 24px;
    --fs-h2-mobile: 21px;
    --fs-h2-sub: calc(var(--fs-h2) * 0.65);
    --fs-h2-sub-mobile: calc(var(--fs-h2-mobile) * 0.65);

    --fs-h3: 21px;
    --fs-h3-mobile: 18px;
    --fs-h3-sub: calc(var(--fs-h3) * 0.65);
    --fs-h3-sub-mobile: calc(var(--fs-h3-mobile) * 0.65);

    --fs-h4: 18px;
    --fs-h4-mobile: 16px;
    --fs-h4-sub: calc(var(--fs-h4) * 0.65);
    --fs-h4-sub-mobile: calc(var(--fs-h4-mobile) * 0.65);

    --fs-h5: 16px;
    --fs-h5-mobile: 15px;
    --fs-h5-sub: calc(var(--fs-h5) * 0.65);
    --fs-h5-sub-mobile: calc(var(--fs-h5-mobile) * 0.65);

    --fs-h6: 14px;
    --fs-h6-mobile: 13px;

    --headline-line-height-desktop: 50px;
    --headline-line-height-mobile: 36px;
    --headline-margin-bottom: 8px;
    --subheadline-line-height-desktop: 26px;
    --subheadline-line-height-mobile: 26px;

    // Line Heights
    --lh-default: 1;
    --lh-small: var(--lh-default) * 1.15;
    --lh-base: var(--lh-default) * 1.3;
    --lh-large: var(--lh-default) * 2;

    // Background/Font Theme Colors
    --theme-on-secondary-highlight: var(--theme-cta);
    --theme-on-secondary-headline: var(--theme-attention);
    --theme-on-secondary-subheadline: var(--theme-cta-font-color);
    --theme-on-tertiary-highlight: var(--theme-cta);
    --theme-on-tertiary-headline: var(--theme-attention);
    --theme-on-tertiary-subheadline: var(--theme-cta-font-color);
    --theme-on-default-highlight: var(--theme-cta);
    --theme-on-default-headline: var(--theme-primary);
    --theme-on-default-subheadline: var(--theme-cta-font-color);

    // Button
    --bg-button: var(--theme-primary);
    --color-button: var(--color-white-true);
    --bg-button-primary: var(--theme-cta);
    --color-button-primary: var(--color-white-true);
    --bg-button-secondary: var(--theme-secondary);
    --color-button-tertiary: var(--color-white-true);
    --bg-button-tertiary: var(--theme-primary);
    --color-button-secondary: var(--color-white-true);
    --bg-button-success: var(--theme-success);
    --color-button-success: var(--color-white-true);
    --bg-button-warning: var(--theme-attention);
    --color-button-warning: var(--color-white-true);
    --bg-button-error: var(--theme-error);
    --color-button-error: var(--color-white-true);
    --border-radius-button: 10px;

    // Font Weight
    --fw-light: 300;
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
    --fw-black: 800;

    // Header
    --top-header-background: var(--theme-attention);
    --top-header-text-color: var(--color-white-true);
    --top-header-icon-color: var(--color-white-true);
    --top-header-phone-number-color: var(--color-white-true);
    --top-header-cta-color: var(--color-white-true);
    --color-search-bar-input: var(--theme-dark);
    --fs-search-bar-input: 18px;
    --border-color-main-navigation: var(--theme-attention);
    --quicklink-button-color: var(--theme-cta);
    --quicklink-text-color: var(--theme-cta-font-color);

    // Cart
    --bg-cart-mobile: var(--color-gray-250);
    --bg-cart: var(--color-gray-250);
    --border-cart-product-select: var(--theme-dark);
    --cart-product-price: var(--color-gray-800);
    --cart-product-old-price: var(--theme-primary);
    --color-checkout-delivery-cost: var(--color-gray-800);
    --color-cart-summary-total: var(--color-gray-800);
    --color-cart-summary-tax: var(--color-gray-600);
    --bg-cart-summary: var(--color-white-true);
    --color-cart-summary-installment: var(--color-turqoise);
    --bg-shipping-cost-bar: #ebf8fe;

    // Slider
    --color-dots: var(--color-gray-400);
    --color-dots--active: var(--theme-primary);

    // Menu
    --menu-color-primary: var(--theme-dark);
    --menu-color-primary-hover: var(--theme-primary);

    // Loaders
    --loader-background: rgba(255, 255, 255, 0.7);

    // CTA
    --color-teaser-right-active-box: var(--theme-beige);
    --color-teaser-right-box-border: #ece5d9;
    --color-teaser-cta-box: var(--theme-tertiary);

    // Box Shadow
    --box-shadow-default: 0 2px 5px rgba(0, 0, 0, 0.2);

    // Badges
    --color-badge-topseller-background: var(--color-gray-600);
    --color-badge-topseller-text: var(--color-white-true);
    --color-badge-bware-background: #ffee09;
    --color-badge-bware-text: var(--theme-dark);
    --color-badge-sale-background: var(--color-red);
    --color-badge-sale-text: var(--color-white-true);
    --color-badge-aktion-background: var(--color-red);
    --color-badge-aktion-text: var(--color-white-true);
    --color-badge-sparset-background: var(--color-red);
    --color-badge-sparset-text: var(--color-white-true);
    --color-badge-property-background: #90dacd;
    --color-badge-property-text: var(--theme-dark);
    --color-badge-savings-background: var(--color-white-true);
    --color-badge-savings-text: var(--color-red);
    --color-badge-savings-pdp-background: var(--color-red);
    --color-badge-savings-pdp-text: var(--color-white-true);
    --color-badge-savings-special-deal-background: var(--color-red);
    --color-badge-savings-special-deal-text: var(--color-white-true);
    --color-badge-gratis-background: #ffffffe6;
    --color-badge-gratis-text: var(--color-red);
    --color-badge-xplusy-background: var(--color-red);
    --color-badge-xplusy-text: var(--color-white-true);
    --color-badge-raten-background: #009bac;
    --color-badge-raten-text: var(--color-white-true);

    // Hero Teaser Slider
    --hero-teaser-slider-headline-color: var(--theme-primary);
    --hero-teaser-slider-subheadline-color: var(--theme-cta-font-color);
    --hero-teaser-slider-cta-color: var(--theme-cta-font-color);

    // Product
    --color-product-title: var(--theme-attention);
    --background-product-buy-button: var(--theme-primary);
    --color-product-buy-button: var(--theme-cta-font-color);
    --fs-product-title-mobile: 17px;
    --lh-product-title-mobile: 20px;
    --fs-product-title-desktop: 17px;
    --lh-product-title-desktop: 20px;
    --fs-product-description-mobile: var(--fs-s);
    --fs-product-description-desktop: 15px;
    --fs-product-title-special-deal-desktop: 19px;

    // PDP
    --pdp-color-product-title: var(--theme-attention);
    --pdp-background-buy-button: var(--theme-primary);
    --pdp-color-buy-button: var(--color-white-true);
    --pdp-color-caption: var(--theme-attention);
    --pdp-background-caption: var(--theme-secondary);
    --pdp-selected-image-border-color: var(--theme-primary);
    --pdp-slider-arrow-color: var(--theme-primary);
    --pdp-slider-arrow-background: var(--color-white-true);
    --pdp-video-thumbnail-background: var(--theme-secondary);
    --pdp-video-thumbnail-icon: var(--theme-primary);
    --pdp-abo-info-color: var(--theme-primary);

    // AccordionPDP
    --fs-accordionpdp-title: 20px;

    //PLP
    --plp-headline-color: var(--theme-cta);

    // Hero Teaser Box
    --hero-teaser-box-headline-font-size-desktop: 34px;
    --hero-teaser-box-headline-line-height-desktop: 40px;
    --hero-teaser-box-headline-font-size-mobile: 28px;
    --hero-teaser-box-headline-line-height-mobile: 36px;

    --hero-teaser-button-text-color: var(--color-white);
    --hero-teaser-button-color: var(--theme-primary);

    // Dots
    --color-slider-active-dot-default: var(--theme-primary);

    // Newsletter
    --newsletter-background-button: var(--theme-cta);
    --newsletter-color-button: var(--theme-cta-font-color);

    // Footer
    --footer-background-color: var(--theme-beige);
    --footer-font-color: var(--theme-cta);

    // Empty Cart
    --empty-cart-link: var(--color-white);

    // Product Safety
    --safety-list-line-height: 20px;
    --safety-list-fs: var(--fs-s);

    // Navigation
    --free-delivery-highlighted: var(--theme-primary);
    --main-navigation-grid-column-width-mobile: 60px auto auto 125px;
    --main-navigation-grid-column-width-tablet: 60px auto auto 142px;
    --main-navigation-grid-column-width-desktop: 112px 33% auto 142px;
    --main-navigation-grid-column-width-wide: var(--main-navigation-grid-column-width-desktop);

    --main-logo-wrapper-max-width-desktop: 112px;
    --main-logo-min-height-desktop: 78px;

    --main-logo-wrapper-min-height-mobile: 50px;
    --main-logo-height-mobile: auto;
    --main-logo-max-height-mobile: auto;

    // Ambiente Shopping
    --ambiente-tooltip-title-color: var(--theme-primary);
    --ambiente-tooltip-title-font-size: 18px;
    --ambiente-tooltip-price-color: var(--theme-dark);
    --ambiente-tooltip-link-color: var(--theme-primary);
    --ambiente-marker-inactive-background-color: #999;

    // Multi Page Checkout
    --mp-checkout-steps-background-color: var(--color-gray-200);

    --mp-checkout-steps-text-color: var(--color-white-true);

    // Border Radii
    --border-radius-default: 10px;
    --border-radius-default-inner: 8px;
    --border-radius-wide: 20px;
    --border-radius-product-image-container: 10px 10px 0 0;
    --border-radius-badge: 0 10px 10px 0;
    --border-radius-precheckout: 10px 10px 10px 0;
    --border-radius-bottom-right-badge: 10px 0 0 10px;
    --border-radius-product-card: 10px;
    --border-radius-max: 100%;

    // Content Navigation
    --content-navigation-bg: #fff;
    --content-navigation-line: #f5f5f5;
    --content-navigation-font-color: #666;
    --content-navigation-cta: var(--theme-primary);

    // Headline colors
    --color-headline: var(--color-gray-800);
    --color-subheadline: var(--color-gray-800);

    // Component Description Box
    --bg-component-description-box: var(--theme-primary);

    // Main Navigation Custom
    --main-navigation-custom-height-desktop: 128px; // height + border size
    --main-navigation-custom-separator-color: var(--theme-primary);
    --main-navigation-custom-background-color: var(--color-white-true);
    --main-navigation-custom-font-color: var(--menu-color-primary);
    --main-navigation-custom-active-color: var(--theme-primary);
    --main-navigation-custom-dropdown-background-color: var(--theme-beige);
    --main-navigation-custom-search-background-color: var(--color-white-true);
    --main-navigation-custom-search-border-color: var(--color-gray-80);
    --main-navigation-custom-search-font-color: var(--color-gray-550);
    --main-navigation-custom-quick-links-separator-color: var(--color-gray-80);
    --color-icons: var(--theme-primary);

    // Header Logo Custom
    --header-logo-custom-min-width-desktop-logo: 190px;
    --header-logo-custom-min-width-tablet-logo: 150px;
    --header-logo-custom-min-width-mobile-logo: 150px;

    // Desktop Navigation
    --desktop-navigation-font-family: var(--theme-headlines);
    --desktop-navigation-font-size: var(--fs-18);
    --desktop-navigation-font-weight: var(--fw-semibold);
    --desktop-navigation-item-font-weight: var(--fw-medium);
    --desktop-navigation-headline-font-weight: var(--fw-bold);

    // Burger Menu
    --burger-menu-font-size: var(--fs-l);
    --burger-menu-icon-color: var(--theme-primary);

    // Quick Links
    --burger-menu-quick-links-item-font-color: var(--main-navigation-custom-font-color);

    // LiveTV Button
    --live-tv-button-font-color: var(--color-white-true);

    // Special Button
    --special-button-font-color: var(--color-white-true);
    --special-button-border-color: var(--color-gray-800);
    --special-button-border-color-hover: var(--color-gray-800);
}

@include mq($until: tablet) {
    :root {
        --fs-base: 15px;
    }
}
