.loqate-input {
    position: relative;

    &--with-values {
        input {
            border-radius: var(--border-radius-product-image-container);
            border-bottom: 0;
        }
    }
}

.loqate-input__label {
    position: relative;
    display: block;
}

.loqate-input:has(.loqate-input__suggestion-list) input {
    border-color: #000;
}

.loqate-input__input {
    width: 100%;
    padding: 18px 15px 8px;
    background-color: var(--bg-input);
    border: 1px solid var(--border-input);

    &::placeholder {
        color: transparent;
    }

    &:disabled {
        border-color: var(--color-gray-400);
        color: var(--color-gray-600);
    }
}

.loqate-input--error {
    border: 1px solid var(--theme-error);
}

.loqate-input__title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 13px 15px 11px;
    color: var(--input-text);
    font-size: var(--fs-sm);
    font-weight: var(--fw-normal);
    pointer-events: none;
    transition: 0.2s ease transform;
}

.loqate-input__search-icon {
    font-size: 25px;
    position: absolute;
    left: 9px;
    top: 10px;
}

.loqate-input__title-text {
    padding-left: 18px;
}

.loqate-input__input:focus ~ .loqate-input__title,
.loqate-input__input:not(:placeholder-shown) ~ .loqate-input__title {
    pointer-events: none;
    transform: translate(-4px, -11px) scale(0.85);
}

.loqate-input__error-message {
    padding-top: 2px;
    color: var(--input-error);
    font-size: var(--fs-sm);
}

.loqate-input__suggestion-list {
    position: absolute;
    top: 46px;
    background: var(--color-white-true);
    z-index: 10;
    width: 100%;
    border: 1px solid var(--border-input);
    border-top: 0;
    max-height: 180px;
    overflow-y: scroll;

    @include mq($from: tablet) {
        max-height: 300px;
    }
}

.loqate-input__suggestion-list-item {
    cursor: pointer;
    padding: 8px 12px;

    &:hover {
        background: var(--color-white);
    }
}

.loqate-input__highlight {
    font-weight: var(--fw-semibold);
}
