.op-checkout-cart-line-item {
    .price-box__price {
        color: var(--cart-product-price);
        font-size: var(--fs-3xl);
    }
}

.op-checkout-cart-line-item__content {
    padding-bottom: 8px;
}

.op-checkout-cart-line-item__name {
    color: var(--theme-primary);
    font-weight: var(--fw-bold);
}
