.desktop-sub-navigation {
    display: none;

    position: absolute;
    top: 100%;
    border: 1px solid;
    padding: 25px;
    gap: 25px;
    background: var(--main-navigation-custom-dropdown-background-color);

    &--right {
        right: 0;
    }
}

.desktop-navigation-item--hover > .desktop-sub-navigation {
    display: flex;
}
