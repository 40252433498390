.op-checkout-cart__header {
    display: grid;
    grid-template-columns: 1fr auto 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 2px solid var(--color-border-checkout-cart);
    cursor: pointer;

    @include mq($from: desktop) {
        border-top: 1px solid var(--color-border-alt-checkout-cart);
        border-left: 1px solid var(--color-border-alt-checkout-cart);
        border-right: 1px solid var(--color-border-alt-checkout-cart);
    }
}

.op-checkout-cart__content {
    border-bottom: 1px solid var(--color-border-checkout-cart);
    padding: 10px 0 30px;

    @include mq($from: desktop) {
        padding: 10px 20px;
        border-left: 1px solid var(--color-border-alt-checkout-cart);
        border-right: 1px solid var(--color-border-alt-checkout-cart);
    }
}

.op-checkout-cart__headline {
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    display: flex;
    flex-direction: row;
}

.op-checkout-cart__total-price {
    font-family: var(--font-product-price);
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    line-height: 1;
    text-align: right;
    color: var(--color-checkout-cart-price);
}

.op-checkout-cart__tax-info {
    font-size: var(--fs-base);
    font-weight: var(--fw-regular);
    display: block;
    color: var(--color-checkout-cart-tax);
}

.op-checkout-cart__detail-arrow {
    font-size: var(--fs-h2);
    text-align: right;
    color: var(--color-checkout-cart-arrow);
}

.op-checkout-cart__arrow-icon {
    vertical-align: middle;
}

.op-checkout-cart__back-to-cart {
    margin-left: 19px;
    font-size: 12px;
    margin-top: -2px;
    background: var(--color-checkout-cart-arrow);
    color: #fff;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: var(--border-radius-default);
}
