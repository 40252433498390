/* stylelint-disable max-nesting-depth */
.special-deal {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius-product-card);
    border: 2px solid var(--color-red);
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;

    &--hover-effect-zoom {
        :hover {
            @include mq($from: desktop) {
                .special-deal__image {
                    transform: scale(1.05) translateY(-2.4%);
                }
            }
        }
    }

    .loading-indicator::after {
        width: 18px;
        height: 18px;
    }
}
/* stylelint-enable max-nesting-depth */

.special-deal__image-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-product-image-container);
}

.special-deal__badge-wrapper {
    display: flex;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;

    .catalog-badge--corner-bottom-right {
        line-height: 15px;
        padding: 1px 10px;
        word-break: break-word;
    }
}

.special-deal__badge-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.special-deal__badge-container--bottom {
    margin-top: auto;
    align-items: flex-end;

    .catalog-badge {
        max-width: 75%;
    }
}

.special-deal__image {
    position: relative;
    object-fit: cover;
    border-radius: var(--border-radius-product-image-container);
    height: 100%;
    width: 100%;
    transform: scale(1);
    transition: transform 0.3s ease;

    @include mq($until: desktop) {
        max-height: 450px;
    }
}

.special-deal__product-top {
    position: relative;
    max-height: 415px;

    @include mq($from: tablet) {
        height: 75%;
        max-height: none;
    }
}

.special-deal__video {
    position: relative;
    aspect-ratio: 16/9;

    @include mq($from: tablet) {
        position: absolute;
        inset: 0;
        aspect-ratio: auto;
    }
}

.special-deal__details-container {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;

    @include mq($from: tablet) {
        padding: 16px;
    }
}

.special-deal__title {
    margin-bottom: 4px;

    a {
        font-family: var(--font-base);
        font-weight: var(--fw-semibold);
        color: var(--color-product-title);
        font-size: var(--fs-product-title-special-deal-desktop);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;

        @include mq($until: tablet) {
            font-size: var(--fs-product-title-mobile);
        }
    }
}

.special-deal__description {
    font-size: var(--fs-product-description-mobile);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;

    @include mq($from: tablet) {
        word-break: unset;
        font-size: var(--fs-product-description-desktop);
    }

    p {
        margin: 0;
    }
}

.special-deal__product-data {
    margin-top: auto;
}

.special-deal__cta-btn-details {
    border-radius: var(--border-radius-default);
    background: var(--background-product-buy-button);
    margin-top: auto;
    color: var(--color-product-buy-button);
    text-transform: unset;
    font-size: var(--fs-base);
    font-weight: var(--fw-normal);
    letter-spacing: normal;
    min-width: unset;

    @include mq($until: tablet) {
        min-width: unset;
    }
}

.special-deal__cta-btn {
    @include mq($from: tablet) {
        margin-top: 16px;
        font-size: var(--fs-s);
    }
}

.special-deal__cta-btn-cart {
    background: var(--background-product-buy-button);
    border-radius: var(--border-radius-default);
    margin-top: auto;

    @include mq($from: tablet) {
        display: flex;
    }

    .select-button__button {
        color: var(--color-product-buy-button);
        text-transform: unset;
        font-size: 16px;

        /* stylelint-disable-next-line selector-class-pattern */
        .icon--AddToCart {
            font-size: 24px;
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .icon--ArrowRightLight {
        display: none;
    }

    .select-button__select {
        display: none;
    }
}

.special-deal__specials-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 0;

    .headlines {
        padding: 2px;

        .headlines__headline {
            font-weight: var(--fw-black);
            font-size: var(--fs-l);
            padding-top: 3px;
        }

        .headlines__subheadline {
            font-size: 15px;
            color: var(--color-text);
            padding-bottom: 5px;
        }
    }

    .special-deal__specials-bottom {
        background: #222c;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--color-black-true);
        border-bottom: 1px solid var(--color-black-true);

        &--no-headlines {
            border-radius: 10px 10px 0 0;
        }
    }
}

.special-deal__specials-top {
    border-radius: 9px 9px 0 0;
    height: 70%;
    display: flex;
    flex-direction: column;
}

.special-deal__infos {
    display: grid;
    grid-template-areas: 'badge price oldPrice';
    grid-template-columns: repeat(3, 1fr);
    margin: 8px 0;

    @include mq($from: tablet) {
        margin: 0;
        margin-top: 4px;
    }

    .catalog-badge {
        grid-area: badge;
        width: fit-content;
        height: fit-content;
        align-self: center;
        font-size: var(--fs-xs);
    }
}

.special-deal__price {
    color: var(--color-red);
    font-weight: var(--fw-medium);
    font-family: var(--font-product-price);
    font-size: var(--fs-ml);
    grid-area: price;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include mq($from: wide) {
        font-size: var(--fs-2xl);
    }
}

.special-deal__old-price {
    color: var(--menu-color-primary);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-xs);
    grid-area: oldPrice;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 4px;
    white-space: pre-wrap;
    text-align: right;

    .catalog-badge {
        grid-area: badge;
        justify-content: left;
        width: fit-content;
        font-size: var(--fs-badge-small);
    }
}

.special-deal__reviews-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
}

.special-deal__review-count {
    color: var(--color-star-text);
    margin-left: 4px;
    font-size: 12px;
    margin-top: 2px;
}

.special-deal__stars-normal {
    color: #bfbfbf;
}
