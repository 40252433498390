.header-logo {
    @include mq($until: desktop) {
        align-self: center;
    }

    &__desktop {
        display: none;

        img {
            min-height: var(--main-logo-min-height-desktop);
            aspect-ratio: auto 1/1;
        }

        @include mq($from: desktop) {
            display: block;
            width: 100%;

            > a {
                display: block;
                max-width: var(--main-logo-wrapper-max-width-desktop);
            }
        }
    }

    &__mobile {
        display: block;
        min-height: var(--main-logo-wrapper-min-height-mobile);
        max-width: 100%;

        img {
            height: var(--main-logo-height-mobile);
            max-height: var(--main-logo-max-height-mobile);
            display: inline-block;
            vertical-align: bottom;
        }

        @include mq($from: desktop) {
            display: none;
        }
    }
}

/* ::: Flip-Effect start */
.header-logo__flip-box {
    perspective: 1000px;
    width: 100%;
    height: 100%;
}

.header-logo__flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: rotateY(-180deg);
    animation: flipH 3s 0s infinite alternate ease-in-out;
}

.header-logo__flip-box-front,
.header-logo__flip-box-back {
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.header-logo__flip-box-back {
    position: absolute;
    top: 0;
    transform: rotateY(180deg);
}

@keyframes flipH {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(-180deg);
    }

    100% {
        transform: rotateY(-180deg);
    }
}

@-webkit-keyframes flipH {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(-180deg);
    }

    100% {
        transform: rotateY(-180deg);
    }
}

/* ::: Flip-Effect end */

/* ::: Fade-Effect start */

.header-logo__fade {
    position: relative;
    width: 100%;
    height: 100%;
}

.header-logo__fade-first {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.header-logo__fade-second {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    animation: fade 3s infinite alternate ease-in-out;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

/* ::: Fade-Effect end */
