.footer-text-item__column {
    width: 100%;
    padding: 19px;
    border-bottom: 1px solid;
    @include mq($from: desktop) {
        width: 25%;
        padding: 0;
        border: none;
    }
}

.footer-text-item__item {
    padding-top: 10px;
    @include mq($from: desktop) {
        background-color: transparent;
        padding: 0;
    }

    p {
        margin: 10px 0;
    }
}

.footer-text-item__column a {
    color: currentcolor;
}

.footer-text-item__heading {
    font-size: 19px;
    font-weight: var(--fw-semibold);
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @include mq($from: desktop) {
        font-size: var(--fs-footer-header-desktop);
        line-height: 30px;
        cursor: auto;
    }
}

.footer-text-item__column p {
    line-height: 26px;
    font-weight: var(--fw-normal);
}

.footer-text-item__plus {
    float: right;
    @include mq($from: desktop) {
        display: none;
    }
}

.footer-text-item__icon {
    margin-left: auto;
}
