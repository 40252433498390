.order-details {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.order-details__wrapper {
    margin-top: 20px;
}

.order-details__item-header {
    margin: 40px 0;
}

.order-details__item-title {
    font-weight: var(--fw-semibold);
    font-size: 24px;
}

.order-details__item-address {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    margin-bottom: 35px;

    @include mq($from: tablet) {
        display: flex;
        margin: 0;
        padding: 20px 0;
        border-top: 1px solid var(--theme-primary);
    }
}

.order-details__headlines {
    padding: 20px 0;
    border-bottom: 1px solid var(--theme-primary);

    .headlines__headline {
        text-align: left;
    }
}

.order-details__go-back {
    margin-top: 20px;
}

.order-details__item-payment {
    margin: 20px 0;

    @include mq($from: tablet) {
        margin: 0 0 0 10%;
    }
}

.order-details__item-shipping {
    display: flex;
    flex-direction: column;
}

.order-details__shipping-title,
.order-details__payment-title {
    font-size: 18px;
    font-weight: var(--fw-semibold);
    border-bottom: 1px solid var(--theme-primary);
    margin-bottom: 5px;

    @include mq($from: tablet) {
        border: none;
    }
}

.order-details__customer-number-text {
    margin-left: 10px;
}

.order-details__line-items {
    border-bottom: 1px solid var(--theme-primary);

    .order-details__line-item {
        border-top: 1px solid var(--theme-primary);
    }
}

.order-details__payment-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    max-height: 40px;
    color: var(--theme-primary);
}
