.wko-modal__modal-body {
    @include mq($until: desktop) {
        padding: 24px;
    }

    @include mq($until: tablet) {
        padding: 14px;
    }
}

.wko-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wko-modal__content-container {
    background: var(--theme-beige);
    border-radius: var(--border-radius-product-image-container);
    transform: translateY(8px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wko-modal__headline {
    color: var(--theme-primary);
    font-size: var(--fs-l);
    font-weight: var(--fw-semibold);
    white-space: pre-wrap;
    text-align: center;
    margin: 12px 0;
}

.wko-modal__content {
    display: flex;
    justify-content: center;
    padding: 24px;
    gap: 16px;
    color: var(--theme-primary);
    align-items: center;
}

.wko-modal__image {
    height: 75px;
    width: 75px;
    border-radius: var(--border-radius-default);
}

.wko-modal__description {
    display: flex;
    flex-direction: column;

    &--title {
        font-weight: var(--fw-semibold);
    }
}

.wko-modal__decline-button {
    border-radius: var(--border-radius-default);
    padding: 11px;
    font-size: 18px;
    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);
    width: 100%;

    @include mq($from: desktop) {
        width: 50%;
    }
}

.wko-modal__success-overlay {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 535px;

    .icon {
        border-radius: 50%;
        background: rgba(56, 170, 3, 0.2);
        color: var(--theme-success);
        width: 120px;
        height: 120px;
        padding: 24px;
    }

    .wko-modal__success-message {
        white-space: pre-wrap;
        text-align: center;
        margin-top: 12px;
        color: var(--theme-success);
        font-size: var(--fs-l);
    }
}
