.date-of-birth__container {
    display: flex;
    flex-direction: column;
}

.date-of-birth__input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-gap: 10px;
}

.date-of-birth__error-wrapper {
    width: 100%;
    min-height: 20px;
}

.date-of-birth__error-wrapper .input__error {
    display: block;
}
