// Fonts
:root {
    --ff-headline: sans-serif;
    --ff-base: sans-serif;
    --ff-title: sans-serif;
    --ff-product-price: 'Oswald', sans-serif;

    --fw-light: 300;
    --fw-thin: var(--fw-light);
    --fw-normal: 400;
    --fw-regular: var(--fw-normal);
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 600;
    --fw-black: 800;

    --fw-title: var(--fw-black);
    --fw-headline: var(--fw-semibold);
    --fw-base: var(--fw-regular);

    --lh-default: 1;
    --lh-base: var(--lh-default) * 1.45;
    --lh-small: var(--lh-default);
    --lh-big: var(--lh-default) * 2;

    --fs-base-px: 15;
    --fs-xxs: calc(0.4rem / 16) * var(--fs-base-px); // 6px
    --fs-xs: calc(0.8rem / 16) * var(--fs-base-px); // 12px
    --fs-s: calc(0.9rem / 16) * var(--fs-base-px); // 13.5px
    --fs-base: calc(1rem / 16) * var(--fs-base-px); // 15px
    --fs-l: calc(1.33rem / 16) * var(--fs-base-px); // 19.95px
    --fs-xl: calc(1.66rem / 16) * var(--fs-base-px); // 24.9px
    --fs-xxl: calc(2.66rem / 16) * var(--fs-base-px); // 39.9px
    --fs-xxxl: calc(3.6rem / 16) * var(--fs-base-px); // 54px
    --fs-xxxxl: calc(3.8rem / 16) * var(--fs-base-px); // 57px

    --fs-h1-mobile: var(--fs-xl);
    --fs-h1: var(--fs-xxl);
    --fs-h1-sub-mobile: var(--fs-l);
    --fs-h1-sub: var(--fs-xl);
    --fs-h2-mobile: var(--fs-l);
    --fs-h2: var(--fs-xl);
    --fs-h2-sub-mobile: var(--fs-base);
    --fs-h2-sub: var(--fs-l);
    --fs-h3-mobile: var(--fs-base);
    --fs-h3: var(--fs-l);
    --fs-h3-sub-mobile: var(--fs-s);
    --fs-h3-sub: var(--fs-base);
    --fs-h4-mobile: var(--fs-base);
    --fs-h4: var(--fs-l);
    --fs-h4-sub-mobile: var(--fs-s);
    --fs-h4-sub: var(--fs-base);
    --fs-h5-mobile: var(--fs-s);
    --fs-h5: var(--fs-base);
    --fs-h6-mobile: var(--fs-s);
    --fs-h6: var(--fs-base);
}
