.progress-bar__progress-bar {
    width: 100%;
    position: relative;
    display: block;
    margin: 0 auto;
    height: 4px;
    background: var(--color-gray-400);
    border-radius: 2px;
    margin-bottom: 15px;

    @include mq($from: tablet) {
        width: 50%;
    }

    .progress-bar__progress {
        display: block;
        background: var(--theme-primary);
        height: 4px;
    }
}

.progress-bar__articles-container {
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--theme-primary);
}
