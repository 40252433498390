.checkout-success-section {
    .dynamic-fieldset__fields {
        margin-bottom: 0;
    }
}

.checkout-success-section__headline {
    border-bottom: 1px solid var(--theme-primary);
    padding-bottom: 4px;
    margin-bottom: 12px;
    font-weight: var(--fw-semibold);
}

.checkout-success-section__feedback {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.checkout-success-section__icon {
    font-size: 24px;

    padding-right: 20px;
    color: var(--theme-primary);
}
