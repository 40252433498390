.main-navigation-custom {
    position: relative;
    z-index: var(--zi-main-navigation);
    background: var(--main-navigation-custom-background-color);
    border-bottom: 1px solid var(--main-navigation-custom-separator-color);

    @include mq($from: desktop) {
        height: var(--main-navigation-custom-height-desktop);
    }

    .link-open-in-tab--active {
        color: var(--main-navigation-custom-active-color);

        &::before {
            color: var(--main-navigation-custom-active-color);
        }
    }
}

.main-navigation-custom__inner {
    position: relative;
    display: grid;
    grid-template-areas:
        'logo meta'
        'mobileSearch mobileSearch';
    grid-template-columns: auto 1fr;
    column-gap: 45px;


    @include mq($from: tablet) {
        grid-template-areas:
        'logo meta';
    }

    @include mq($from: desktop) {
        grid-template-areas:
            'logo meta'
            'logo navigation';
    }
}

.main-navigation-custom__logo {
    display: flex;
    align-items: center;
    max-height: 87px;
    margin: 20px 0;
    grid-area: logo;
    gap: 5px;
    padding-left: 2px;

    @include mq($from: tablet) {
        padding-left: 0;
    }

    .burger-menu-wrapper {
        display: block;

        @include mq($from: desktop) {
            display: none;
        }
    }
}

.main-navigation-custom__meta {
    grid-area: meta;
    display: grid;
    grid-template-areas: "metaRight";
    gap: 45px;

    @include mq($from: tablet) {
        grid-template-areas: "metaLeft metaRight";
        grid-template-columns: auto 131px;
    }

    @include mq($from: desktop) {
        grid-template-columns: auto 224px;
    }
}

.main-navigation-custom__meta-left {
    display: none;
    grid-area: metaLeft;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    flex: 1;

    @include mq($from: tablet) {
        display: flex;
    }

    @include mq($from: desktop) {
        align-items: flex-end;
    }

    .special-button {
        display: none;

        @include mq($from: desktop) {
            display: block;
        }
    }

    .search-bar {
        padding: 0;
    }
}

.main-navigation-custom__search,
.main-navigation-custom__mobile-search {
    flex: 1;

    .search-bar__wrapper {
        position: initial;
        padding: 0;

        @include mq($from: desktop) {
            position: relative;
        }
    }

    .search-input__input {
        position: relative;
        height: 40px;
        line-height: 1;
        border: 1px solid var(--main-navigation-custom-search-border-color);
        color: var(--main-navigation-custom-search-font-color);
        font-size: var(--fs-sm);
        border-radius: 0;
        padding: 12.5px 64px 12.5px 14px;
        z-index: 2;
        top: initial;
        transform: none;

        &::placeholder {
            color: var(--main-navigation-custom-search-font-color);
        }
    }

    .search-input__input--autocomplete {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: var(--main-navigation-custom-search-background-color);
    }

    .search-input__icon-button {
        top: calc(50% - 13px);
        right: 0;
        font-size: 38px;
        height: 26px;
        width: 50px;
        z-index: 3;
        display: flex;
        border-left: 1px solid var(--main-navigation-custom-search-border-color);
        align-items: center;
        justify-content: center;

        svg {
            fill: var(--main-navigation-custom-search-font-color);
            font-size: 22px;
        }
    }
}

.main-navigation-custom__meta-right {
    grid-area: metaRight;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 14px;

    @include mq($from: tablet) {
        padding-right: 12px;
    }

    @include mq($from: desktop) {
        padding-right: 4px;
        align-items: flex-end;
    }

    .burger-menu-wrapper {
        display: none;

        @include mq($from: desktop) {
            display: block;
            margin: 3px 0;
        }
    }

    .user-menu {
        gap: 12px;
        margin: 3px 0;

        .dropdown__header {
            /* stylelint-disable-next-line selector-class-pattern */
            .icon--ChevronDown {
                display: none;
            }

            .dropdown__item-flag {
                margin: 0 4px 0 0;
            }
        }
    }

    .user-menu__account {
        svg {
            font-size: 24px;
            margin-bottom: 1px;
        }
    }

    .user-menu__cart-link {
        padding: 5px 4px 4px;
    }

    .user-menu__cart-item-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: var(--fw-normal);
        top: 0;
        left: 15px;
        font-size: var(--fs-xs);
        padding: 0;
    }
}

.main-navigation-custom__nav {
    display: none;

    grid-area: navigation;

    @include mq($from: desktop) {
        display: block;
    }
}

.main-navigation-custom__mobile-search {
    grid-area: mobileSearch;
    padding: 0 14px 14px;

    @include mq($from: tablet) {
        display: none;
    }
}

.main-navigation-custom__meta,
.main-navigation-custom__nav {

    @include mq($from: desktop) {
        height: calc((var(--main-navigation-custom-height-desktop) - 1px) / 2);
    }
}

.main-navigation-custom:has(.desktop-navigation--hover) {
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, .25);
}
