/** Any global variables go here */
:root {
    --border-radius: 10px;
    --border-radius-sm: 2px;

    --sp-base: 1rem;
    --sp-xs: 0.5rem;
    --sp-sm: 0.8rem;
    --sp-md: var(--sp-base);
    --sp-lg: 1.5rem;
    --sp-xl: 2rem;
    --sp-xxl: 3rem;
}
