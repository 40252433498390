.background--primary {
    .hero-teaser-slider__item-active {
        .headlines__headline,
        .headlines__subheadline {
            color: var(--theme-primary);
        }
    }

    .hero-teaser-slider__link,
    .headlines__headline {
        color: var(--color-white-true);
    }
}

.background--tertiary {
    .hero-teaser-slider__item-active {
        .headlines__headline,
        .headlines__subheadline {
            color: var(--theme-primary);
        }
    }
}

.hero-teaser-slider {
    display: grid;
    grid-template-columns: 100%;

    @include mq($until: tablet) {
        background: var(--color-teaser-cta-box);
    }

    @include mq($from: tablet) {
        padding: 50px 0;
        grid-template-columns: 79% auto;
    }

    .hero-teaser__video {
        @include mq($from: tablet) {
            aspect-ratio: auto;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.hero-teaser-slider__left-slider {
    position: relative;
    margin-bottom: 0;

    @include mq($from: tablet) {
        border-top-left-radius: var(--border-radius-default);
        border-bottom-left-radius: var(--border-radius-default);
        overflow: hidden;
    }

    .arrow-button {
        border-radius: 40px;
        margin-left: 15px;
        margin-right: 15px;
        top: 25%;

        @include mq($from: tablet) {
            display: none;
        }
    }
}

.hero-teaser-slider__left-slider__slider {
    height: 100%;
    margin: 0;

    .slider__inner-wrapper {
        height: 100%;

        .swiper {
            height: 100%;
        }
    }
}

.hero-teaser-slider__right-items {
    position: relative;
    display: none;
    font-family: var(--font-base);

    @include mq($from: tablet) {
        display: grid;
        width: 100%;
    }

    .title-h3 {
        font-family: var(--font-base);
    }

    a {
        text-decoration: underline;
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }
    }
}

.hero-teaser-slider__text-content-container {
    display: table;

    @include mq($from: tablet) {
        background: var(--color-white-true);
        border-top: 1px solid var(--color-teaser-right-box-border);
        border-left: 1px solid var(--color-teaser-right-box-border);
        border-right: 1px solid var(--color-teaser-right-box-border);
    }

    &:first-of-type {
        border-top-right-radius: var(--border-radius-default);
    }

    &:last-of-type {
        border-bottom-right-radius: var(--border-radius-default);
        border-bottom: 1px solid var(--color-teaser-right-box-border);
    }

    &.hero-teaser-slider__item-active {
        background: var(--color-teaser-right-active-box);
    }
}

.hero-teaser-slider__text-wrapper {
    padding: 10px 20px;
    display: table-cell;
    vertical-align: middle;

    &:not(.hero-teaser-slider__item-active) {
        &:hover {
            cursor: pointer;
        }
    }

    .headlines {
        padding: 0 0 16px;
        text-align: left;
    }

    .headlines__headline {
        color: var(--hero-teaser-slider-headline-color);
    }

    .headlines__subheadline {
        color: var(--hero-teaser-slider-subheadline-color);
    }

    .hero-teaser-slider__link {
        color: var(--hero-teaser-slider-cta-color);
        @include mq($from: tablet, $until: desktop) {
            font-size: 12px;
        }
    }
}
