@mixin full-width {
    position: relative;
    display: grid;
    grid-template-areas: '. wrapperContainer .';
    width: var(--max-width);
    grid-template-columns: auto var(--max-width) auto;
    color: var(--color-text);

    @include mq($from: desktop) {
        left: calc((var(--max-width) - var(--breakpoint-desktop)) / -2);
        grid-template-columns: auto var(--breakpoint-desktop) auto;
    }
}

@mixin overflow {
    &.responsive-full-page-width-wrapper--overflow-both {
        grid-template-areas: 'wrapperContainer';
        grid-template-columns: var(--max-width);
    }
}

.responsive-full-page-width-wrapper {
    &--mobile {
        @include mq($until: tablet) {
            @include full-width;
            @include overflow;
        }
    }

    &--tablet {
        @include mq($from: tablet, $until: desktop) {
            @include full-width;
            @include overflow;
        }
    }

    &--desktop {
        @include mq($from: desktop) {
            @include full-width;
            @include overflow;
        }
    }
}

.responsive-full-page-width-wrapper__container {
    grid-area: wrapperContainer;
}
