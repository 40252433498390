.personal-info {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.personal-info__headlines {
    padding: 0;
}

.personal-info__wrapper {
    font-family: var(--ff-menu);

    h1,
    h2,
    h3 {
        font-family: var(--ff-menu);
    }

    .headlines__headline {
        text-align: left;
        border-bottom: 1px solid var(--theme-primary);
        padding: 20px 0;
    }
}

.personal-info__general-info,
.personal-info__customer-identification {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--theme-primary);
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}

.personal-info__section {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}

.personal-info__section:not(:last-child) {
    border-bottom: 1px solid var(--theme-primary);
}

.personal-info__customer-credentials {
    border-bottom: 0 solid var(--theme-primary);
}

.personal-info__reset {
    margin-left: 5px;
}

.personal-info__billing-address {
    margin: 15px 0;
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.personal-info__customer-number-text {
    margin-left: 10px;
}

.personal-info__edit-btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    margin: 15px 0;
}

.personal-info__password {
    margin-bottom: 15px;
}

.personal-info__submit {
    border-radius: var(--border-radius-default);
}

.personal-info__billing-address-container {
    display: flex;
    align-items: unset;
}
