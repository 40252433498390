.logo-slider-basic-slide__image-container {
    height: 80px;
    padding: 10px;

    picture {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.logo-slider-basic-slide__image {
    max-height: 70%;
    aspect-ratio: auto 1/1;

    @include mq($from: tablet) {
        min-width: 128px;
    }
}
