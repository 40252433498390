.register__password-requirements {
    margin-bottom: 20px;
}

.register__content-wrapper {
    @include mq($until: desktop) {
        padding: 0 20px;
    }

    * {
        font-family: var(--font-base);
    }
}

.register__headline {
    padding: 10px 0 0;

    @include mq($from: tablet) {
        padding: 20px 0 0;
    }
}

.register__headline .headlines__headline {
    color: var(--theme-primary);
}

.register__password {
    margin-bottom: 20px;
}

.register__password-confirmation {
    margin-bottom: 20px;
}

.register__password-headline-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
}

.register__password-confirm {
    margin-bottom: 10px;
}

.register__wrapper {
    display: block;
    margin-top: 20px;

    @include mq($from: tablet) {
        display: flex;
        gap: 50px;
    }
}

.register__gdpr-checkbox {
    margin-bottom: 20px;
    margin-top: 15px;
}

.register-addresses__hint {
    margin-bottom: 15px;
}

.register__information {
    flex: 1 1;
}

.register__form {
    flex: 2 1;
}

.register__submit-button {
    text-align: center;

    button {
        border-radius: var(--border-radius-default);
        margin-bottom: 30px;
    }
}

.register__full-name {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
