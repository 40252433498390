.product-grid--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px 12px;
    grid-auto-rows: minmax (1fr, 1fr);
    padding: 24px 12px 30px;
    grid-auto-flow: dense;

    @include mq($from: tablet) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        grid-auto-rows: minmax (1fr, max-content);
    }

    @include mq($from: desktop) {
        padding: 30px 0;
        grid-template-columns: repeat(5, 1fr);

        &--col-3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &--col-4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &--col-5 {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .product-grid__product-element {
        border-radius: var(--border-radius-default);
    }

    .product-grid__content-container {
        height: 100%;
    }

    .product-grid__special-deal {
        grid-column: span 2;
    }
}
