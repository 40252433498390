.cart-empty-state__headline {
    text-align: center;
    color: var(--theme-primary);
    margin-bottom: 8px;

    @include mq($from: tablet) {
        margin-bottom: 16px;
    }
}

.cart-empty-state__subline {
    text-align: center;
    margin-bottom: 12px;

    @include mq($from: tablet) {
        font-size: var(--fs-l);
    }
}

.cart-empty-state__icon {
    text-align: center;
    margin-top: 2%;
    font-size: 66px;
}

.cart-empty-state__text {
    text-align: center;
    margin-top: 2%;
    color: var(--theme-primary);
}

.cart-empty-state__button {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
}

.cart-empty-state__empty-link {
    border-radius: var(--border-radius-default);
    padding: 10px 28px;
    background: var(--quicklink-button-color);
    min-width: 185px;
    border-color: transparent;
    color: var(--empty-cart-link);
}
