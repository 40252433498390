.mobile-menu {
    position: fixed;
    inset: 0;
    z-index: var(--zi-mobile-menu);
    background: var(--color-white-true);
    overflow: scroll;

    @include mq($from: desktop) {
        display: none;
    }
}

div.mobile-menu__header {
    grid-template-areas:
        'mobileMenuButton logo logo userMenu';

    .header-logo {
        grid-area: logo;
    }

    .user-menu {
        grid-area: userMenu;
    }
}

.mobile-menu__mobile-button {
    grid-area: mobileMenuButton;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    svg {
        font-size: 35px;
        color: var(--theme-primary);
    }
}

.mobile-menu__navigation {
    > ul {
        margin: 0;
    }
}
