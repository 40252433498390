.cross-selling-banner {
    padding: 30px 27px 20px;
    background: var(--theme-secondary);
    color: var(--theme-on-secondary-headline);
}

.cross-selling-banner__container {
    max-width: 600px;
    margin: 0 auto;
}

.cross-selling-banner__headline {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: var(--fw-bold);
    text-align: center;
}

.cross-selling-banner__image {
    margin: 0 auto 20px;
}
