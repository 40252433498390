.mp-checkout-summary {
    background: var(--bg-checkout-summary);

    @include mq($from: desktop) {
        overflow: auto;
        position: sticky;
        top: 20px;
    }
}

.mp-checkout-summary__header {
    font-size: var(--fs-h2);
    font-weight: var(--fw-semibold);
    color: var(--color-headline);
    padding-bottom: 15px;
}

.mp-checkout-summary__footer {
    margin-top: 15px;
    color: var(--color-gray-500);
}

.mp-checkout-summary__footer-legal-text {
    padding-bottom: 15px;
    font-size: 0.9em;
}

.mp-checkout-summary__footer-legal-text a {
    text-decoration: underline;
    color: var(--color-gray-500);
    font-weight: 600;
}

.mp-checkout-summary__legal-optin {
    font-size: 0.9em;
}

.mp-checkout-summary__legal-optin .checkbox__label {
    color: var(--color-gray-500);
}

.mp-checkout-summary__legal-optin .checkbox__box {
    border-color: var(--color-gray-500);
}

.mp-checkout-summary__buynow-button {
    padding: 20px;
    margin-top: 15px;
    font-size: var(--fs-xl);
    height: auto;
}

.mp-checkout-summary__delivery-info {
    font-size: var(--fs-18);
    font-weight: var(--fw-semibold);
    margin-top: 25px;
}

.mp-checkout-summary__usp::before {
    content: none;
}

.mp-checkout-summary__headline-banner {
    padding: 10px;
    background-color: var(--color-gray-300);
    font-size: var(--fs-l);
    font-weight: var(--fw-semibold);
    margin-bottom: 20px;
}

.mp-checkout-summary__payment-info,
.mp-checkout-summary__products {
    margin-bottom: 20px;
}
