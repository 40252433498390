.order-overview {
    padding: 0 20px;

    font-family: var(--font-menu);

    h1,
    h2,
    h3 {
        font-family: var(--font-menu);
        font-weight: var(--fw-semibold);
        padding: 0;
    }

    @include mq($from: tablet) {
        padding: 0;
    }
}

.order-overview__headlines {
    border-bottom: 1px solid var(--theme-primary);
    padding: 0;
}

.order-overview__headlines .headlines__headline {
    text-align: left;
    padding: 20px 0;
}

.order-overview__item-text {
    @include mq($from: tablet) {
        width: 30%;
        margin-right: 20px;
    }
}

.order-overview__item {
    padding: 20px 0;

    @include mq($from: tablet) {
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.order-overview__item:first-child {
    border-top: none;
}

.order-overview__item:last-child {
    border-bottom: 1px solid var(--theme-primary);
}

.order-overview__checkout-button {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: var(--border-radius-default);
    font-weight: var(--fw-medium);
    margin-bottom: 20px;
    width: 100%;

    @include mq($from: tablet) {
        margin-bottom: 0;
    }
}

.order-overview__item-sum {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: var(--fw-semibold);
    margin-bottom: 15px;
}

.order-overview__item-sum-nr {
    margin-left: 16px;
    font-size: 16px;
    color: var(--theme-primary);
}

.order-overview__item-totals {
    font-size: 16px;
    font-weight: var(--fw-semibold);
    color: var(--theme-primary);
}

.order-overview__item-title {
    font-size: 16px;
    font-weight: var(--fw-semibold);
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.order-overview__item-checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 65px;
}

.order-overview__share-icon {
    font-size: 24px;
    margin-top: 27px;
    margin-right: 20px;

    @include mq($from: tablet) {
        margin-top: 9px;
        margin-right: 0;
    }
}

.order-overview__item-load-more {
    margin: 20px 0;
}

.order-overview__load-more-btn {
    border-radius: var(--border-radius-default);
    margin: 0 auto;
    display: block;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
}
