.flip-logo__flip-box {
    perspective: 1000px;
    width: 100%;
    height: 100%;
}

.flip-logo__inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: rotateY(-180deg);
    animation: flipH 3s 0s infinite alternate ease-in-out;
}

.flip-logo__front,
.flip-logo__back {
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(0deg);
    display: flex;
    align-items: center;
}

.flip-logo__back {
    position: absolute;
    top: 0;
    transform: rotateY(180deg);
}

@keyframes flipH {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(-180deg);
    }

    100% {
        transform: rotateY(-180deg);
    }
}

@-webkit-keyframes flipH {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        transform: rotateY(0deg);
    }

    80% {
        transform: rotateY(-180deg);
    }

    100% {
        transform: rotateY(-180deg);
    }
}
