.checkout-success-message {
    font-size: 16px;
    color: var(--color-black-true);
    text-align: left;
    margin-bottom: 50px;
    padding: 20px;

    @include mq($until: tablet) {
        margin: 30px 0 0;
    }

    p {
        margin: 0 0 12px;

        &:has(+ ul) {
            margin-bottom: 0;
        }
    }

    ul {
        margin-bottom: 12px;

        li {
            margin-bottom: 0;
            padding-left: 0;
        }
    }

    .input__helper-text {
        margin-top: 12px;
    }
}

.checkout-success-message__header {
    text-align: center;
    padding: 0 0 20px;
}

.checkout-success-message__icon {
    font-size: 86px;
    color: var(--theme-primary);

    .checkout-success-message--error & {
        color: var(--theme-error);
    }
}

.checkout-success-message__headline {
    margin-bottom: 10px;

    .headlines {
        padding: 0;
    }
}

.checkout-success-message__text {
    margin: 0 0 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.checkout-success-message__link {
    color: inherit;
    text-decoration: underline;
    font-weight: var(--fw-bold);
}

.checkout-success-message__form {
    text-align: left;
}

.checkout-success-message__subscriptions {
    width: fit-content;

    &--center {
        margin: 0 auto;
    }

    &--left {
        margin-left: auto;
    }

    &--right {
        margin-right: auto;
    }
}

.checkout-success-message__submit {
    margin: 20px 0;
}
