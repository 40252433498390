// Fonts
:root {
    --ff-menu: 'Nunito', sans-serif;
    --ff-headline: 'Nunito', sans-serif;
    --ff-base: 'Nunito', sans-serif;
    --ff-title: 'Nunito', sans-serif;
    --ff-product-price: 'Oswald', sans-serif;

    --fw-title: 600;
    --fw-headline: 500;
    --fw-base: 400;
    --fw-menu: 600;
    --fw-product-price: 500;
}
