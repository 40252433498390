ul {
    padding: 0;
    margin: 0 0 0 20px;

    li {
        position: relative;
        padding-left: 5px;
        margin: 0 0 5px 10px;
    }
}

ol {
    padding-left: 20px;

    li {
        margin-bottom: 10px;
        padding-left: 10px;
    }
}
