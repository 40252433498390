.authorization-modal__authorize-wrapper {
    text-align: center;
}

.authorization-modal__attention-icon {
    font-size: 40px;
    color: var(--theme-tertiary);
}

.authorization-modal__attention-wrapper {
    padding: 20px 25px;
    border-radius: var(--border-radius-default);
    display: inline-block;
    background: var(--theme-primary);
}

.authorization-modal__authorize-header {
    margin-top: 15px;
}

.authorization-modal__yes,
.authorization-modal__no {
    margin: 8px;
}

.authorization-modal__shop-name {
    color: var(--theme-primary);
    font-size: 1.1em;
}
