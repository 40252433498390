.download-with-image-and-text-item__content {
    padding: 15px 20px;
    text-align: center;
}

.download-with-image-and-text-item__button-container {
    text-align: center;
}

.download-with-image-and-text-item--rounded {
    padding-top: 30px;
}

.download-with-image-and-text-item__image-container {
    margin: 0 20px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        padding-top: 100%;
        display: block;
    }
}

.download-with-image-and-text-item__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
