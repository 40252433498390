.asymmetric-image {
    padding: 20px 0 50px;
}

.asymmetric-image--title-position-top {
    .asymmetric-image__content {
        top: 32px;
    }
}

.asymmetric-image--title-position-bottom {
    .asymmetric-image__content {
        bottom: 32px;
    }
}

.asymmetric-image__content-container {
    position: relative;
}

.asymmetric-image__image-container {
    height: 350px;
    margin-left: 10%;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    @include mq($from: tablet) {
        height: 350px;
    }

    @include mq($from: desktop) {
        height: 450px;
    }
}

.asymmetric-image__content {
    position: absolute;
    display: flex;
    left: 0;
    flex-direction: column;
    align-items: flex-start;

    .asymmetric-image__image-title,
    .asymmetric-image__image-subtitle {
        display: block;
        margin-bottom: 4px;
        margin-left: 8px;
        padding: 4px 10px;

        @include mq($from: tablet) {
            margin-bottom: 8px;
            margin-left: 16px;
        }
    }
}
