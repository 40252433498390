.card-extension {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.card-extension__icon {
    margin-right: 8px;
    position: relative;
    top: 2px;
    color: var(--color-red);
}

.card-extension__submit {
    padding: 12px 18px;
    max-width: 316px;
}
