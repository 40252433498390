/* Correct positioning of edit-button */
.br-component--is-preview {
    position: relative;
}

/* Correct position in CSS grid */
.br-component__account-navigation {
    grid-area: navigation;
}

.br-component__account-content {
    grid-area: content;
}
