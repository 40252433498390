.desktop-navigation-item {
    font-size: var(--desktop-navigation-font-size);
    font-family: var(--desktop-navigation-font-family);
    position: initial;
    list-style: none;
    margin: 0;
    padding: 0;
    color: var(--main-navigation-custom-font-color);
    font-weight: var(--desktop-navigation-font-weight);

    &--hover,
    .link-open-in-tab--active {
        color: var(--main-navigation-custom-active-color);

        .desktop-navigation-item__link-text {
            border-color: var(--main-navigation-custom-active-color);
            color: var(--main-navigation-custom-active-color);
        }

        svg {
            fill: var(--main-navigation-custom-active-color);
        }
    }

    &--hover {
        .desktop-navigation-item__arrow {
            transform: rotate(180deg);
        }
    }

}

.desktop-navigation-item__link {
    color: var(--main-navigation-custom-font-color);
}

.desktop-navigation-item__link-text {
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 20px 0 18px;
    border-bottom: 3px solid transparent;
}

.desktop-navigation-item__arrow {
    font-size: 16px;
    transition: transform 0.3s ease;
}
