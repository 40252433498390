/* Please file all your z-index values in here */

:root {
    --zi-cart-product: 0;
    --zi-btn: 1;
    --zi-login-form-loading: 2;
    --z-index-slider-arrows: 100;
    --zi-table-cell-first-child: 100;
    --zi-search-backdrop: 101;
    --zi-main-navigation: 102;
    --zi-search-bar-autosuggest: 103;
    --zi-search-bar: 104;
    --zi-login-dropdown: 105;
    --zi-flyout: 120;
    --zi-badge: 130;
    --zi-sticky-button: 140;
    --zi-index-dropdown: 200;
    --zi-loading: 200;
    --zi-emarsys: 997;
    --zi-sticky-bar: 998;
    --zi-main-menu-open: 999;
    --zi-modal: 1000;
    --zi-precheckout: 2000;
    --zi-mobile-menu: 2001;
    --zi-country-switch-modal-overlay: 2002;
}
