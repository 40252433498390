.price-box__price--rate {
    display: flex;
    flex-direction: column;

    .price-box__total-installment-price {
        font-family: var(--font-base);
        font-size: var(--fs-s);
        font-weight: var(--fw-normal);
        margin-top: 6px;
        white-space: break-spaces;
        margin-bottom: 8px;
    }

    .pre-checkout-product__price-box__total-installments-price {
        font-family: var(--font-base);
        font-size: var(--fs-xs);
        font-weight: var(--fw-normal);
        white-space: break-spaces;
    }
}

.price-box__cheaper-set-box {
    display: flex;
    flex-direction: column;
    font-size: var(--fs-s);
    font-weight: 700;
}

.price-box__old-price {
    font-size: var(--fs-s);
    font-weight: var(--fw-normal);
    color: var(--color-black-true);
}

.price-box__amount-text {
    font-weight: var(--fw-normal);
    font-family: var(--font-base);
}

.price-box__cheaper-set-quantity-advantage-text {
    font-family: var(--font-base);
    color: var(--color-black-true);
}

.price-box__unit-price {
    color: var(--color-black-true);
}

.price-box__price--club-price {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .catalog-badge {
        border-radius: var(--border-radius-badge);
    }
}

.price-box__per-deliver-info {
    font-family: var(--font-base);
    font-size: var(--fs-base);
    font-weight: var(--fw-light);
    color: var(--color-black);
    margin-left: 5px;
}
