.login-and-register {
    display: grid;
    gap: 10px 50px;
    grid-template-rows: 1fr;
    grid-template-areas:
        'topHeadlines'
        'loginHeadlines'
        'loginForm'
        'loginCta'
        'loginLinks'
        'registerHeadlines'
        'registerContent'
        'registerCta';
    position: relative;
    margin: 20px;

    @include mq($from: tablet) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'topHeadlines topHeadlines'
            'loginHeadlines registerHeadlines'
            'loginForm registerContent'
            'loginCta registerCta'
            'loginLinks .';
        margin: 20px 50px;
    }
}

.login-and-register__headlines {
    padding: 20px 0 0;

    @include mq($until: tablet) {
        padding: 10px 0;
    }
}

.login-and-register__headlines .headlines__subheadline {
    font-weight: var(--fw-normal);
}

.login-and-register__login-headlines {
    grid-area: loginHeadlines;
}

.login-and-register__register-headlines {
    grid-area: registerHeadlines;

    @include mq($until: tablet) {
        margin-top: 30px;
    }
}

.login-and-register__register-text {
    grid-area: registerContent;
}

.login-and-register__register-button {
    grid-area: registerCta;
    width: 100%;
    line-height: 1.15;
}

.login-and-register__register {
    border-radius: var(--border-radius-default);
}

.login-and-register__top-headlines {
    grid-area: topHeadlines;
}
