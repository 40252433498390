.voucher-row {
    display: flex;
    gap: 5px;

    .op-checkout-summary__mini-cart-summary-line {
        width: 100%;
    }
}

.voucher-row__remove-icon {
    margin-top: 2px;
    font-size: var(--fs-sm);
}

.voucher-row__remove-button {
    cursor: pointer;
}

.voucher-row__voucher-valid {
    color: var(--theme-success);
}

.voucher-row__voucher-invalid {
    color: var(--theme-error);
}

.voucher-row__label {
    display: flex;
}

.voucher-row__value {
    font-family: var(--font-product-price);
    text-align: right;
    min-width: 64px;
    font-size: var(--fs-sm);
}

.voucher-row__voucher-overlay {
    &--flip-left {
        border-left: none;
        border-right: 3px solid var(--theme-error);
        left: -224px;

        @include mq($from: tablet) {
            border-left: none;
            border-right: 3px solid var(--theme-error);
            left: -180px;
            bottom: 36px;
        }
        @include mq($from: desktop) {
            border-left: none;
            border-right: 3px solid var(--theme-error);
            left: -224px;
            bottom: 16px;
        }
    }
}
