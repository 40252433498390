.toggle-play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: 0.4;
    z-index: 2;
    cursor: pointer;
    border-radius: 100%;

    svg {
        width: 30px;
        height: 30px;
    }
}

.toggle-play-button__play-button-color {
    color: var(--theme-primary);
    opacity: 1;
}
