a .content-slider__slide {
    cursor: pointer;
}

.content-slider-basic-slide__content {
    padding: 0 20px;
    text-align: center;
}

.content-slider {
    .headlines__headline {
        color: var(--color-headline);
        line-height: var(--lh-small);
        margin-bottom: var(--headline-margin-bottom);

        @include mq($from: tablet) {
            line-height: var(--lh-small);
        }
    }

    .headlines__subheadline {
        margin: 0 4px;
        line-height: var(--lh-small);

        @include mq($from: tablet) {
            line-height: var(--lh-small);
        }
    }

    .slider__inner-wrapper--with-arrows {
        @include mq($from: tablet, $until: desktop) {
            margin-left: 55px;
            margin-right: 55px;

            .arrow-button {
                display: flex;
            }
        }
    }
}

.content-slider__mobile {
    color: var(--color-text);
}

.content-slider__swiper-server {
    .content-slider-basic-slide {
        max-width: 250px;
    }
}
