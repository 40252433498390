.info-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border: 1px solid var(--color-gray-400);
    padding: 20px;
    margin: 0 15px;

    @include mq($from: tablet) {
        margin: 0;
    }

    &__icon {
        display: flex;

        svg {
            width: 36px;
            height: 36px;
            color: var(--theme-primary);
        }
    }

    &__text {
        font-size: var(--fs-s);
        color: var(--color-gray-500);
    }
}
