.content-slider-basic-slide__content {
    padding: 15px 20px;
}

.content-slider-basic-slide__image {
    width: 100%;
}

.content-slider-basic-slide--rounded {
    padding-top: 30px;

    .content-slider-basic-slide__image-container {
        margin: 0 20px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        &::before {
            content: '';
            padding-top: 100%;
            display: block;
        }
    }

    .responsive-image__img.content-slider-basic-slide__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
