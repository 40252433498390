.product-stage-description__preview {
    position: relative;
    font-size: 14px;
}

.product-stage-description__preview--oversized {
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 3em;
        width: 100%;
        background: linear-gradient(
            var(--bg-product-stage-description-gradient-start) 0%,
            var(--bg-product-stage-description-gradient-end) 100%
        );
    }
}

.product-stage-description__show-more-button {
    padding: 4px 10px;
    background: var(--bg-product-stage-description-show-more-button);
    color: var(--color-product-stage-description-show-more-button);
    font-size: var(--fs-xs);
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
}
