.user-manuals {
    padding: 0 20px;
    margin-bottom: 20px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.user-manuals__headlines {
    padding: 20px 0;
}

.user-manuals__input-wrapper {
    position: relative;
}

.user-manuals__input {
    padding-right: 58px;
    width: 100%;
    line-height: 44px;
    border-radius: var(--border-radius-default);
    border: 1px solid var(--theme-primary);
    color: var(--color-search-bar-input);
    font-size: var(--fs-search-bar-input);
    padding-left: 24px;
    margin-bottom: 20px;
}

.user-manuals__table-wrapper {
    overflow: auto;
    width: calc(var(--max-width) - 40px);
    margin-bottom: 20px;

    @include mq($from: tablet) {
        width: 100%;
    }
}

.user-manuals__table {
    width: 100%;
    border-collapse: collapse;

    thead tr {
        border-bottom: 2px solid var(--theme-primary);
        white-space: nowrap;
    }

    tbody tr {
        border-bottom: 1px solid var(--theme-primary);

        &:last-child {
            border-bottom: none;
        }
    }

    th,
    td {
        padding: 10px;
        text-align: center;

        &:first-child,
        &:nth-child(2) {
            text-align: left;
            min-width: 200px;
            word-break: break-word;
        }

        @include mq($from: tablet) {
            width: 150px;

            &:first-child {
                width: calc(100% - 500px);
            }

            &:nth-child(2) {
                width: 200px;
            }
        }

        @include mq($from: desktop) {
            &:first-child {
                width: calc(100% - 600px);
            }

            &:nth-child(2) {
                width: 300px;
            }
        }

        a {
            color: currentcolor;
            text-decoration: underline;
        }
    }
}

.user-manuals__icon-button {
    all: unset;
    position: absolute;
    right: 20px;
    top: 16px;
    cursor: pointer;
}
