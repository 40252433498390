/* stylelint-disable declaration-no-important */
:root {
    --slider-gutter-mobile: 10px;
    --slider-arrow-height: 40px;
}

.slider {
    width: 100%;
}

.slider__inner-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    &--with-arrows {
        .slider__inner-wrapper--arrows-wrapper {
            display: contents;
        }

        .arrow-button {
            display: none;

            @include mq($from: desktop) {
                display: flex;
            }
        }

        .arrow-button--left {
            left: -54px;
        }

        .arrow-button--right {
            right: -54px;
        }
    }
}

.swiper {
    width: 100%;
}

.product-slider {
    .swiper {
        padding-bottom: 5px;
    }

    .swiper-slide {
        height: auto;
    }
}

.swiper-wrapper {
    user-select: none;
    padding-bottom: 8px;
    // workaround for https://github.com/nolimits4web/swiper/issues/3431
    width: 100vw;
}

.arrow-button--navigation-disabled {
    display: none !important;
}

.slider__pagination {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    padding-bottom: 10px;
}

.slider__pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #0003;
    margin: 0 4px;
}

.slider__pagination-disabled {
    pointer-events: none;
    cursor: default;
}

.slider__pagination-bullet-active {
    background: var(--theme-primary);
    opacity: 1;
}

.slider__pagination-bullet-active-animated {
    opacity: 1;
    width: 35px;
    border-radius: 5px;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, #e5e5e5 50%, var(--theme-primary) 50%);
    animation: slideBackground var(--swiper-pagination-bullet-animation-duration) ease-out forwards;
}

.slider__pagination-bullet-active-animation-completed {
    .slider__pagination-bullet-active-animated {
        background-position: -100% 0 !important;
    }
}

.slider__pagination-bullet-active-animation-paused-on-hover {
    &:hover {
        .slider__pagination-bullet-active-animated {
            animation-play-state: paused;
        }

        .slider__pagination:hover {
            .slider__pagination-bullet-active-animated {
                animation-play-state: running;
            }
        }
    }
}

@keyframes slideBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -100% 0;
    }
}

.background--primary {
    .slider__pagination-bullet-active {
        background: var(--color-white-true);
    }
}

.swiper-pagination-clickable {
    .slider__pagination-bullet {
        cursor: pointer;
    }
}

.swiper-pagination-lock {
    display: none;
}

.swiper-pagination-horizontal {
    top: var(--swiper-pagination-top, 100%) !important;
}

/* CUSTOM OPACITY ANIMATION */
.slider__custom-transition-animation .swiper-slide {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slider__custom-transition-animation .swiper-slide.swiper-slide-active {
    opacity: 1;
}
