.product-stage-logos {
    display: flex;
}

.product-stage-logos__logo {
    background: var(--bg-product-stage-logos);
    object-fit: scale-down;
    margin-right: 10px;
    flex: 1 1 0;
    height: 40px;
    width: 0;
    padding: 3px;

    &:last-child {
        margin-right: 0;
    }
}
