.background__top-header {
    background: var(--top-header-background);
}

.top-header__top {
    display: flex;
    justify-content: center;
    padding: 4px 0;
    font-size: 14px;
    min-height: 25px;

    @include mq($from: desktop) {
        justify-content: space-between;
    }

    img {
        min-height: 18px;
        aspect-ratio: auto 1/1;
    }
}

.top-header__top-left {
    display: block;
    width: 100%;

    @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
        width: auto;
    }
}

.top-header__wrapper {
    color: var(--top-header-text-color);
    display: flex;
    align-items: center;

    .top-header__icon {
        display: inline-block;
        margin-bottom: -3px;
        color: var(--top-header-icon-color);
        min-height: 18px;
    }
}

.top-header__phone-link {
    color: var(--top-header-phone-number-color);
    font-weight: var(--fw-bold);
    text-decoration: none;
}

.top-header__action-link {
    color: var(--top-header-cta-color);
}

.top-header__html-action-links {
    display: none;

    @include mq($from: desktop) {
        display: block;
    }

    p {
        margin: 0;
        font-size: 14px;
    }

    a {
        color: var(--top-header-cta-color);
    }
}

.top-header__phone-text,
.top-header__usps-text {
    @include mq($from: desktop) {
        overflow-wrap: break-word;
    }

    p {
        margin: 0;
    }
}

.top-header__phone-icon,
.top-header__usps-icon {
    display: inline-block;
    margin-right: 8px;
    width: 18px;
    padding: 2px;

    @include mq($until: tablet) {
        display: none;
    }

    @include mq($from: desktop) {
        padding: 0;
    }
}

.top-header__phone-wrapper {
    display: block;
    text-align: center;

    @include mq($from: desktop) {
        margin-right: 40px;
    }
}

.top-header__usps-wrapper {
    display: none;

    a {
        color: currentcolor;
        display: flex;
    }

    @include mq($from: tablet) {
        padding-right: 25px;
    }

    @include mq($from: desktop) {
        display: flex;
    }
}

.top-header__action-wrapper {
    display: none;

    @include mq($from: desktop) {
        display: flex;
    }
}
