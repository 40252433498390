.product-safety-pictograms {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin-top: 15px;
    gap: 16px;
    padding: 8px;

    @include mq($from: tablet) {
        margin-top: 30px;
        grid-template-columns: max-content 1fr max-content 1fr;
    }
}

.product-safety-pictograms__icon {
    height: 30px;
}
