.payment-method-item {
    margin-bottom: 16px;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-80);
    }
}

.payment-method-item__inner-wrapper {
    padding: 0 8px;
    margin-bottom: 12px;
}

.payment-method-item__payment-info {
    display: flex;
    justify-content: space-between;

    &:not(.payment-method-item__payment-description) {
        margin-bottom: 4px;
    }
}

.payment-method-item__radio {
    .radio-button__label {
        margin-right: 20px;
    }
}

.payment-method-item__radio-button-text {
    margin: 0 0 8px;
}

.payment-method-item__radio-label {
    font-weight: var(--fw-semibold);
    font-size: 17px;

    @include mq($from: tablet) {
        font-size: 18px;
    }
}

.payment-method-item__radio-fees {
    font-size: 15px;
    font-weight: 300;
}

.payment-method-item__payment-description {
    margin-left: 48px;
    color: var(--color-gray-500);
    font-size: var(--fs-s);
    margin-bottom: 12px;
}

.payment-method-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    max-height: 40px;
    color: var(--theme-primary);
}
