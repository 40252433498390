.mpc-summary-payment {
    padding: 10px;

    @include mq($from: tablet) {
        display: flex;
        column-gap: 10px;
    }
}

.mpc-summary-payment__header {
    flex-basis: 250px;

    @include mq($until: tablet) {
        display: flex;
        padding-bottom: 10px;
        justify-content: space-between;
    }
}

.mpc-summary-payment__title {
    font-weight: var(--fw-bold);
}

.mpc-summary-payment__image {
    display: block;
    max-width: 50px;
    padding-bottom: 2px;
    font-size: 52px;
    max-height: 40px;
    color: var(--theme-primary);
}

.mpc-summary-payment__change {
    cursor: pointer;
    text-decoration: underline;
}
