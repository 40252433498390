.product-stage {
    position: relative;

    @include mq($from: tablet) {
        display: flex;
    }
}

.product-stage__badge {
    position: absolute;
    left: 0;
    top: 25px;

    @include mq($from: tablet) {
        top: 70px;
    }
}

.product-stage__product-logo {
    max-width: 150px;
    margin-bottom: 6px;
}

.product-stage__media {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}

.product-stage__image {
    max-height: 700px;
}

.product-stage__detail {
    padding: 25px 15px;

    @include mq($from: tablet) {
        flex: 0 1 500px;
        padding: 50px 15px;
    }
}

/* Display a slant in the background of the image */
.product-stage__container::before {
    @include mq($from: tablet) {
        content: '';
        display: block;
        background: var(--color-white-true);
    }
}

.product-stage__title {
    font-size: 30px;
    font-weight: var(--fw-semibold);
    line-height: 1.2;
    margin-bottom: 6px;
}

.product-stage__rating {
    display: flex;
    font-size: 12px;
    margin-bottom: 7px;
}

.product-stage__claim {
    font-family: var(--ff-headline);
    font-weight: var(--fw-medium);
    font-size: 20px;
    margin-bottom: 11px;
}

.product-stage__description {
    margin-bottom: 10px;
    font-size: 14px;
}

.product-stage__variant-switcher {
    margin-bottom: 10px;
}

.product-stage__infos {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.product-stage__loading-indicator {
    margin: 0 auto;
}

.product-stage__saved-money {
    font-size: 16px;
    font-weight: var(--fw-semibold);
    color: var(--theme-accent);
}

.product-stage__price-container {
    flex: 1 0 auto;
    text-align: right;
    line-height: 1.3;
}

.product-stage__old-price {
    font-size: 16px;
    display: block;
    margin-bottom: -0.7em;
}

.product-stage__price {
    font-family: var(--ff-headline);
    font-weight: var(--fw-semibold);
    font-size: 45px;
}

.product-stage__unit-price {
    font-size: 12px;
}

.product-stage__price-hint {
    margin: 5px 0 10px;
    font-size: 12px;
    text-align: center;
}

.product-stage__price-hint-link {
    cursor: pointer;
    text-decoration: underline;
}

.product-stage__delivery-hint {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: var(--fw-semibold);
}

.product-stage__delivery-icon {
    font-size: 28px;
    margin-right: 10px;
}
