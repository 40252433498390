.club-price {
    font-family: var(--font-base);
    display: flex;
    gap: 12px;
    margin: 4px 0;
}

.club-price__left {
    justify-content: left;
}

.club-price__right {
    justify-content: right;
}

.club-price__icon-container {
    display: flex;
}

.club-price__help-icon {
    cursor: pointer;
}

.club-price__content-container {
    display: flex;
    gap: 5px;
}

.club-price__card-icon-container {
    color: var(--theme-primary);
}

.club-price__icon--small {
    font-size: var(--fs-club-price-icon-small);
}

.club-price__icon--medium {
    font-size: var(--fs-club-price-icon-medium);
}

.club-price__text-container--small {
    font-size: var(--fs-club-price-text-small);
    hyphens: auto;
    word-break: break-word;
}

.club-price__text-container--medium {
    font-size: var(--fs-club-price-text-medium);
}

.club-price__price-container {
    display: inline-block;
}
