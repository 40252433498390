h1,
.h1 {
    font-size: var(--fs-h1-mobile);
    font-weight: var(--fw-title);
    font-family: var(--ff-title);
    color: currentcolor;
    padding: 0;
    margin: 0;
    line-height: var(--lh-small);

    @include mq($from: tablet) {
        font-size: var(--fs-h1);
    }
}

.h1-sub {
    font-size: var(--fs-h1-sub-mobile);
    font-weight: var(--fw-headline);
    font-family: var(--ff-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h1-sub);
    }
}

h2,
.title-h1 {
    font-family: var(--ff-headline);
    font-size: var(--fs-h1-mobile);
    font-weight: var(--fw-headline);
    margin: 0;
    color: currentcolor;

    @include mq($from: tablet) {
        font-size: var(--fs-h1);
    }
}

.title-h1-sub {
    font-size: var(--fs-h1-sub-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h1-sub);
    }
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: var(--ff-headline);
    margin: 0;
}

h3,
.title-h2 {
    font-family: var(--ff-headline);
    font-size: var(--fs-h2-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h2);
    }
}

.title-h2-sub {
    font-size: var(--fs-h2-sub-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h2-sub);
    }
}

h4,
.title-h3 {
    font-family: var(--ff-headline);
    font-size: var(--fs-h3-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h3);
    }
}

.title-h3-sub {
    font-size: var(--fs-h3-sub-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h3-sub);
    }
}

h5,
.title-h4 {
    font-family: var(--ff-headline);
    font-size: var(--fs-h4-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h4);
    }
}

.title-h4-sub {
    font-size: var(--fs-h4-sub-mobile);
    font-weight: var(--fw-headline);

    @include mq($from: tablet) {
        font-size: var(--fs-h4-sub);
    }
}
