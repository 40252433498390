.radio-button-group__title {
    @extend .form__title;
}

.radio-button-group__error {
    color: var(--input-error);
}

.radio-button-group__radios {
    display: flex;
}
