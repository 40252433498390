.product-listing {
    @include mq($from: tablet) {
        padding: 0;
    }

    &:has(> .headlines) {
        padding-top: 10px;
    }

    .headlines--padding {
        padding: 20px 0;
    }

    .product-slider {
        padding: 0 0 30px;

        @include mq($from: tablet) {
            margin: 0 -4px;
        }
    }

    .content-width-wrapper--mobile,
    .content-width-wrapper--tablet,
    .content-width-wrapper--desktop,
    .content-width-wrapper--wide {
        width: inherit;
    }
}

.product-listing__teaser-wrapper {
    padding: 0 0 30px;

    @include mq($from: desktop) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 'product hero hero hero';
        grid-column-gap: 24px;
    }

    // needs to be set because of different media queries between css and js
    &--tablet {
        grid-template-areas: 'hero hero hero hero';
    }
}

.product-listing__teaser-wrapper--no-headline {
    padding: 30px 0;
}

.product-listing__teaser-product {
    grid-area: product;

    @include mq($until: desktop) {
        // stylelint-disable declaration-no-important
        display: none !important;
    }
}

.product-listing__teaser-hero {
    grid-area: hero;

    @include mq($from: tablet) {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        border-radius: var(--border-radius-default);
    }

    .hero-teaser__button {
        border-radius: var(--border-radius-default);
        color: var(--color-white-true);
        padding: 6px;
        line-height: 24px;
        font-weight: var(--fw-normal);
        letter-spacing: normal;

        @include mq($until: tablet) {
            padding: 6px 12px;
            font-size: var(--fs-s);
        }
    }

    .hero-teaser__box {
        padding: 30px;
    }
}

.product-listing__teaser-wrapper--no-bottom-padding {
    padding-bottom: 0;
}
