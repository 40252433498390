.flexbox {
    padding-bottom: 20px;

    @include mq($from: tablet) {
        padding-bottom: 40px;
    }
}

.flexbox-container {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: dense;
    margin: 20px;

    @include mq($from: tablet) {
        margin: 0;
    }
}

.flexbox-box {
    aspect-ratio: 1/1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

.flexbox-box__image {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-default);
}

.flexbox-box__video {
    position: absolute;
    inset: 0;

    video {
        border-radius: var(--border-radius-default);
    }
}

.flexbox-box__btn {
    background-color: var(--color-teaser-cta-box);
    color: var(--color-black);
    border-radius: var(--border-radius-default);
    margin-bottom: 12px;
    max-width: 90%;
    min-width: unset;
    padding: 10px 40px;
    font-size: 0.8em;
    font-weight: var(--fw-semibold);
    z-index: var(--zi-btn);

    @include mq($from: desktop) {
        font-size: 1em;
    }
}

.flexbox-container--grid-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

.flexbox-container--grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

.flexbox-container--grid-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }
    }
}

.flexbox-container--grid-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 4 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 1 / span 2;
            grid-column: 4 / span 2;
        }
    }
}

.flexbox-container--grid-5 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }
    }
}

.flexbox-container--grid-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 5 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 3 / span 2;
            grid-column: 1 / span 2;
        }
    }
}

.flexbox-container--grid-7 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 4 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(3) {
        grid-row: 7 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 3 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(3) {
            grid-row: 3 / span 2;
            grid-column: 3 / span 2;
        }
    }
}

.flexbox-container--grid-8 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 5 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 1 / span 2;
            grid-column: 4 / span 2;
        }

        .flexbox-box--big:nth-child(3) {
            grid-row: 3 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(4) {
            grid-row: 3 / span 2;
            grid-column: 4 / span 2;
        }
    }
}

.flexbox-container--grid-9 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 5 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(3) {
        grid-row: 8 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(3, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 2 / span 2;
            grid-column: 4 / span 2;
        }

        .flexbox-box--big:nth-child(3) {
            grid-row: auto;
            grid-column: auto;
        }
    }
}

.flexbox-container--grid-10 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(11, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 4 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(3) {
        grid-row: 7 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(4) {
        grid-row: 10 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 3 / span 2;
            grid-column: 2 / span 2;
        }

        .flexbox-box--big:nth-child(3) {
            grid-row: auto;
            grid-column: auto;
        }

        .flexbox-box--big:nth-child(4) {
            grid-row: auto;
            grid-column: auto;
        }
    }
}

.flexbox-container--grid-11 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);

    .flexbox-box--big:nth-child(1) {
        grid-row: 2 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(2) {
        grid-row: 5 / span 2;
        grid-column: 1 / span 2;
    }

    .flexbox-box--big:nth-child(3) {
        grid-row: 8 / span 2;
        grid-column: 1 / span 2;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .flexbox-box--big:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 2 / span 2;
        }

        .flexbox-box--big:nth-child(2) {
            grid-row: 3 / span 2;
            grid-column: 1 / span 2;
        }

        .flexbox-box--big:nth-child(3) {
            grid-row: 3 / span 2;
            grid-column: 3 / span 2;
        }
    }
}
