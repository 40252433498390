.checkout-summary-usp {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: var(--fs-base);
}

.checkout-summary-usp__usp {
    display: flex;
    align-items: center;
    padding: 0;
}
