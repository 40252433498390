.product-extra-info {
    padding: 40px 0;
}

.product-extra-info .headlines__headline {
    color: var(--theme-attention);
}

.product-extra-info__technical-data-wrapper {
    display: grid;
}

.product-extra-info__technical-data-wrapper--two-col {
    @include mq($from: tablet) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}
