.option-item {
    border: 1px solid var(--color-gray-400);
    border-radius: var(--border-radius-default);
    cursor: pointer;
    background: var(--color-gray-250);
    user-select: none;
    padding: 0;
    align-self: baseline;
}

.option-item__title {
    min-width: 95px;
    max-width: 190px;
    font-size: 16px;
    color: var(--color-gray-550);
    padding: 6px 12px;
    background: var(--color-white-true);
    border-radius: var(--border-radius-default);
    border: 1px solid var(--color-gray-500);
    margin: -1px;

    @include mq($from: tablet) {
        word-break: break-word;
    }
}

.option-item__description {
    font-size: 16px;
    padding: 6px 12px;
    display: flex;
}

.option-item__price,
.option-item__subtext {
    font-weight: 300;
}

.option-item--disabled {
    border-style: dashed;
    border-color: var(--color-gray-500);

    .option-item__title {
        border: none;
        background: transparent;
    }
}

.option-item:not(.option-item--disabled):hover,
.option-item--active {
    .option-item__title {
        color: var(--theme-primary);
        border-color: var(--theme-primary);
    }
}

.option-item--active {
    .option-item__title {
        font-weight: var(--fw-bold);
    }
}
