.search-suggestion__autosuggest {
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zi-search-bar-autosuggest);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    max-height: calc(100% - 64px);

    @include mq($from: tablet) {
        display: grid;
        position: absolute;
        top: calc(100% + 12px);
        grid-template-areas:
            'productSuggestion'
            'productSuggestionButton';
        grid-template-columns: 1fr;
        column-gap: 16px;
        max-height: none;

        &.search-suggestion__autosuggest--has-search-suggestion {
            grid-template-areas:
                'searchSuggestion productSuggestion'
                'searchSuggestion productSuggestionButton';
            grid-template-columns: 1fr 2fr;
            overflow: auto;
            @include mq($until: desktop) {
                max-height: calc(100% - 63px);
            }
        }
    }

    @include mq($from: desktop) {
        width: 100vw;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
        top: calc(100% - 14px);
        height: auto;
        min-height: auto;
    }
}

.search-suggestion__autosuggest-keywords-wrapper {
    grid-area: searchSuggestion;
}

.search-suggestion__autosuggest-keywords {
    overflow-y: auto;
    margin: 0;
    list-style-type: none;

    @media only screen and (max-device-width: 768px) and (orientation: landscape) {
        max-height: 80px;
    }
}

.search-suggestion__autosuggest-keyword {
    margin: 0 0 10px;
    padding: 0;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-device-width: 768px) and (orientation: landscape) {
        margin-bottom: 4px;
        max-height: 80px;
    }
}

.search-suggestion__autosuggest-results-wrapper {
    grid-area: productSuggestion;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
        height: auto;
    }
}

.search-suggestion__autosuggest-keyword-bold {
    font-weight: var(--fw-semibold);
}

.search-suggestion__autosuggest-results {
    overflow-y: auto;
}

.search-suggestion__search-page-button {
    grid-area: productSuggestionButton;
    border-radius: 50px;
    border: 1px solid var(--theme-primary);
    color: var(--theme-primary);
    font-size: 1.2rem;
    text-align: center;
    padding: 10px;
    font-weight: var(--fw-bold);
    margin: 15px;
    cursor: pointer;
}

.search-suggestion__autosuggest-headline {
    font-size: var(--fs-base);
    font-weight: var(--fw-medium);
    margin: 16px 0 10px;
    color: var(--color-gray-500);

    @media only screen and (max-device-width: 768px) and (orientation: landscape) {
        margin-bottom: 4px;
        margin-top: 6px;
    }
}

.search-suggestion__result-mobile-wrapper {
    overflow: auto;
}
