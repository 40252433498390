.info-banner__headlines {
    padding: 0;
    text-align: left;
    margin: auto 0;
}

.info-banner__centered-headlines {
    padding: 0;
    text-align: center;
    margin: auto 0;
}
