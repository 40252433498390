.pre-checkout-product__product-details__wrapper {
    display: flex;
    flex-direction: column;
}

.pre-checkout-product__product-details__claim {
    margin-bottom: 8px;
}

.pre-checkout-product__product-details__usps {
    margin-bottom: 8px;

    ::before {
        border-radius: 50%;
        height: 6px;
        width: 6px;
        margin-top: 2px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.pre-checkout-product__product-details__deliverables {
    margin-bottom: 8px;
}

.pre-checkout-product__product-details__deliverables-text {
    font-weight: var(--fw-semibold);
}

.pre-checkout-product__product-details__cta-btn {
    border-radius: var(--border-radius-default);
    margin: 8px 0 12px;
    padding: 11px;
    width: 100%;
    font-size: 18px;
    align-self: center;

    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);

    @include mq($until: desktop) {
        width: 50%;
        align-self: baseline;
    }
}

.pre-checkout-product__product-details__rating {
    align-self: baseline;
    margin-bottom: 8px;
    font-size: 16px;

    .pre-checkout-product__product-details__review-count {
        font-size: 12px;
    }
}
