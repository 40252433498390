.account-verification-modal__wrapper {
    text-align: center;
}

.account-verification-modal__attention-icon {
    font-size: 40px;
    color: var(--theme-tertiary);
}

.account-verification-modal__attention-wrapper {
    padding: 20px 25px;
    border-radius: var(--border-radius-default);
    display: inline-block;
    background: var(--theme-primary);
}

.account-verification-modal__header {
    margin-top: 15px;
}
