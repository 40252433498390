.personal-info-phone-number__header {
    display: flex;
    align-items: unset;
    justify-content: space-between;
}

.personal-info-phone-number__title {
    margin-bottom: 15px;
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.personal-info-phone-number__edit-icon {
    cursor: pointer;
}

.personal-info-phone-number__input {
    margin-bottom: 15px;
}
