.background--primary {
    --bg: var(--theme-primary);
    --color-text: var(--theme-on-primary);
    --color-highlight: var(--theme-on-primary-highlight);
    --color-headline: var(--theme-on-primary-headline);
    --color-subheadline: var(--theme-on-primary-headline);
    --color-border: var(--theme-on-primary-border);
}

.background--secondary {
    --bg: var(--theme-secondary);
    --color-text: var(--theme-on-secondary);
    --color-highlight: var(--theme-on-secondary-highlight);
    --color-headline: var(--theme-on-secondary-headline);
    --color-subheadline: var(--theme-on-secondary-headline);
    --color-banner: var(--bg-info-banner-secondary);
    --color-border: var(--theme-on-secondary-border);
}

.background--tertiary {
    --bg: var(--theme-tertiary);
    --color-text: var(--theme-on-tertiary);
    --color-highlight: var(--theme-on-tertiary-highlight);
    --color-headline: var(--theme-on-tertiary-headline);
    --color-subheadline: var(--theme-on-tertiary-headline);
    --color-banner: var(--bg-info-banner-tertiary);
    --color-banner-close: var(--color-white-true);
    --color-border: var(--theme-on-tertiary-border);
}

.background--default {
    --bg: var(--theme-default);
    --color-text: var(--theme-on-default);
    --color-highlight: var(--theme-on-default-highlight);
    --color-headline: var(--theme-on-default-headline);
    --color-subheadline: var(--theme-on-default-subheadline);
    --color-banner: var(--bg-info-banner);
    --color-border: var(--theme-on-default-border);
}

.background--white-true {
    --bg: var(--color-white-true);
    --color-text: var(--theme-on-default);
    --color-highlight: var(--theme-on-default-highlight);
    --color-headline: var(--theme-on-default-headline);
    --color-subheadline: var(--theme-on-default-headline);
    --color-banner: var(--bg-info-banner);
}

.background--black-true {
    --bg: var(--color-black-true);
    --color-text: var(--theme-on-default);
    --color-highlight: var(--theme-on-default-highlight);
    --color-headline: var(--theme-on-default-headline);
    --color-subheadline: var(--theme-on-default-headline);
    --color-banner: var(--bg-info-banner);
}

.background--primary,
.background--secondary,
.background--tertiary,
.background--default,
.background--white-true,
.background--black-true {
    background: var(--bg);
    color: var(--color-text);
    --bg-li-marker: var(--color-text);
}

.background--warning {
    --color-banner: var(--theme-warning);
    --color-banner-close: var(--color-white-true);
    --color-headline: var(---color-white-true);
    --color-subheadline: var(---color-white-true);
    --color-text: var(--color-white-true);
}

.background--error {
    --color-banner: var(--theme-error);
    --color-banner-close: var(--color-white-true);
    --color-headline: var(---color-white-true);
    --color-subheadline: var(---color-white-true);
    --color-text: var(--color-white-true);
}

.background--info {
    --color-banner: var(--theme-info);
}

.background--success {
    --color-banner: var(--theme-success);
    --color-banner-close: var(--color-white-true);
    --color-headline: var(---color-white-true);
    --color-subheadline: var(---color-white-true);
    --color-text: var(--color-white-true);
}
