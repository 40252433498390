.search-input__form--open-portal {
    display: flex;
    padding-left: 12px;
    gap: 12px;
    align-items: center;
}

.search-input__input {
    position: relative;
    padding-right: 58px;
    width: 100%;
    line-height: 44px;
    border-radius: var(--border-radius-default);
    border: 1px solid var(--theme-primary);
    color: var(--color-search-bar-input);
    font-size: var(--fs-search-bar-input);
    padding-left: 24px;
    z-index: 7;
    background-color: transparent;

    &:focus-visible,
    &:focus {
        outline: none;
    }
}

.search-input__input--open-portal {
    border: none;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
}

.search-input__icon-button {
    position: absolute;
    right: 10px;
    color: var(--theme-primary);
    font-size: 44px;
    cursor: pointer;
    height: 44px;
    top: calc(50% - 22px);
    z-index: 7;
}

.search-input__icon-button--open-portal {
    position: relative;
    background: none;
    border-radius: 0;
    display: flex;
    align-items: center;

    &.search-input__icon-button-clear {
        right: 0;
        width: 44px;
        height: 44px;
        transform: scale(1);
        font-size: unset;
    }
}
