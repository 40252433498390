.background-image-with-text {
    position: relative;
    --color-headline: var(--color-background-image-with-text-headline);
    --color-subheadline: var(--color-background-image-with-text-subheadline);

    &__container {
        display: grid;
    }
}

.background-image-with-text__text-container,
.background-image-with-text__text-container-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: var(--color-background-image-with-text);
}

.background-image-with-text__text-container-mobile {
    padding: 50px 0 20px;
}

.background-image-with-text__cta-button {
    padding: 12px;
}

.background-image-with-text__image {
    width: 100%;
    height: auto;
}
