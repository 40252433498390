.option-selector {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.option-selector__title {
    font-size: 16px;
    color: var(--color-gray-800);
    font-weight: var(--fw-semibold);
    padding-bottom: 13px;

    @include mq($from: tablet) {
        padding: 8px 0 13px;
    }
}

.option-selector__subline {
    font-weight: var(--fw-light);
}

.option-selector__options {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    margin: 0 -16px;
    padding: 0 16px;

    @include mq($from: tablet) {
        margin: 0;
        padding: 0;
    }
}

.option-selector__variant-error {
    color: var(--theme-error);
    padding-top: 5px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.option-selector__attention-icon {
    margin-right: 5px;
}

.option-selector__dimension {
    border-top: 1px solid var(--option-selector-border-color);
    padding: 23px 16px 0;
    margin: 0 -16px;

    &:last-child {
        border-bottom: 1px solid var(--option-selector-border-color);
    }

    @include mq($from: tablet) {
        border: none;
        margin: 0;
        padding: 0;

        &:last-child {
            border: none;
        }
    }
}
