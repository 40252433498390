.splp-navigation-item {
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    width: auto;

    &::before {
        display: none;
    }
}

.splp-navigation-item__link {
    display: block;
    margin: 10px 5px;
    padding: 6px 14px;
    white-space: nowrap;
    border: 1px solid var(--color-text);
    color: var(--color-text);
    background: var(--bg);
    font-weight: var(--fw-navigation-links);
    border-radius: var(--bd-radius-navigation-links);
}

.splp-navigation-item__anchor-link {
    cursor: pointer;
}
