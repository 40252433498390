.cart-summary__background-wrapper {
    background: var(--bg-cart-mobile);

    @include mq($from: tablet) {
        background: var(--bg-cart);
    }
}

.cart-summary {
    padding: 0;
    background: var(--bg-cart-summary);
}

.cart-summary__content {
    padding: 0;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.cart-summary__voucher-input {
    padding: 10px;
    background: var(--theme-beige);
    margin-bottom: 20px;
}

.cart-summary__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    min-width: 0;
}

.cart-summary__label {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    hyphens: auto;
}

.cart-summary__row-label {
    position: relative;
    display: flex;
}

.cart-summary__subtotal-label {
    font-size: var(--fs-l);
    margin-right: 1ch;
}

.cart-summary__subtotal-price {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
    color: var(--color-cart-summary-total);
}

.cart-summary__delivery-label {
    font-size: var(--fs-sm);
    font-weight: var(--fw-regular);
    color: var(--color-checkout-delivery-cost);
    margin-right: 0;
    display: flex;
    flex-direction: row;
    gap: 4px;

    span {
        cursor: pointer;
    }
}

.cart-summary__delivery-price {
    font-family: var(--font-product-price);
    font-size: var(--fs-sm);
    font-weight: var(--fw-regular);
    color: currentcolor;
}

.cart-summary__payment-fee-price {
    font-family: var(--font-product-price);
    font-size: 14px;
    font-weight: var(--fw-regular);
    color: currentcolor;
}

.cart-summary__savings,
.cart-summary__club-savings {
    display: flex;
    justify-content: center;
    text-align: right;
    color: var(--color-cart-summary-savings);
    font-size: var(--fs-sm);
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 6px;
    flex-direction: column;

    @include mq($from: tablet) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &--icon {
        position: relative;
        bottom: -2px;
        right: 4px;
        cursor: pointer;
    }

    .cart-summary__club-savings--link-underline {
        text-decoration: underline;
    }
}

.cart-summary__club-savings {
    cursor: pointer;
}

.cart-summary__installment {
    display: block;
    text-align: right;
    color: var(--color-cart-summary-installment);
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;

    @include mq($from: tablet) {
        margin-bottom: 10px;
    }
}

.cart-summary__total-label {
    margin-right: 0;
    font-size: var(--fs-l);
}

.cart-summary__tax-info {
    display: inline-block;
    font-size: var(--fs-xs);
    font-weight: var(--fw-regular);
    white-space: nowrap;
    hyphens: auto;
    color: var(--color-cart-summary-tax);
}

.cart-summary__price {
    white-space: nowrap;
    font-size: 22px;
    font-weight: var(--fw-semibold);
    color: var(--color-cart-summary-total);
}

.cart-summary__checkout-button {
    width: 100%;
    letter-spacing: 1.25px;
    font-weight: var(--fw-semibold);
    text-transform: uppercase;
    border-radius: var(--border-radius-default);
    display: flex;

    .cart-summary__checkout-button-text {
        display: flex;
        flex-wrap: wrap;
        overflow-y: hidden;
        max-height: 18px;
        gap: 5px;
        justify-content: center;

        .cart-summary__checkout-button-text--capitalize {
            text-transform: capitalize;
        }
    }
}

.cart-summary__payment-logos-item {
    padding: 0 10px;
    max-height: 80px;
    max-width: 80px;
}

.cart-summary__usps {
    background: var(--theme-beige);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.cart-summary__usp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    padding: 5px;
    color: var(--theme-primary);
    white-space: nowrap;
}

.cart-summary__usp-icon {
    margin-right: 5px;
}

.cart-summary--sticky {
    padding: 0 0 8px;

    .cart-summary__desktop-container {
        padding: 4px 15px;
        @include mq($from: tablet) {
            display: grid;
            grid-template-columns: auto 330px;
        }
    }

    .cart-summary__total {
        .cart-summary__price {
            font-size: var(--fs-l);
        }
    }

    .cart-summary-footer__usp {
        display: none;
    }

    .paypal-express {
        display: none;
    }

    @include mq($from: tablet) {
        .cart-summary__content {
            max-width: 1300px;
            padding: 4px 15px;
            margin: auto;
            align-items: center;
        }

        .cart-summary__total {
            margin-bottom: 0;
        }

        .cart-summary__installment,
        .cart-summary__club-savings {
            text-align: left;
        }

        .cart-summary__row {
            display: block;
        }

        .cart-summary__checkout-button {
            height: 46px;
            font-size: 20px;
        }
    }
}

.cart-summary__voucher-head {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    &:hover {
        cursor: pointer;
    }
}

.cart-summary__voucher-body {
    display: flex;
    flex-direction: column;
}

.cart-summary__voucher-body-code {
    padding: 15px 25px;
    margin: 10px 20px;
    border: 1px solid var(--color-gray-600);
    border-radius: 50px;
}

.cart-summary__voucher-body-btn {
    padding: 15px 25px;
    margin: 10px 20px;
    border: none;
    background: var(--bg-button-tertiary);
    border-radius: 50px;

    &:hover {
        cursor: pointer;
    }
}

.cart-summary__voucher-msg-success {
    color: var(--theme-success);
    padding: 0 20px;
    margin-top: 20px;
}

.cart-summary__voucher-msg-error {
    color: var(--theme-error);
    padding: 0 20px;
    margin-top: 20px;
}

.cart-summary__voucher-wrapper {
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 0 20px;
}

.cart-summary__remove-button {
    text-align: right;
    font-size: 20px;

    &:hover {
        cursor: pointer;
    }
}

.cart-summary__voucher {
    max-width: 250px;
}

.cart-summary__voucher-value {
    float: right;
    font-weight: 900;
}

.cart-summary__separator {
    border-top: 2px solid #eee;
    padding-top: 5px;

    @include mq($from: tablet) {
        padding-top: 10px;
    }

    @include mq($from: desktop) {
        padding-top: 8px;
    }
}

.cart-summary__border-top {
    border-radius: none;
    border-top: 1px solid var(--color-gray-80);
    border-left: 1px solid var(--color-gray-80);
    border-right: 1px solid var(--color-gray-80);
    padding: 14px 14px 0;
    border-top-right-radius: var(--border-radius-default);
    border-top-left-radius: var(--border-radius-default);
    margin: 0 15px;
    @include mq($from: tablet) {
        margin: 0;
    }
}

.cart-summary__border-side {
    border-radius: none;
    border-left: 1px solid var(--color-gray-80);
    border-right: 1px solid var(--color-gray-80);
    padding: 0 14px;
    margin: 0 15px;
    @include mq($from: tablet) {
        margin: 0;
    }
}

.cart-summary__border-bottom {
    border-radius: none;
    border-bottom: 1px solid var(--color-gray-80);
    border-left: 1px solid var(--color-gray-80);
    border-right: 1px solid var(--color-gray-80);
    padding: 0 14px 14px;
    border-bottom-right-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    margin: 0 15px;
    @include mq($from: tablet) {
        margin: 0;
    }
}

.cart-summary__box-shadow {
    box-shadow: 0 -2px 20px var(--color-gray-900-20);
    margin-left: 0;
    margin-right: 0;
    border: none;
    padding: 10px;
}

.cart-summary__payment-fee-label {
    font-size: var(--fs-sm);
}
