.info-banner-common {
    display: grid;
    grid-template-columns: [content] auto;
    padding: 10px 0;
    background-color: var(--color-banner);

    &.info-banner-common--no-close-button {
        justify-content: center;
    }
}

.info-banner-common__closeable {
    grid-template-columns: [spacer]50px [content] auto [close] 50px;
}

.info-banner-common__has-icon {
    grid-template-columns: [spacer]50px [content] auto [close] 50px;
}

.info-banner-common__icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin-right: 20px;
}

.info-banner-common__content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: content;
}

.info-banner-common__headlines {
    padding: 0;
    text-align: left;
    margin: auto 0;
}

.info-banner-common__centered-headlines {
    padding: 0;
    text-align: center;
    margin: auto 0;
}

.info-banner-common__close-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-banner-common__close-icon-container {
    outline: 0;
    padding: 0;
    min-width: 35px;
    height: 35px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.info-banner-common__close-icon {
    width: 19px;
    height: 19px;
    color: var(--color-banner-close);
}
