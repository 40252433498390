.voucher-tool-tip__voucher-overlay {
    position: absolute;
    display: flex;
    gap: 8px;
    background-color: var(--color-voucher-overlay-bg);
    left: -60px;
    bottom: 24px;
    width: 212px;
    min-height: 35px;
    box-shadow: 1px 1px 5px var(--color-gray-400);
    border-left: 3px solid var(--theme-error);

    @include mq($from: desktop) {
        left: 16px;
        bottom: 16px;
    }
}

.voucher-tool-tip__content-container {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 15px 5px;
    font-size: 14px;
    color: var(--color-text);

    svg {
        color: var(--theme-error);
    }
}

.voucher-tool-tip__icon {
    min-width: 15px;
}

.voucher-tool-tip__error-message {
    line-height: var(--lh-base);
}
