.teaser-grid-tile {
    background: var(--color-white-true);
    color: var(--color-black);
    border-radius: var(--border-radius-default);
    overflow: hidden;

    &--with-link {
        cursor: pointer;
    }

    &--with-box-shadow {
        box-shadow: var(--box-shadow-default);
    }

    &--with-square-corners {
        border-radius: 0;
    }
}

.teaser-grid-tile__content-wrapper {
    position: relative;
    height: 100%;
    display: grid;

    &--with-image,
    &--with-teaser {
        grid-template-rows: auto;
    }
}

.teaser-grid-tile__text-container {
    width: 100%;
    padding: 20px;
    line-height: var(--lh-base);
    word-break: break-word;
    overflow: hidden;

    p {
        margin: 0;
    }

    a {
        color: currentcolor;
        text-decoration: underline;
    }

    ul {
        margin: 0 0 0 10px;
    }

    h1 {
        line-height: 1.15;
    }

    h2 {
        line-height: 1.15;
    }

    h3 {
        line-height: 1.15;
    }

    h4 {
        line-height: 1.15;
    }

    sup {
        vertical-align: super;
        top: -0.25em;
    }

    sub {
        vertical-align: sub;
        bottom: -0.25em;
    }
}

.teaser-grid-tile__link {
    color: inherit;
}

.teaser-grid-tile__image {
    width: 100%;
    height: auto;
}

.teaser-grid-tile__text-video {
    width: 100%;
    border-radius: var(--border-radius-default);
}

.teaser-grid-tile__text-video-with-padding {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding-bottom: 52%;
    border-radius: 0;
}

.teaser-grid__grid--1-1-columns,
.teaser-grid__grid--1-2-columns {
    .teaser-grid-tile__text-video {
        border-radius: 0;
    }

    @include mq($from: tablet) {
        .teaser-grid-tile__content-wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include mq($from: tablet) {
    .teaser-grid-tile__text-container-wrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        &--start {
            align-items: flex-start;
        }

        &--center {
            align-items: center;
        }

        &--end {
            align-items: flex-end;
        }
    }
}

.teaser-grid-tile__content-wrapper--above {
    picture,
    .bloomreach-video,
    .before-after-image {
        order: 2;
    }

    .teaser-grid-tile__text-container-wrapper {
        order: 1;
    }

    &.teaser-grid-tile__content-wrapper--with-image.teaser-grid-tile__content-wrapper--with-teaser {
        grid-template-rows: 1fr auto;
    }
}

.teaser-grid-tile__content-wrapper--below {
    picture,
    .bloomreach-video,
    .before-after-image {
        order: 1;
    }

    .teaser-grid-tile__text-container-wrapper {
        order: 2;
    }

    &.teaser-grid-tile__content-wrapper--with-image.teaser-grid-tile__content-wrapper--with-teaser {
        grid-template-rows: auto 1fr;
    }
}

.teaser-grid-tile__content-wrapper--ignore-text-block-position {
    @include mq($until: tablet) {
        picture,
        .bloomreach-video,
        .before-after-image {
            order: 1;
        }

        .teaser-grid-tile__text-container-wrapper {
            order: 2;
        }
    }
}

@include mq($from: tablet) {
    .teaser-grid-tile__content-wrapper:has(.bloomreach-video) {
        aspect-ratio: 1/1;

        .teaser-grid-tile__video {
            position: absolute;
            inset: 0;
        }

        &.teaser-grid-tile__content-wrapper--with-teaser {
            aspect-ratio: auto;

            .teaser-grid-tile__video {
                position: relative;
                inset: auto;
            }
        }
    }
}
