.checkout-login-form__form {
    display: grid;
    gap: 15px;
}

.checkout-login-form__required-field-hint {
    margin: 0 0 20px 4px;
    font-size: var(--fs-s);
    color: var(--color-gray-500);
}

.checkout-login-form__full-name {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}

.checkout-login-form__forgot-password {
    margin-top: 20px;

    a,
    a:visited {
        text-decoration: underline;
        color: inherit;
    }
}
