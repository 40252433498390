.accordion-pdp-item__column {
    width: 100%;
    border-bottom: 1px solid var(--color-gray-80);
    padding: 16px;
}

.accordion-pdp-item__heading {
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--theme-attention);

    .accordion-pdp-item__chevron {
        float: right;
        margin-top: 5px;
        color: var(--theme-attention);
    }

    &--ratings {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.delivery-item {
    border-collapse: collapse;
}

.delivery-item__row:nth-child(odd) {
    background-color: var(--color-gray-300);
}

.delivery-item__data-label {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
}

.delivery-item__data-value {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
}

.accordion-pdp-item__item {
    @include mq($from: tablet) {
        padding: 14px 83px;
    }
}

.accordion-pdp-item__ratings-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.accordion-pdp-item__rating {
    scroll-padding-top: 20px;

    .star-rating__star {
        font-size: 1em;
    }
}
