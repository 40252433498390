.product-grid-search__wrapper {
    padding-bottom: 50px;
    height: 100%;
}

.product-grid-search__headlines {
    .headlines__headline {
        color: var(--plp-headline-color);
    }
}

.product-grid-search__pagination-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 0 24px;
}
