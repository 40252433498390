.payment-network-unavailable__container {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.payment-network-unavailable__attention-icon-container {
    background-color: var(--theme-primary);
    color: var(--theme-accent);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin-right: 16px;
}

.payment-network-unavailable__attention-icon {
    font-size: 4rem;
    padding: 10px 15px 15px;
}

.payment-network-unavailable__text {
    strong {
        font-size: var(--fs-l);
    }
}

.payment-network-unavailable__submit {
    margin-top: 24px;
}
