.product-button__cta-btn {
    margin-top: 8px;
}

.product-button__cta-btn-details {
    border-radius: var(--border-radius-default);
    background: var(--background-product-buy-button);
    color: var(--color-product-buy-button);
    text-transform: unset;
    font-size: var(--fs-base);
    font-weight: var(--fw-normal);
    letter-spacing: normal;
    padding: 10px 4px;
    min-width: unset;

    @include mq($until: tablet) {
        min-width: unset;
        font-size: var(--fs-s);
    }
}

.product-button__cta-btn-cart {
    background: var(--background-product-buy-button);
    border-radius: var(--border-radius-default);

    @include mq($from: tablet) {
        display: flex;
    }

    .select-button__button {
        color: var(--color-product-buy-button);
        text-transform: unset;
        font-size: var(--fs-s);

        @include mq($from: tablet) {
            font-size: var(--fs-base);
        }

        /* stylelint-disable-next-line selector-class-pattern */
        .icon--AddToCart {
            font-size: 24px;
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .icon--ArrowRightLight {
        display: none;
    }

    .select-button__select {
        display: none;
    }
}
