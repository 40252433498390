.star-rating-bar {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.star-rating-bar__star-rating {
    margin-right: 5px;
    font-size: 16px;
}
