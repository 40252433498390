.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100% - 26px);
    max-width: 700px;
    max-height: 80vh;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    z-index: var(--zi-modal);

    @include mq($from: tablet) {
        width: 80%;
    }

    &:focus {
        outline: none;
    }
}

.modal__inner {
    position: relative;
    padding: 0;
    border-radius: var(--border-radius-default);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.34);

    @include mq($from: desktop) {
        border-radius: 10px / 15px;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
    }
}

.modal__body {
    padding: 45px;
    max-height: 80vh;
    overflow: auto;
    overscroll-behavior: contain;

    h2 {
        margin-right: 40px;
    }
}

.modal__footer {
    position: sticky;
    padding: 15px 30px 30px;
    bottom: 0;
}

.modal__headline-icon {
    display: flex;
    align-items: center;
    margin-right: 1ch;
}

.modal__close {
    position: fixed;
    right: 24px;
    top: 12px;
    font-size: 22px;
    border: 0;
    color: var(--color-gray-900);
    background: transparent;
    padding: 0;
    cursor: pointer;
    z-index: 1;
}

.modal--bg-white {
    .modal__inner {
        background: var(--color-white);
    }
}

.modal--bg-white-true {
    .modal__inner {
        background: var(--color-white-true);
    }
}

.page--overlay-open {
    height: 100%;
    width: 100%;
}

.modal__overlay-bg {
    position: fixed;
    inset: 0;
    z-index: var(--zi-modal);
    background: var(--modal-overlay-bg-color);
}
