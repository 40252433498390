html {
    font-family: var(--font-base);
    font-weight: var(--fw-light);
    font-size: var(--fs-base);
    color: var(--color-black);
    line-height: var(--lh-base);

    @include mq($from: tablet) {
        font-size: var(--fs-base);
    }

    @include mq($from: desktop) {
        font-size: var(--fs-base);
    }
}

em {
    font-style: italic;
}

b,
strong {
    font-weight: var(--fw-bold);
}

p {
    margin-bottom: var(--sp-base);
}

a {
    text-decoration: var(--td-links);

    &:focus {
        outline: none;
    }
}
