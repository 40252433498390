.op-checkout-summary {
    padding: 25px 15px;

    @include mq($from: desktop) {
        overflow: auto;
        position: sticky;
        top: 20px;
        background: var(--bg-checkout-summary);
    }

    .voucher-redemption__head {
        padding: 10px;
        text-decoration: underline;
    }
}

.op-checkout-summary__header {
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    color: var(--color-headline);
}

.op-checkout-summary__usps {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: var(--fs-l);
}

.op-checkout-summary__usp {
    display: flex;
    align-items: center;
    padding: 0;
}

.op-checkout-summary__usp::before {
    content: none;
}

.op-checkout-summary__line-item {
    display: grid;
    grid-template-areas: 'quantity productName price';
    grid-template-columns: 30px auto 120px;
    align-items: center;
    margin-bottom: 15px;
}

.op-checkout-summary__quantity {
    grid-area: quantity;
    font-size: var(--fs-h4);
    font-weight: var(--fw-bold);
}

.op-checkout-summary__product-name {
    grid-area: productName;
    font-size: var(--fs-h4);
    font-weight: var(--fw-bold);
    line-height: 1;
}

.op-checkout-summary__price {
    grid-area: price;
}

.op-checkout-summary__mini-cart {
    .price-box__unit-price {
        font-size: var(--fs-xs);
    }

    @include mq($from: desktop) {
        margin-top: 15px;
    }
}

.op-checkout-summary__mini-cart-summary {
    border-top: 2px solid var(--color-borders-checkout);
    padding-top: 20px;
}

.op-checkout-summary__mini-cart-summary-line {
    display: grid;
    grid-template-columns: auto 140px;
    margin: 5px 0;
}

.op-checkout-summary__label {
    text-align: left;
    hyphens: auto;
}

.op-checkout-summary__voucher-label {
    display: flex;
}

.op-checkout-summary__value {
    font-family: var(--font-product-price);
    text-align: right;
}

.op-checkout-summary__subtotal {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
}

.op-checkout-summary__delivery-cost {
    font-size: var(--fs-l);
    color: var(--color-checkout-delivery-cost);
}

.op-checkout-summary__total {
    font-size: var(--fs-xl);
    font-weight: var(--fw-bold);
    margin-top: 10px;
    word-break: break-word;
    line-height: 1;

    .op-checkout-summary__total-label {
        display: inline-block;
        margin-right: 1ch;
    }

    .op-checkout-summary__tax-label {
        font-size: var(--fs-s);
        font-weight: var(--fw-regular);
        color: var(--color-checkout-cart-tax);
        white-space: nowrap;
    }
}

.op-checkout-summary__total-price {
    color: var(--color-checkout-total-value);
    font-family: var(--font-product-price);
}

.op-checkout-summary__total-savings {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
    color: var(--color-checkout-total-savings);
}

.op-checkout-summary__footer {
    margin-top: 15px;
}

.op-checkout-summary__footer-legal-text {
    padding: 15px 0 30px;
}

.op-checkout-summary__footer-legal-text a {
    text-decoration: underline;
    color: var(--color-checkbox-label);
    font-weight: 600;
}

.op-checkout-summary__buynow-button {
    padding: 20px;
    margin-top: 15px;
    font-size: var(--fs-xl);
    height: auto;
}

.op-checkout-summary .delivery-info {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
    margin-top: 10px;
}

.op-checkout-summary__voucher-overlay {
    @include mq($from: tablet) {
        left: 25px;
        bottom: 15px;
    }
}
