.testimonial-slider {
    padding: 20px 0 40px;

    .swiper-slide-next,
    .swiper-slide-prev {
        @include mq($until: tablet) {
            opacity: 0.5;
        }
    }
}

.testimonial-slider__slider-wrapper {
    display: flex;
    --swiper-theme-color: var(--theme-primary);
    position: relative;
    align-items: center;

    .swiper-container {
        padding-left: 20px;
        width: 100%;
    }
}

.testimonial-slider__slider-wrapper .slider__inner-wrapper--with-arrows {
    @include mq($from: desktop) {
        margin-left: 60px;
        margin-right: 60px;
    }
}

.background--primary,
.background--tertiary {
    .testimonial-slider__pagination-bullet-active {
        background: var(--color-white-true);
    }
}
