.pre-checkout-product {
    position: relative;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 10px;
    margin: 0 15px 26px;
    border-radius: var(--border-radius-default);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    background: var(--color-white-true);
    gap: 5px 15px;
    grid-template-areas:
        'productImage claim'
        'productDetailMobile productDetailMobile'
        'orderInfo orderInfo';

    @include mq($from: tablet) {
        background: var(--color-white-true);
        padding: 16px;
        margin: 0 0 26px;
        grid-gap: 15px;
        grid-template-areas:
            'productImage productDetail'
            'orderInfo orderInfo';
    }

    @include mq($from: desktop) {
        grid-template-areas:
            'productImage productDetail'
            'productImage orderInfo';
    }
}

.pre-checkout-product__image-container {
    position: relative;
    grid-area: productImage;
    border-radius: var(--border-radius-default);
    overflow: hidden;
    aspect-ratio: 1/1;
    display: flex;
    align-items: stretch;
}

.pre-checkout-product__image {
    width: 100%;
    height: auto;
}

.pre-checkout-product__detail {
    @include mq($from: tablet) {
        grid-area: productDetail;
    }

    padding-right: 10px;
}

.pre-checkout-product__order-info {
    grid-area: orderInfo;
    align-self: end;
    margin-right: 20px;
}

.pre-checkout-product__detail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 10px 5px 0;
}

.pre-checkout-product__title {
    font-size: 16px;
    color: var(--theme-primary);
    font-weight: 700;
}

.pre-checkout-product__remove-button {
    grid-area: removeButton;
    cursor: pointer;
    font-size: var(--fs-h2);
    padding-left: 20px;
    line-height: 1;

    &:hover {
        color: var(--theme-primary);
    }
}

.pre-checkout-product__description {
    grid-area: productDetailMobile;
    padding-right: 10px;

    @include mq($from: tablet) {
        grid-area: description;
    }

    font-size: 14px;
    text-align: start;

    li {
        margin-bottom: 0;
    }
}

.pre-checkout-product-description__preview--oversized {
    overflow-y: hidden;
}

.pre-checkout-product__price-info-container {
    display: flex;
    justify-content: space-between;

    @include mq($until: tablet) {
        display: block;
    }

    .price-colored-sparset,
    .price-colored-sale {
        color: var(--color-red);
    }
}

.pre-checkout-product__quantity-info {
    grid-area: quantityInfo;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pre-checkout-product__price {
    align-self: flex-end;

    .price-box__old-price {
        color: var(--pre-checkout-product-old-price);
        margin-bottom: 10px;
    }

    .price-box__price {
        color: var(--pre-checkout-product-price);
        font-size: 25px;
    }
}

.pre-checkout-product__promotion {
    margin-bottom: 12px;
    padding: 3px 16px;
    border-radius: var(--border-radius-precheckout);
    font-weight: 600;
    font-size: 13px;
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}

.pre-checkout-product__quantity {
    border: 1px solid var(--border-pre-checkout-product-select);
    margin-right: 0;
    font-weight: 400;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius-default);

    select {
        font-size: 16px;
        padding: 12px 50px 12px 24px;

        &:focus-visible {
            outline: 0;
        }
    }

    .select__arrow {
        font-size: 16px;
    }
}

.pre-checkout-product__shipping-time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--fs-h5);
    font-weight: var(--fw-bold);
    color: var(--color-pre-checkout-product-shipping-time);
    margin: 10px 0 5px;
}

.pre-checkout-product__shipping-icon {
    display: inline-flex;
    margin-right: 12px;
    font-size: 32px;
    color: var(--color-pre-checkout-product-shipping-time);
}

.pre-checkout-product__badge-wrapper {
    display: none;
    width: 100%;
    position: absolute;

    @include mq($from: desktop) {
        display: flex;
    }
}

.pre-checkout-product__badge-container--top {
    display: flex;
    flex-direction: row;
    width: inherit;
    position: absolute;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;

    :first-child {
        border-radius: var(--border-radius-default) 0 0 0;
    }

    :nth-child(2) {
        border-radius: 0 var(--border-radius-default) 0 0;
    }
}

.pre-checkout-product__badge-container--bottom {
    .catalog-badge {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0;
    }
}

.pre-checkout-product__cta-btn-cart {
    border-radius: var(--border-radius-default);
    padding: 11px;
    max-width: 140px;
    font-size: 18px;

    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);

    @include mq($until: desktop) {
        min-width: 0;
    }
}
