.badge {
    &::after {
        content: '';
        width: 20px;
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
        background: var(--color-bg);
        position: absolute;
        right: -19px;
        top: 0;
        bottom: 0;
    }

    display: inline-block;
    position: relative;
    font-weight: var(--fw-text-badge);
    padding: 0 18px;
    letter-spacing: 0.25px;
    font-size: 18px;
    z-index: var(--zi-badge);

    @include mq($from: tablet) {
        font-size: 20px;
    }
}

.badge--primary {
    background: var(--bg-badge-primary);
    color: var(--color-badge-primary);
}

.badge--secondary {
    background: var(--bg-badge-primary);
    color: var(--color-badge-primary);
}

.badge--tertiary {
    background: var(--bg-badge-primary);
    color: var(--color-badge-primary);
}

.badge--info {
    --color-bg: var(--bg-badge-info);
    background-color: var(--bg-badge-info);
    color: var(--color-badge-info);
}

.product__marketing {
    .badge--type-gratis {
        background: var(--bg-badge-highlight);
        color: var(--color-badge-highlight);
    }

    .badge--type-gespart {
        background: var(--color-badge-highlight);
        color: var(--bg-badge-highlight);
        border: 1px solid var(--bg-badge-highlight);
    }

    .badge--type-raten {
        background: var(--color-badge-raten);
        color: var(--color-badge-highlight);
    }
}

.badge--highlight {
    --color-bg: var(--bg-badge-highlight);
    background-color: var(--bg-badge-highlight);
    color: var(--color-badge-highlight);
    border: 1px solid transparent;
}

.badge--promotion {
    --color-bg: var(--bg-badge-promotion);
    background-color: var(--bg-badge-promotion);
    color: var(--color-badge-promotion);
}

.badge--warning {
    --color-bg: var(--bg-badge-warning);
    background-color: var(--bg-badge-warning);
    color: var(--color-badge-warning);
}

.badge--success {
    --color-bg: var(--bg-badge-success);
    background-color: var(--bg-badge-success);
    color: var(--color-badge-success);
}

.badge--error {
    --color-bg: var(--bg-badge-error);
    background-color: var(--bg-badge-error);
    color: var(--color-badge-error);
}

.badge--transform-uppercase {
    text-transform: uppercase;
}

.badge--large {
    padding: 0 21px;
    letter-spacing: 0.25px;
    font-size: var(--fs-h1-mobile);
    font-weight: var(--fw-bold);

    @include mq($from: tablet) {
        letter-spacing: 0.28px;
        padding: 0 34px;
        font-size: var(--fs-h1);
    }
}

.badge--medium {
    font-size: 16px;

    @include mq($from: tablet) {
        padding: 0 var(--fs-xs);
        font-size: 18px;
    }
}

.badge--small {
    padding: 0 21px;
    font-size: var(--fs-base);
}

.badge--spacing-small {
    padding: 4px 6px;
}

.badge--spacing-medium {
    padding: 8px 21px;
}

.badge--spacing-large {
    padding: 12px 15px;
}

.badge--align-center {
    text-align: center;
}

.badge--align-left {
    text-align: left;
}

.badge--align-right {
    text-align: right;
}
