.arrow-button {
    justify-content: center;
    align-items: center;
    color: var(--theme-primary);
    background: var(--color-white-true);
    width: 45px;
    clip-path: unset;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 1px 3px -1px var(--color-black-true);
    user-select: none;
}
