.main-navigation {
    position: relative;
    z-index: var(--zi-main-navigation);
    border-bottom: 1px solid var(--border-color-main-navigation);

    .header-logo {
        grid-area: logo;
    }

    .user-menu {
        grid-area: userMenu;
    }

    .desktop-menu {
        grid-area: desktopMenu;
    }

    .search-bar__wrapper {
        padding: 0;
    }
}

.main-navigation__wrapper,
.mobile-menu__header {
    position: relative;
    display: grid;
    grid-template-areas:
        'mobileMenuButton logo logo userMenu'
        'searchBar searchBar searchBar searchBar'
        'mobileQuickLinks mobileQuickLinks mobileQuickLinks mobileQuickLinks';

    grid-template-columns: var(--main-navigation-grid-column-width-mobile);
    gap: 10px;
    padding: 16px 12px;

    @include mq($from: tablet) {
        grid-template-columns: var(--main-navigation-grid-column-width-tablet);
    }

    @include mq($from: desktop) {
        grid-template-areas:
            'logo searchBar shippingText userMenu'
            'desktopMenu desktopMenu desktopMenu desktopMenu';
        grid-template-columns: var(--main-navigation-grid-column-width-desktop);
        padding: 16px 0;
        column-gap: 20px;
    }

    @include mq($from: wide) {
        grid-template-areas:
            'logo searchBar shippingText userMenu'
            'logo desktopMenu desktopMenu desktopMenu';
        grid-template-columns: var(--main-navigation-grid-column-width-wide);
    }
}

.main-navigation__mobile-button {
    grid-area: mobileMenuButton;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    @include mq($from: desktop) {
        display: none;
    }

    svg {
        font-size: 50px;
        color: var(--theme-primary);
    }
}

.main-navigation__shipping-text,
.mobile-menu__shipping-text {
    display: none;
    flex-direction: column;
    justify-content: center;
    grid-area: shippingText;
    color: var(--free-delivery-highlighted);
    font-family: var(--ff-menu);

    @include mq($from: desktop) {
        display: flex;
    }

    p {
        margin: 0;
        font-size: 8px;

        @include mq($from: tablet) {
            font-size: 16px;
        }

        &:first-of-type {
            font-size: 11px;
            font-weight: var(--fw-black);

            @include mq($from: tablet) {
                font-size: 25px;
            }
        }
    }
}

.main-navigation__mobile-quick-links {
    grid-area: mobileQuickLinks;
    display: flex;
    justify-content: space-between;

    @include mq($from: desktop) {
        display: none;
    }

    a {
        font-weight: var(--fw-semibold);
        color: var(--menu-color-primary);
    }
}
