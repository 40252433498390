.contact-block {
    display: flex;
    flex: 1 1 50%;
    padding-bottom: 20px;

    @include mq($from: tablet) {
        padding-bottom: 0;
    }
}

.contact-block__icon {
    flex-shrink: 0;
    filter: drop-shadow(0 0 1px rgba(#000, 0.35));
    width: 40px;
    height: 40px;
}

.contact-block__items {
    margin-left: 20px;
}

.contact-block__title {
    font-weight: var(--fw-bold);
    font-size: var(--fs-h4-mobile);
    margin-bottom: 10px;

    @include mq($from: tablet) {
        font-size: var(--fs-h4);
    }
}
