.teaser-grid {
    background: var(--color-white-true);
    position: relative;
}

.teaser-grid__grid {
    width: 100%;
    display: grid;
    gap: 20px;
    padding: 30px 20px;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
    }
}

//.teaser-grid__grid--{desktop}-{mobile}-columns
.teaser-grid__grid--1-1-columns {
    grid-template-columns: repeat(1, 1fr);
}

.teaser-grid__grid--1-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.teaser-grid__grid--1x-1-columns {
    grid-template-columns: repeat(1, 1fr);
}

.teaser-grid__grid--1x-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.teaser-grid__grid--2-2-columns {
    grid-template-columns: repeat(2, 1fr);
}

.teaser-grid__grid--3-1-columns {
    @include mq($from: tablet) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.teaser-grid__grid--3-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.teaser-grid__grid--4-1-columns {
    @include mq($from: tablet) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.teaser-grid__grid--4-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.teaser-grid__grid--5-1-columns {
    @include mq($from: tablet) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.teaser-grid__grid--5-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.teaser-grid__grid--6-1-columns {
    @include mq($from: tablet) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.teaser-grid__grid--6-2-columns {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.teaser-grid__grid--big-first-row {
    @include mq($until: tablet) {
        > div:first-child {
            grid-column: span 2;
        }
    }
}

.teaser-grid__grid--no-gap-between-teasers {
    gap: 0;
}

.teaser-grid__grid--no-bottom-padding {
    padding-bottom: 0;
}

.teaser-grid__headlines {
    color: var(--color-black);
    padding: 20px 20px 0;
}
