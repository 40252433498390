.breadcrumb {
    display: flex;
    padding: 12px 12px 0;
    margin-bottom: 12px;
    list-style: none;

    @include mq($from: desktop) {
        padding: 12px 0 0;
    }
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    font-size: var(--fs-s);
    color: var(--color-black);

    /* stylelint-disable-next-line selector-class-pattern */
    .icon--Home {
        position: relative;
        top: 2px;
    }

    &__link {
        color: var(--color-black);
    }

    &__icon {
        font-size: 8px;
        margin: 0 8px;
    }

    span {
        color: var(--theme-primary)
    }
}

.breadcrumb-item:has(.breadcrumb-item__current-product) {
    display: none;

    @include mq($from: tablet) {
        display: flex;
    }
}
