.checkmark-list {
    li {
        align-items: center;
    }

    li::before {
        height: 1em;
        width: 1em;
        mask: url('../../../assets/icons/check.svg') no-repeat center center;
        background: var(--theme-primary);
    }
}
