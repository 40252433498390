/* stylelint-disable declaration-no-important */
.product-details-thumbnails-gallery--wrapper {
    grid-area: thumbnails;
    display: none;
    height: 350px;
    position: sticky;
    top: 30px;

    @include mq($from: wide) {
        height: 514px;
    }

    @include mq($from: desktop) {
        display: block;
    }

    .product-details__image {
        &:focus-visible {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .arrow-button {
        padding: 5px 0;
        line-height: 15px;
        color: var(--theme-primary);
        background: none;
        border: none;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 30px;
        text-align: center;
        box-shadow: none;
        cursor: pointer;
    }

    .arrow-button--navigation-disabled {
        display: block !important;
        cursor: auto;

        svg {
            color: transparent;
        }
    }

    .swiper {
        z-index: 0;
    }

    .swiper-wrapper {
        width: inherit;
        padding: 0;
    }

    .swiper-slide {
        height: 55px !important;
        width: 55px;
        display: flex;
        justify-content: center;

        @include mq($from: wide) {
            height: 85px !important;
            width: 85px !important;
        }
    }

    .swiper-slide-thumb-active {
        border: 2px solid var(--pdp-selected-image-border-color);
    }
}

.product-details-thumbnails-gallery--video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 57px;
    width: 57px;
    background: var(--pdp-video-thumbnail-background);

    cursor: pointer;

    @include mq($from: wide) {
        height: 87px;
        width: 87px;
    }

    .product-details-thumbnails-gallery__playbutton {
        color: var(--pdp-video-thumbnail-icon);
        font-size: 18px;
        background: #fff;
        border-radius: 50%;

        @include mq($from: 1300px) {
            font-size: 36px;
        }
    }

    .product-details-thumbnails-gallery__videotext {
        position: relative;
        top: 6px;
        font-size: var(--fs-s);
        font-weight: 400;
    }
}

.product-details-thumbnails-gallery__thumbnail-container {
    aspect-ratio: 1/1;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
}

.product-details-thumbnails-gallery__image {
    cursor: pointer;
    height: 100%;
}

.product-details-thumbnails-gallery__slider {
    height: 300px;
    margin: 0;

    @include mq($from: wide) {
        height: 418px;
    }

    .slider__inner-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .arrow-button--top {
        order: 1;
    }

    .product-details-thumbnails-gallery__swiper {
        order: 2;
    }

    .arrow-button--bottom {
        order: 3;
    }
}

body:has(.content-navigation) {
    .product-details-thumbnails-gallery--wrapper {
        top: 72px
    }
}
