body:has(.country-select-container--open) {
    [id^='userlike-frame-'] {
        visibility: hidden;
    }
}

.country-switch {
    padding: 0 3px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @include mq($from: tablet) {
        padding: 3px;
        width: fit-content;
    }
}

.dropdown__header {
    display: flex;
    justify-content: center;
    align-items: center;

    &--clickable {
        cursor: pointer;
    }
}

.dropdown__item-flag {
    margin: 0 5px;
    font-size: 25px;

    &--select-disabled {
        cursor: default;
    }
}

.dropdown__country-switch-icon-filled {
    transform: scale(1.6);
    fill: var(--theme-primary);
}
