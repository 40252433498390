.customer-club {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.customer-club__headlines {
    padding: 0;
}

.customer-club__wrapper {
    font-family: var(--ff-menu);

    h1,
    h2,
    h3 {
        font-family: var(--ff-menu);
        font-weight: var(--fw-semibold);
    }

    .headlines__headline {
        text-align: left;
        border-bottom: 1px solid var(--theme-primary);
        padding: 20px 0;
    }
}

.customer-club__submit {
    padding: 12px 18px;
    max-width: 316px;
}
