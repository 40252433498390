.date-of-birth-input__inputs {
    display: flex;
}

.date-of-birth-input__masked-input {
    flex-grow: 1;
}

.date-of-birth-input__masked-input:not(:last-child) {
    margin-right: 10px;
}

.date-of-birth-input__error {
    padding-top: 2px;
    display: block;
    color: var(--input-error);
    font-size: 15px;
}

.date-of-birth-input__hidden-input {
    opacity: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    filter: alpha(opacity=0);
    overflow: hidden;
}

.date-of-birth-input__label {
    margin-bottom: 10px;
    margin-left: 4px;
    font-size: var(--fs-sm);
    font-weight: var(--fw-semibold);
}
