.pagination__wrapper {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    border-radius: var(--border-radius-default);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    background: var(--color-white-true);
    width: fit-content;
    list-style: none;
    margin: 0;
}

.pagination__item {
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.pagination__item:nth-last-child(2) {
    .pagination__link:not(.pagination__item-active)::before {
        content: '';
        width: 1px;
        height: 32px;
        background: var(--theme-primary);
        position: absolute;
        right: 0;
        top: 7px;
    }
}

.pagination__item:nth-child(2) {
    .pagination__link:not(.pagination__item-active)::before {
        content: '';
        width: 1px;
        height: 32px;
        background: var(--theme-primary);
        position: absolute;
        left: 0;
        top: 7px;
    }
}

.pagination__link {
    height: 46px;
    border-top: 1px solid var(--theme-primary);
    border-bottom: 1px solid var(--theme-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 100ms ease-in-out;
    color: var(--color-black);
}

.pagination__page-link,
.pagination__break-link {
    @include mq($from: tablet) {
        width: 46px;
    }

    @include mq($until: tablet) {
        width: 40px;
    }

    @include mq($until: 360px) {
        width: 35px;
    }
}

.pagination__break-link {
    @include mq($until: tablet) {
        width: 25px;
    }
}

.pagination__link:hover {
    background-color: var(--color-gray-300);
}

.pagination__link:focus {
    background-color: var(--color-gray-300);
    outline: none;
}

.pagination__item::before,
.pagination__next-previous::before {
    display: none;
}

.pagination__next-previous {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.pagination__next-previous.disabled {
    cursor: not-allowed;

    .pagination__link:hover,
    .pagination__link:focus {
        background-color: unset;
    }

    .pagination__link {
        color: var(--color-gray-600);
    }
}

.pagination__previous {
    padding: 0 16px;
    border-left: 1px solid var(--theme-primary);
    border-bottom: 1px solid var(--theme-primary);
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    font-weight: var(--fw-bold);

    @include mq($until: tablet) {
        padding: 0 12px;
    }

    @include mq($until: 360px) {
        padding: 0 10px;
    }
}

.pagination__previous-icon {
    @include mq($from: tablet) {
        padding-right: 4px;
    }
}

.pagination__previous-text {
    padding-left: 4px;

    @include mq($until: tablet) {
        display: none;
    }
}

.pagination__next {
    line-height: 16px;
    padding: 0 16px;
    border-right: 1px solid var(--theme-primary);
    border-bottom: 1px solid var(--theme-primary);
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
    font-weight: var(--fw-bold);

    @include mq($until: tablet) {
        padding: 0 12px;
    }

    @include mq($until: 360px) {
        padding: 0 10px;
    }
}

.pagination__next-icon {
    @include mq($from: tablet) {
        padding-left: 4px;
    }
}

.pagination__next-text {
    padding-right: 4px;

    @include mq($until: tablet) {
        display: none;
    }
}

.pagination__item-active {
    border: 1px solid var(--color-black);
    font-weight: var(--fw-bold);
}

.pagination__item-active:focus {
    background-color: unset;
}
