.stage-slider {
    .slider {
        margin: 0;
    }
}

.stage-slider__content-container {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    grid-template-areas:
        'imageContainer'
        'textContainer';

    @include mq($from: tablet) {
        overflow: hidden;
    }
}

.stage-slider--image-position-left {
    @include mq($from: desktop) {
        .stage-slider__content-container {
            grid-template-columns: 75% 25%;
            grid-template-areas: 'imageContainer textContainer';
        }
    }
}

.stage-slider__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 15px;
}

.stage-slider__content {
    grid-area: textContainer;
    padding: var(--sp-base) var(--sp-lg);

    @include mq($from: desktop) {
        padding: var(--sp-base) var(--sp-xl) var(--sp-base) var(--sp-lg);
    }
}

.stage-slider__image-container {
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 84%, 0% 100%);
    height: 300px;
    margin-top: 20px;

    @include mq($from: desktop) {
        position: relative;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
        height: auto;
    }
}

.stage-slider__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
