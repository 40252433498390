.cart-product {
    z-index: var(--zi-cart-product);
    position: relative;
    display: grid;
    grid-template-areas:
        'productImage productDetail'
        'orderInfo orderInfo';
    grid-gap: 15px;
    padding: 0 15px;
    margin-bottom: 10px;
    background: var(--bg-cart-product);

    @include mq($from: tablet) {
        grid-template-columns: 25% auto;
        grid-template-areas:
            'productImage productDetail'
            'productImage orderInfo';
        padding: 20px;
    }
}

.cart-product__image-container {
    grid-area: productImage;
}

.cart-product__detail {
    grid-area: productDetail;
}

.cart-product__order-info {
    grid-area: orderInfo;
}

.cart-product__detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-product__remove-button {
    grid-area: removeButton;
    cursor: pointer;
    font-size: var(--fs-h3);
    padding-left: 10px;
}

.cart-product__description {
    grid-area: description;

    li {
        margin-bottom: 0;
    }
}

.cart-product-description__preview--oversized {
    overflow-y: hidden;
}

.cart-product__price-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1;
}

.cart-product__quantity-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cart-product__quantity {
    margin-right: 10px;
}
