.variant-option {
    border: 1px solid var(--color-gray-400);
    border-radius: var(--border-radius-default);
    cursor: pointer;
    background: var(--color-gray-250);
    user-select: none;
}

.variant-option__title {
    min-width: 95px;
    max-width: 190px;
    font-size: 16px;
    color: var(--color-gray-550);
    padding: 6px 12px;
    background: var(--color-white-true);
    border-radius: var(--border-radius-default);
    border: 1px solid var(--color-gray-500);
    margin: -1px;

    @include mq($from: tablet) {
        word-break: break-word;
    }
}

.variant-option__price {
    font-size: 16px;
    padding: 6px 12px;
}

.variant-option--disabled {
    border-style: dashed;
    border-color: var(--color-gray-500);

    .variant-option__title {
        border: none;
        background: transparent;
    }
}

.variant-option:not(.variant-option--disabled):hover,
.variant-option--active {
    .variant-option__title {
        color: var(--theme-primary);
        border-color: var(--theme-primary);
    }
}

.variant-option--active {
    .variant-option__title {
        font-weight: var(--fw-bold);
    }
}

.variant-option--promotion {
    .variant-option__price {
        color: var(--color-red);
        font-weight: var(--fw-semibold);
    }
}
