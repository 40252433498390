.deal-of-the-day__wrapper .product {
    @include mq($until: tablet) {
        display: grid;
        flex-wrap: wrap;
        margin-top: 10px;
        grid-template-columns: 30% 70%;

        .product__product-top {
            aspect-ratio: 1/1;
        }

        .product__details-container {
            min-height: auto;
        }
    }
}

.deal-of-the-day__has-products {
    margin-bottom: 40px;
}

.deal-of-the-day__header {
    text-align: center;
}

.deal-of-the-day__header-title {
    padding: 20px 0 0;

    .headlines__headline {
        color: var(--color-product-title);
    }

    .headlines__subheadline {
        margin-bottom: 20px;
    }
}

.deal-of-the-day__product-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include mq($from: tablet) {
        margin-top: 20px;
        grid-gap: 20px;
        grid-template-columns: auto 30%;
    }
}

.deal-of-the-day__product-container-left {
    position: relative;
    height: 100%;

    .catalog-badge--filled--red {
        position: absolute;
        bottom: 5px;
        right: 10px;
        background: var(--color-white-true);
        color: var(--theme-error);
        border: 1px solid var(--theme-error);
    }
}

.deal-of-the-day__main-image {
    box-shadow: var(--box-shadow-default);
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius-default);

    @include mq($until: tablet) {
        width: 100%;
    }
}

.product.deal-of-the-day__product {
    flex-direction: row;
    margin: 20px;
    height: 95%;

    @include mq($from: tablet) {
        display: grid;
        grid-template-rows: 1fr 1fr;
        margin: 0;
        height: 100%;
    }
}

.deal-of-the-day__product {
    .product__image-container {
        width: 100px;
        padding: 10px;
        margin-bottom: 0;

        @include mq($from: tablet) {
            padding: 0;
            width: 100%;
        }

        .product__image {
            margin: 0;
            height: 100%;
            border-radius: var(--border-radius-default);
            object-fit: cover;
            align-self: flex-start;

            @include mq($from: tablet) {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .product__title {
        margin: 0;
        min-height: unset;
        padding-bottom: unset;
    }

    .star-rating-bar {
        margin: 10px 0;
    }

    .product__product-data {
        min-height: unset;
    }

    .product__product-button {
        color: var(--color-black-true);
    }

    .product__image-container__image {
        border-radius: var(--border-radius-product-image-container);
    }
}

.deal-of-the-day__image-sticker {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.badge {
    &.deal-of-the-day__image-sticker {
        border-bottom-right-radius: 0;
        background: var(--color-white-true);
        color: var(--theme-error);
        border: 1px solid var(--theme-error);
        padding: 0 5px;
    }
}
