.product-details-gallery {
    display: block;

    @include mq($from: tablet) {
        position: relative;
    }

    .swiper-wrapper {
        width: inherit;
        padding: 0;
        aspect-ratio: 1/1;
    }

    .arrow-button {
        display: none;
        color: var(--pdp-slider-arrow-color);
        background: var(--pdp-slider-arrow-background);
        z-index: 1;
        bottom: 45%;

        @include mq($from: tablet) {
            display: flex;
        }

        &--left {
            left: 8px;
        }

        &--right {
            right: 8px;
        }
    }
}

.product-details-gallery__image {
    height: 100%;

    &:focus-visible {
        outline: none;
    }
}

.product-details-gallery__pagination {
    display: flex;
    width: 100%;
    position: absolute;
    top: 100%;
    flex-wrap: wrap;
    text-align: center;
    margin: 4px 0;

    @include mq($from: tablet) {
        display: none;
    }

    .slider__pagination-bullet {
        margin: 4px;
    }
}

.product-details-gallery__slider {
    margin: 0;

    .slider__inner-wrapper {
        margin: 0;
    }

    .swiper-slide {
        text-align: center;
    }
}
