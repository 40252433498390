.marketing-slider__slide-item {
    cursor: default;
    font-size: 14px;
    display: flex;
    align-items: center;

    * {
        width: 100%;
    }

    @include mq($until: desktop) {
        * {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.marketing-slider__slide-text {
    padding: 0 20px;
}

.marketing-slider__text-wrapper {
    margin: 10px 0;
}

.marketing-slider__cursor-pointer {
    cursor: pointer;
}

.swiper-wrapper {
    width: auto;
}
