.set-new-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: auto;
    padding: 0 15px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.set-new-password__title {
    padding: 20px 0;

    @include mq($until: tablet) {
        padding: 10px 0;
    }
}

.set-new-password__title .headlines__headline {
    color: var(--theme-primary);
}

.set-new-password__description {
    margin-bottom: 20px;
}

.set-new-password__btn {
    margin: 15px 0;
}
