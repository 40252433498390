.voucher-validity-indicator__icon-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    font-size: var(--fs-sm);
}

.voucher-validity-indicator__code-container {
    display: flex;
}

.voucher-validity-indicator__label {
    display: flex;
    gap: 5px;
    font-size: var(--fs-sm);
    min-width: 0;
}

.voucher-validity-indicator__code {
    cursor: pointer;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
}

.voucher-validity-indicator__voucher-valid {
    color: var(--theme-success);
}

.voucher-validity-indicator__voucher-invalid {
    color: var(--theme-error);
}
