.contact {
    padding: 20px;

    .headlines {
        text-align: left;

        @include mq($from: tablet) {
            text-align: center;
        }
    }
}

.background--default {
    .headlines__headline {
        color: var(--theme-on-default);
    }
}

.background--tertiary {
    .headlines__headline {
        color: var(--theme-on-tertiary-border);
    }
}

.contact__quote-container {
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-areas: 'imageContainer quoteContainer';
    grid-gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    @include mq($from: tablet) {
        grid-template-areas: '. imageContainer quoteContainer .';
        grid-template-columns: auto 150px 300px auto;
    }

    @include mq($from: desktop) {
        grid-template-areas: '. imageContainer quoteContainer .';
        grid-template-columns: auto 200px 300px auto;
    }
}

.contact__image-container {
    grid-area: imageContainer;
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;

    @include mq($from: tablet) {
        width: 150px;
        height: 150px;
    }
    @include mq($from: desktop) {
        width: 200px;
        height: 200px;
    }

    img {
        display: inline;
        height: 100%;
        width: auto;
        max-width: unset;
    }
}

.contact__quote-content {
    grid-area: quoteContainer;
}

.contact__quote {
    font-style: italic;
}

.contact__quote-name {
    font-weight: var(--fw-bold);
}

.contact__contact-headline {
    text-align: left;
    font-weight: var(--fw-medium);
    font-size: var(--fs-h3-mobile);
    margin-bottom: 20px;

    @include mq($from: tablet) {
        font-size: var(--fs-h3);
        text-align: center;
    }
}

.contact__contact-container {
    display: flex;
    justify-content: center;

    @include mq($from: tablet) {
        width: 100%;
    }
}

.contact__contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include mq($from: tablet) {
        display: grid;
        width: 100%;
        grid-template-areas: 'even odd';
        grid-template-columns: 1fr 1fr;
    }
}

.contact__odd-grid,
.contact__even-grid {
    grid-area: odd;
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: row;

    @include mq($from: tablet) {
        justify-content: center;
    }
}

.contact__even-grid {
    grid-area: even;
}
