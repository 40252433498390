.voucher-redemption__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;

    @include mq($from: tablet) {
        padding: 10px 20px;
    }

    &:hover {
        cursor: pointer;
    }
}

.voucher-redemption__body {
    display: flex;
    margin-bottom: 20px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.voucher-redemption__body-code {
    border: 1px solid var(--color-gray-80);
    border-right: none;
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
    text-overflow: ellipsis;
    width: 100%;

    font-size: var(--fs-sm);
    color: var(--color-gray-600);

    .input__input:focus ~ .input__title,
    .input__input:not(:placeholder-shown) ~ .input__title {
        transform: translate(-12px, -11px) scale(0.85);
        width: 115%;
    }

    .input__label {
        overflow: hidden;
    }

    .input__input {
        border: none;
        outline: none;
    }

    .input__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding-right: 0;
    }

    &::placeholder {
        color: var(--color-gray-500);
    }
}

.voucher-redemption__body-btn {
    color: var(--color-gray-600);
    font-weight: var(--fw-semibold);
    padding: 12px;
    border: 1px solid var(--color-gray-80);
    background: var(--color-gray-200);
    border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
    text-transform: none;
    min-width: auto;
    font-size: var(--fs-sm);
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 1;
    }
}

.voucher-redemption__msg-success {
    color: var(--theme-success);
    padding: 0 20px;
    margin-top: 20px;
}

.voucher-redemption__msg-error {
    display: flex;
    color: var(--theme-error);
    margin: 12px;
}

.voucher-redemption__wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 0 20px;
}

.voucher-redemption__voucher {
    line-height: 23px;
    max-width: 250px;
}
