.subscription-management-product__name {
    margin-bottom: 10px;
}

.subscription-management-product__image {
    max-width: 150px;
}

.subscription-management-product__wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    padding: 20px 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--theme-primary);
    }
}

.subscription-management-product__container {
    display: flex;
    flex-direction: column;
}

.subscription-management-product__details-container {
    display: flex;
    align-items: center;
    gap: 25px;

    @include mq($until: tablet) {
        gap: 15px;
    }
}

.subscription-management-product__details {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px 20px;
    font-weight: var(--fw-semibold);

    div {
        display: contents;
    }

    span {
        display: block;
    }
}

.subscription-management-product__cancel-button {
    margin-top: auto;
    margin-left: auto;
}
