@font-face {
    font-family: 'Nunito';
    src: url('../../assets/fonts/nunito/Nunito-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 200 1000;
}
@font-face {
    font-family: 'Nunito';
    src: url('../../assets/fonts/nunito/Nunito-Italic-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 200 1000;
}
@font-face {
    font-family: 'Oswald';
    src: url('../../assets/fonts/oswald/Oswald-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-weight: 200 700;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/robotoCondensed/RobotoCondensed-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 200 1000;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/robotoCondensed/RobotoCondensed-Italic-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 200 1000;
}
