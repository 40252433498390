.mobile-image-gallery {
    position: fixed;
    inset: 0;
    background: var(--color-white-true);
    z-index: var(--zi-modal);

    .swiper-slide-prev {
        margin-left: 0;
    }

    .swiper-slide-next,
    .swiper-slide-prev {
        @include mq($until: tablet) {
            opacity: 1;
        }
    }

    .slider__inner-wrapper {
        height: 100%;
    }

    .swiper-wrapper {
        height: 100%;
        align-items: center;
    }

    figcaption {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        text-align: center;
        color: var(--pdp-color-caption);
        font-size: var(--fs-l);
        font-weight: var(--fw-semibold);
    }
}

.mobile-image-gallery__slide-inner-wrapper {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
        display: flex;
        align-items: flex-end;
    }

    .mobile-image-gallery__video {
        height: calc(90vh - 23vw);
        align-items: center;

        .youtube-video {
            width: 100%;
        }
    }
}

.mobile-image-gallery__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    padding: 0 12px;
}

.mobile-image-gallery__icon-wrapper svg {
    display: block;
    font-size: 24px;
    color: var(--color-gray-900);
}

.mobile-image-gallery__slider-wrapper {
    position: relative;
    height: calc(100% - 40px);
}

.mobile-image-gallery__main-slider__slider {
    height: calc(100% - 23vw);
    margin: 0;

    .arrow-button {
        display: flex;
        z-index: 10;
        margin-bottom: 0;

        &--left {
            left: 4px;
        }

        &--right {
            right: 4px;
        }
    }

    .swiper {
        height: 100%;
    }
}

.mobile-image-gallery__thumbs-slider__slider {
    margin: 0;

    .swiper-slide {
        > div {
            opacity: 0.5;
        }

        img {
            display: block;
        }
    }

    .swiper-slide-thumb-active {
        > div {
            border: 2px solid var(--pdp-selected-image-border-color);
            opacity: 1;
        }
    }

    .swipper-wrapper {
        padding-bottom: 0;
    }
}
