.star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-rating__star {
    margin-right: 0.1em;

    &--empty {
        color: var(--color-star-empty);
    }

    &--half {
        color: var(--color-star-full);
        fill: var(--color-star-full);
    }

    &--full {
        color: var(--color-star-full);
        fill: var(--color-star-full);
    }

    &:last-child {
        margin-right: 0;
    }
}

.star-rating__text {
    color: var(--color-black);
    margin-left: 8px;
    font-size: 14px;
    margin-top: 2px;
}
