.desktop-menu-item-custom {
    grid-area: subImage;
    position: relative;
    width: 250px;
    justify-self: flex-end;
    list-style-type: none;
    padding: 10px;
    margin: 0;

    &::before {
        background: none;
    }
}

.desktop-menu-item-custom__image {
    height: 90%;
    object-fit: cover;
}

.desktop-menu-item-custom__badge {
    position: absolute;
    top: 30px;
    left: -10px;
    background: var(--color-teaser-cta-box);
    text-transform: uppercase;
    padding: 5px 30px;
    border-radius: var(--border-radius-default);
    border-bottom-left-radius: 0;
}

.desktop-menu-item-custom__link {
    text-align: center;
}

.desktop-menu-item-custom__link-text {
    color: var(--theme-primary);
    text-decoration: underline;
    font-size: var(--fs-sub-navigation-desktop);
}
