.catalog-badge {
    border-radius: var(--border-radius-badge);
    font-size: var(--fs-badge-medium);
    font-weight: 600;
    padding: 0 10px;
    text-align: center;
}

.catalog-badge--size-small {
    font-size: var(--fs-badge-small);
}

.catalog-badge--size-medium {
    font-size: var(--fs-badge-medium);
}

.catalog-badge--size-large {
    font-size: var(--fs-badge-medium);
    line-height: 28px;
}

.catalog-badge--size-xlarge {
    font-size: var(--fs-badge-large);
    line-height: 32px;
}

:not(.catalog-badge--size-large).catalog-badge--corner-bottom-left {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: var(--fs-badge-small);
    line-height: 21px;
}

.catalog-badge--corner-bottom-right {
    border-radius: var(--border-radius-bottom-right-badge);
    text-align: right;
}

.catalog-badge--corner-top-left {
    border-top-left-radius: 0;
}

.catalog-badge--corner-top-right {
    border-top-right-radius: 0;
}

.catalog-badge--topseller {
    background-color: var(--color-badge-topseller-background);
    color: var(--color-badge-topseller-text);
}

.catalog-badge--bware {
    background-color: var(--color-badge-bware-background);
    color: var(--color-badge-bware-text);
}

.catalog-badge--sale {
    background-color: var(--color-badge-sale-background);
    color: var(--color-badge-sale-text);
}

.catalog-badge--aktion {
    background-color: var(--color-badge-aktion-background);
    color: var(--color-badge-aktion-text);
}

.catalog-badge--sparset {
    background-color: var(--color-badge-sparset-background);
    color: var(--color-badge-sparset-text);
}

.catalog-badge--property {
    background-color: var(--color-badge-property-background);
    color: var(--color-badge-property-text);
}

.catalog-badge--savings {
    background-color: var(--color-badge-savings-background);
    color: var(--color-badge-savings-text);
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-badge-savings-text);
}

.catalog-badge--savings-pdp {
    background-color: var(--color-badge-savings-pdp-background);
    color: var(--color-badge-savings-pdp-text);
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-badge-savings-pdp-background);
}

.catalog-badge--savings-special-deal {
    background-color: var(--color-badge-savings-special-deal-background);
    color: var(--color-badge-savings-pdp-text);
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-badge-savings-special-deal-background);
}

.catalog-badge--gratis {
    background-color: var(--color-badge-gratis-background);
    color: var(--color-badge-gratis-text);
    border: 1px solid var(--color-badge-gratis-text);
    border-right: none;
}

.catalog-badge--xplusy {
    background-color: var(--color-badge-xplusy-background);
    color: var(--color-badge-xplusy-text);
}

.catalog-badge--raten {
    background-color: var(--color-badge-raten-background);
    color: var(--color-badge-raten-text);
    display: block;
}

.catalog-badge--ratensavings {
    background-color: var(--color-badge-raten-background);
    color: var(--color-badge-raten-text);
}
