.download-item {
    display: flex;
    flex-wrap: wrap;

    @include mq($from: tablet) {
        padding: 15px 75px;
    }

    @include mq($until: tablet) {
        justify-content: space-around;
    }
}

.download-item__no-data {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}
