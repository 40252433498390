.login-dropdown {
    display: none;
    padding: 0 15px;
    color: var(--color-black);
    background: var(--color-white-true);
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    min-width: 219px;

    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        margin: 1em 0;

        span {
            cursor: pointer;
        }
    }

    a {
        width: 100%;
        word-break: keep-all;
        white-space: pre;
        text-decoration: none;
        color: var(--color-black);
    }
}

.login-dropdown__welcome-message {
    border-bottom: 1px solid;
    border-color: var(--main-navigation-custom-separator-color);
}

.login-dropdown__welcome-message span {
    font-weight: 600;
}
