.product-subscription-options__radio-group {
    position: relative;
    display: flex;
    flex-direction: column;

    @include mq($from: desktop) {
        flex-direction: row;
    }

    .radio-button {
        margin-bottom: 20px;
    }

    .radio-button__text {
        font-size: 18px;
    }
}

.product-subscription-options__abo-info-text {
    position: absolute;
    color: var(--pdp-abo-info-color);
    font-size: var(--fs-s);
}

.product-subscription-options__select {
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 20px 0;
}

.product-subscription-options__interval-error {
    color: var(--theme-error);
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.product-subscription-options__attention-icon {
    margin-right: 5px;
}
