.product-extra-info-table {
    border-collapse: collapse;
}

.product-extra-info-table__row:nth-child(odd) {
    background-color: var(--color-gray-300);
}

.product-extra-info-table__data-label {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
}

.product-extra-info-table__data-value {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
}
