.input__label {
    position: relative;
    display: block;
}

.input__input {
    width: 100%;
    padding: 18px 15px 8px;
    background-color: var(--bg-input);
    border: 1px solid var(--border-input);

    &::placeholder {
        color: transparent;
    }

    &:disabled {
        border-color: var(--color-gray-400);
        color: var(--color-gray-600);
    }
}

.input__input--error {
    border: 1px solid var(--theme-error);
}

.input__title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 13px 15px 11px;
    color: var(--input-text);
    font-size: var(--fs-sm);
    font-weight: var(--fw-normal);
    pointer-events: none;
    transition: 0.2s ease transform;
}

.input__input:focus ~ .input__title,
.input__input:not(:placeholder-shown) ~ .input__title {
    pointer-events: none;
    transform: translate(-4px, -11px) scale(0.85);
}

.input__error {
    padding-top: 2px;
    color: var(--input-error);
    font-size: var(--fs-sm);
}

.input__input:not(:placeholder-shown):invalid ~ .input__error {
    display: block;
}

.input__check-icon {
    color: var(--input-success);
}

.input__check {
    display: none;
}

.input__input:not(:placeholder-shown):valid ~ .input__check {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}
