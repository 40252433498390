.desktop-sub-navigation-column,
.desktop-sub-navigation-column__item {
    list-style: none;
    margin: 0;
    padding: 0;
}

.desktop-sub-navigation-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.desktop-sub-navigation-column__item {
    width: max-content;
    color: var(--main-navigation-custom-font-color);
    font-family: var(--ff-menu);
    font-weight: var(--desktop-navigation-item-font-weight);

    &--headline {
        font-weight: var(--desktop-navigation-headline-font-weight);
        margin-bottom: 4px;

        .desktop-sub-navigation-column__item-link {
            font-weight: var(--desktop-navigation-headline-font-weight);
        }
    }

    &--indented {
        .desktop-sub-navigation-column__item-link::before {
            content: '›';
            padding-right: 4px;
            color: var(--main-navigation-custom-font-color);
        }
    }
}

.desktop-sub-navigation-column__item-link {
    color: var(--main-navigation-custom-font-color);
    font-weight: var(--desktop-navigation-item-font-weight);

    &:hover {
        color: var(--main-navigation-custom-active-color);

        &::before {
            color: var(--main-navigation-custom-active-color);
        }
    }
}
