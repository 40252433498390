.background-image {
    height: 100%;
    width: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &--parallax {
        background-attachment: fixed;

        @supports (-webkit-touch-callout: none) {
            background-attachment: initial !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}
