.existing-account-modal {
    &::-webkit-scrollbar {
        display: none; /* Webkit */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.existing-account-modal__actions {
    text-align: center;

    > button {
        margin: 8px;
    }
}
