.splp-cart__container {
    display: grid;
    grid-row-gap: 0;
    grid-template-areas:
        'products'
        'addon'
        'summary';
    background-color: var(--bg-cart);
    align-items: flex-start;
    padding: 0;

    @include mq($from: tablet) {
        grid-template-columns: auto 280px;
        grid-template-areas:
            'products summary'
            'addon summary';
        background-color: var(--bg-cart-mobile);
    }

    @include mq($from: desktop) {
        grid-template-columns: auto 350px;
    }

    @include mq($from: wide) {
        grid-template-columns: auto 450px;
    }
}

.splp-cart__background {
    background-color: var(--bg-cart);
    padding: 20px 0;
}

.splp-cart__products {
    grid-area: products;

    @include mq($from: tablet) {
        margin-right: 6px;
    }
}

.splp-cart__headline-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-s);
    padding-bottom: 8px;

    @include mq($from: tablet) {
        padding: 20px 0 3px;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

    .splp-cart__headline-container {
        display: flex;
        align-items: baseline;
        gap: 15px;

        .splp-cart__headline {
            color: var(--theme-primary);
            font-size: var(--fs-l);

            @include mq($from: tablet) {
                font-size: var(--fs-xl);
            }
        }

        .splp-cart__amount {
            display: none;
            gap: 6px;
            color: var(--color-gray-600);
            margin: 0;

            span:last-child {
                font-weight: var(--fw-semibold);
            }

            @include mq($from: tablet) {
                display: flex;
            }
        }
    }

    .splp-cart__no-reservation {
        color: var(--color-gray-500);
        font-size: var(--fs-xs);
    }
}

.splp-cart__addon {
    grid-area: addon;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 0 15px;

    @include mq($from: tablet) {
        padding: 15px;
        background: var(--bg-cart-addon);
    }
}

.splp-cart__addon-headline {
    font-size: var(--fs-h2);
}

.splp-cart__summary {
    grid-area: summary;
    position: sticky;
    top: 20px;
    overflow: auto;

    @include mq($from: tablet) {
        margin-left: 6px;
    }
}

.splp-cart--has-sticky-summary {
    .splp-cart__summary {
        display: none;
    }

    .splp-cart__sticky-summary {
        display: block;
        position: fixed;
        z-index: 10;
        right: 0;
        left: 0;
        bottom: 0;
        background: var(--bg-cart-sticky-footer);
    }
}

.splp-cart__customerclub-details--expire {
    text-align: center;
    border: 1px solid var(--color-gray-80);
    border-radius: var(--border-radius-default);
    background: var(--color-white-true);
    padding: 16px;
    margin: 0 15px 26px;
    align-items: center;

    @include mq($from: tablet) {
        margin: 0 0 26px;
    }

    @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
        text-align: start;
    }

    button {
        line-height: normal;
        width: 100%;
        padding: 6px 4px;
        margin-top: 5px;

        @include mq($from: desktop) {
            width: auto;
            margin: 0;
        }
    }

    .splp-cart__icon {
        color: var(--color-red);
        position: relative;
        top: 2px;
        margin-right: 8px;
    }
}

.splp-cart__product-card {
    padding: 8px;
    border: 1px solid var(--color-gray-80);
    border-radius: var(--border-radius-default);
    background: var(--color-white-true);
    margin: 0 15px 16px;

    @include mq($from: tablet) {
        margin: 0 0 26px;
    }
}

.splp-cart__customer-card {
    margin: 0 15px;

    @include mq($from: tablet) {
        margin: 0;
    }
}

.splp-cart__separator {
    border-top: 1px solid #e9e9e9e9;
    width: calc(100% - 30px);
    margin: 10px auto;
}
