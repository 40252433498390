.info-banner-custom__html-content {
    padding: 0 20px;

    a {
        color: inherit;
    }

    p {
        margin: 0;
    }
}

.info-banner-custom__html-content-mobile {
    @include mq($from: tablet) {
        display: none;
    }
}

.info-banner-custom__html-content-desktop {
    @include mq($until: tablet) {
        display: none;
    }
}
