.emarsys-field-writer {
    text-align: center;
    padding: 20px;

    @include mq($from: desktop) {
        padding: 20px 0;
    }
}

.emarsys-field-writer__message {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &--success {
        color: var(--theme-success);
    }

    &--error {
        color: var(--theme-error);
    }
}

.emarsys-field-writer__message-icon {
    font-size: 24px;
}
