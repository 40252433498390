.country-select-container {
    display: none;

    position: absolute;
    background-color: #fff;
    z-index: var(--zi-index-dropdown);
    padding: 30px;
    left: 0;
    right: 0;
    border: 1px solid #989292;
    top: 100%;
    cursor: default;
    flex-direction: column;
    gap: 12px;

    @include mq($from: tablet) {
        top: calc(100% + 10px);
        left: unset;
        right: -94px;
    }

    @include mq($from: desktop) {
        right: -86px;
    }

    &--open {
        display: flex;
    }
}

.country-select-container__select-label {
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
    display: inline-block;
    margin-bottom: 5px;
}

.country-select-container__title {
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
}

.country-select-container__select-disable {
    display: none;

    .select__control {
        pointer-events: none;
    }

    .select__indicators {
        display: none;
    }
}

.country-select-container__button-container {
    .button__icon-right .icon {
        color: var(--color-button-primary);
    }
}

.burger-menu-country__wrapper {
    .country-select-container {
        top: 0;
        right: 0;
        left: 0;
    }

    .select-box {
        width: 100%;
    }
}
