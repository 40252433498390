.voucher-modal {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
}

.voucher-modal__headline {
    font-size: 18px;
    font-weight: 500;
}

.voucher-modal__button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
