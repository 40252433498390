.loading-indicator::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin: auto;
    border-radius: 50%;
    border: 3px solid;
    border-color: transparent currentcolor;
    animation: loading-indicator 1.5s linear infinite;
}

@keyframes loading-indicator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
