.op-checkout-cart-line-item {
    min-height: 100px;
    display: flex;
    flex-direction: row;
    border: 1px solid #bfbfbf;
    margin-bottom: 15px;
    border-radius: 5px;
}

.op-checkout-cart-line-item__image-container {
    margin-right: 10px;
    width: 36%;
    border-right: 1px solid #bfbfbf;
    @include mq($from: desktop) {
        width: 18%;
    }
}

.op-checkout-cart-line-item__name {
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
    margin-top: 8px;
    color: #b2b2b2;
}

.op-checkout-cart-line-item__detail,
.op-checkout-cart-line-item__quantity {
    font-size: var(--fs-base);
    font-weight: var(--fw-regular);
}

.op-checkout-cart-line-item__image-container img {
    padding: 5px;
}

.op-checkout-cart-line-item__detail {
    margin-top: 20px;
}

.op-checkout-cart-line-item .price-box {
    align-items: flex-start;
    margin-top: 14px;
}

.op-checkout-cart-line-item__quantity {
    margin-top: 10px;
}

.op-checkout-cart-line-item__price-free {
    margin-top: 30px;
    margin-bottom: 7px;
    background: var(--color-badge-gratis-text);
    color: var(--color-white);
    padding: 2px 10px;
    font-size: 28px;
    font-family: var(--font-product-price);
    border-radius: 6px;
    text-transform: uppercase;
    width: 43%;
}
