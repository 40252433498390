:root {
    // Fonts
    /////////////////////////////////////////////
    --font-base: sans-serif;
    --font-title: sans-serif;
    --font-headlines: sans-serif;
    --font-menu: sans-serif;
    --font-product-price: sans-serif;

    --fs-base-px: 15;
    --fs-xxs: calc(0.4rem / 16 * var(--fs-base-px)); // 5.625px
    --fs-xs: calc(0.8rem / 16 * var(--fs-base-px)); // 11.25px
    --fs-s: calc(0.9rem / 16 * var(--fs-base-px)); // 12.65625px
    --fs-sm: calc(0.9375rem / 16 * var(--fs-base-px)); // 15px
    --fs-base: calc(1rem / 16 * var(--fs-base-px)); // 14.0625px
    --fs-l: calc(1.33rem / 16 * var(--fs-base-px)); // 18.703125px
    --fs-xl: calc(1.66rem / 16 * var(--fs-base-px)); // 23.34375px
    --fs-xxl: calc(2.66rem / 16 * var(--fs-base-px)); // 37.40625px
    --fs-xxxl: calc(3.6rem / 16 * var(--fs-base-px)); // 50.625px
    --fs-xxxxl: calc(3.8rem / 16 * var(--fs-base-px)); // 53.4375px

    --fs-h1-mobile: var(--fs-xl);
    --fs-h1: var(--fs-xxl);
    --fs-h1-sub-mobile: var(--fs-l);
    --fs-h1-sub: var(--fs-xl);
    --fs-h2-mobile: var(--fs-l);
    --fs-h2: var(--fs-xl);
    --fs-h2-sub-mobile: var(--fs-base);
    --fs-h2-sub: var(--fs-l);
    --fs-h3-mobile: var(--fs-base);
    --fs-h3: var(--fs-l);
    --fs-h3-sub-mobile: var(--fs-s);
    --fs-h3-sub: var(--fs-base);
    --fs-h4-mobile: var(--fs-base);
    --fs-h4: var(--fs-l);
    --fs-h4-sub-mobile: var(--fs-s);
    --fs-h4-sub: var(--fs-base);
    --fs-h5-mobile: var(--fs-s);
    --fs-h5: var(--fs-base);
    --fs-h6-mobile: var(--fs-s);
    --fs-h6: var(--fs-base);

    --lh-default: 1.15;
    --lh-base: var(--lh-default) * 1.45;
    --ls-small: var(--lh-default);
    --lh-big: var(--lh-default) * 2;

    --color-gray-80: #ccc;
    --color-gray-200: #f0f0f0;
    --color-gray-250: #f7f7f7;
    --color-gray-300: #efefef;
    --color-gray-400: #ddd;
    --color-gray-550: #666;
    --color-gray-600: #707070;
    --color-gray-800: #333;
    --color-gray-900: #222;
    --color-gray-900-20: #2223;

    --color-green-300: #e0ffd8;
    --color-green-500: #238e07;

    --color-yellow-500: #ffed00;

    --color-turqoise: #009bac;

    --color-white-true: #fff;
    --color-white: var(--color-gray-200);
    --color-black-true: #000;
    --color-black: var(--color-gray-900);

    --theme-primary: #333;
    --theme-secondary: #ddd;
    --theme-tertiary: #eee;
    --theme-default: var(--color-white-true);

    --theme-primary-200: #888;
    --theme-primary-transparent: #3330;

    --theme-accent: #ffee09;
    --theme-attention: #ff9000;
    --theme-cta: #ff8c00;
    --theme-error: #e30613;
    --theme-success: #009000;
    --theme-promotion: #e0fdd8;
    --theme-highlight: var(--theme-secondary);
    --theme-info: var(--theme-accent);
    --theme-warning: var(--theme-attention);

    --theme-on-primary: var(--color-white);
    --theme-on-primary-highlight: var(--color-white-true);
    --theme-on-primary-headline: var(--color-white-true);
    --theme-on-primary-subheadline: var(--color-white-true);
    --theme-on-primary-border: var(--color-white-true);

    --theme-on-secondary: var(--color-black);
    --theme-on-secondary-highlight: var(--color-black-true);
    --theme-on-secondary-headline: var(--color-black-true);
    --theme-on-secondary-subheadline: var(--color-black-true);
    --theme-on-secondary-border: var(--color-white-true);

    --theme-on-tertiary: var(--color-white);
    --theme-on-tertiary-highlight: var(--color-white-true);
    --theme-on-tertiary-headline: var(--color-white-true);
    --theme-on-tertiary-subheadline: var(--color-white-true);
    --theme-on-tertiary-border: var(--color-white-true);

    --theme-on-default: var(--color-black);
    --theme-on-default-highlight: var(--color-black-true);
    --theme-on-default-headline: var(--color-black-true);
    --theme-on-default-subheadline: var(--color-black-true);
    --theme-on-default-border: var(--theme-primary);

    --bg: var(--color-white);
    --color-text: var(--color-black);
    --color-headline: var(--color-black);
    --color-subheadline: var(--color-black);
    --color-highlight: var(--theme-primary);

    // Font Weight
    --fw-light: 300;
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
    --fw-black: 800;

    // Accordion
    --border-color-accordion-default: var(--theme-primary);
    --border-color-accordion-primary: var(--color-white-true);
    --border-color-accordion-secondary: var(--color-white-true);
    --border-color-accordion-tertiary: var(--color-white-true);

    // Background-Image with text
    --bg-background-image-with-text: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.25) 75%,
        rgba(0, 0, 0, 0) 100%
    );
    --color-background-image-with-text: var(--color-white-true);
    --bg-background-image-with-text-cta: var(--theme-tertiary);
    --color-background-image-with-text-cta: var(--color-white-true);

    // Button
    --bg-button: var(--theme-primary);
    --color-button: var(--color-white-true);
    --bg-button-primary: var(--theme-primary);
    --color-button-primary: var(--color-white-true);
    --bg-button-secondary: var(--theme-secondary);
    --color-button-tertiary: var(--color-white-true);
    --bg-button-tertiary: var(--theme-tertiary);
    --color-button-secondary: var(--color-white-true);
    --bg-button-success: var(--theme-success);
    --color-button-success: var(--color-white-true);
    --bg-button-warning: var(--theme-attention);
    --color-button-warning: var(--color-white-true);
    --bg-button-error: var(--theme-error);
    --color-button-error: var(--color-white-true);

    // Links
    --fw-links: var(--fw-regular);
    --td-links: none;

    // Lists
    --bg-li-marker: var(--color-text);

    // Select
    --bg-select: var(--color-white-true);
    --color-select: var(--color-black-true);
    --color-select-arrow: var(--theme-primary);
    --fw-select: var(--fw-bold);

    // checkbox
    --bg-checkbox: var(--color-white-true);
    --color-checkbox: var(--theme-primary);
    --color-border-checkbox: var(--theme-primary);
    --color-checkbox-label: var(--color-black-true);

    // Navigation
    --fw-navigation-links: var(--fw-bold);
    --bd-radius-navigation-links: 0;

    // Header Bar
    --bg-header-bar: var(--theme-primary);
    --color-header-bar: var(--color-white-true);

    // Footer
    --fs-footer-header-desktop: 24px;

    // Component Description Box
    --color-component-description-box: var(--color-white-true);

    // Polaroid
    --color-border-polaroid: var(--color-white-true);

    // Slider Item
    --bg-slider-item: var(--theme-primary);
    --color-slider-item: var(--color-white);
    --color-highlight-slider-item: var(--color-white-true);

    // Slider Button
    --bg-slider-button: var(--theme-attention);
    --color-slider-button: var(--color-white-true);

    // Badge
    --bg-badge: var(--theme-accent);
    --color-badge: var(--color-black);
    --bg-badge-primary: var(--theme-primary);
    --color-badge-primary: var(--theme-on-primary);
    --bg-badge-secondary: var(--theme-secondary);
    --color-badge-secondary: var(--theme-on-secondary);
    --bg-badge-tertiary: var(--theme-tertiary);
    --color-badge-tertiary: var(--theme-on-tertiary);
    --bg-badge-info: var(--theme-accent);
    --color-badge-info: var(--color-black);
    --bg-badge-highlight: var(--theme-error);
    --color-badge-highlight: var(--color-white-true);
    --color-badge-raten: var(--color-turqoise);
    --bg-badge-promotion: var(--theme-promotion);
    --color-badge-promotion: var(--color-white-true);
    --fw-text-badge: var(--fw-bold);
    --bg-badge-warning: var(--theme-attention);
    --color-badge-warning: var(--color-white-true);
    --bg-badge-success: var(--bg-button-success);
    --color-badge-success: var(--color-button-success);
    --bg-badge-error: var(--bg-button-error);
    --color-badge-error: var(--color-button-error);

    // Star Rating
    --color-star-full: #ffbe52;
    --color-star-empty: var(--color-gray-400);
    --color-star-text: var(--color-gray-800);

    // Play Button
    --bg-play-button: var(--theme-tertiary);
    --color-play-icon: var(--color-white-true);

    // InfoBanner
    --bg-info-banner-warning: var(--theme-accent);
    --bg-info-banner-error: var(--theme-error);
    --bg-info-banner-succes: var(--theme-success);
    --bg-info-banner-info: var(--color-gray-600);
    --bg-info-banner-tertiary: var(--theme-tertiary);

    // AsymmetricImage
    --bg-asymetric-image-title: var(--theme-accent);
    --color-asymetric-image-title: var(--color-black);

    // Price-Box
    --color-price-box-price: var(--theme-primary);
    --color-price-box-tax: var(--color-gray-600);

    // Product Stage
    --bg-product-stage-logos: var(--color-white-true);
    --bg-product-stage-description-show-more-button: var(--color-white-true);
    --color-product-stage-description-show-more-button: var(--theme-primary);
    --bg-product-stage-description-gradient-start: var(--theme-primary-transparent);
    --bg-product-stage-description-gradient-end: var(--theme-primary);

    // Cart
    --bg-cart-product: var(--theme-secondary);
    --border-cart-product: var(--color-white-true);
    --border-cart-product-select: var(--theme-primary);
    --color-cart-product-shipping-time: var(--theme-success);
    --bg-cart-addon: var(--theme-secondary);
    --bg-cart-addon-product: var(--theme-primary);
    --color-cart-addon-product: var(--color-white-true);
    --bg-cart-summary: var(--theme-secondary);
    --color-cart-summary-total: var(--theme-primary);
    --color-cart-summary-savings: var(--theme-success);
    --bg-cart-sticky-footer: var(--color-white-true);

    // registration
    --bg-radio-button: var(--theme-primary);
    --bg-input: var(--color-white-true);
    --border-input: var(--theme-primary);
    --input-error: var(--theme-error);
    --input-success: var(--theme-success);
    --input-text: var(--color-gray-600);

    // Checkout
    --color-border-checkout-cart: var(--theme-primary);
    --color-border-alt-checkout-cart: var(--theme-secondary);
    --color-checkout-cart-price: var(--theme-primary);
    --color-checkout-cart-tax: var(--color-black);
    --color-checkout-cart-arrow: var(--theme-primary);
    --color-borders-checkout: var(--color-gray-80);
    --color-checkout-delivery-cost: var(--theme-primary);
    --color-checkout-total-value: var(--theme-primary);
    --color-checkout-total-savings: var(--theme-success);
    --color-checkout-delivery-info: var(--theme-success);
    --bg-default-checkout-cart: var(--color-gray-200);
    --bg-checkout-summary: inherit;
    --op-checkout-hint: var(--color-gray-600);

    // Social Media
    --color-social-icon: var(--theme-secondary);
    --color-social-text: var(--theme-secondary);

    // Productbox
    --bg-product-box: var(--theme-primary);

    // ProductCard
    --bg-product-card: var(--color-white-true);
    --color-product-card-price: var(--theme-primary);

    // Table
    --bg-table-cell-default: var(--theme-secondary);
    --bg-table-cell-primary: var(--theme-primary);
    --bg-table-cell-secondary: var(--theme-secondary);
    --bg-table-cell-tertiary: var(--theme-tertiary);
    --border-table-cell-default: var(--theme-primary);
    --border-table-cell-primary: var(--theme-secondary);
    --border-table-cell-secondary: var(--theme-primary);
    --border-table-cell-tertiary: var(--theme-secondary);
    --color-table-cell-default: var(--color-black);
    --color-table-cell-primary: var(--color-white-true);
    --color-table-cell-secondary: var(--color-black);
    --color-table-cell-tertiary: var(--color-black);
    --color-headline-table-cell-default: var(--color-black-true);
    --color-headline-table-cell-primary: var(--color-black-true);
    --color-headline-table-cell-secondary: var(--color-black-true);
    --color-headline-table-cell-tertiary: var(--color-black-true);

    // Slider
    --color-slider-dot-default: var(--color-gray-600);
    --color-slider-dot-primary: var(--color-white-true);
    --color-slider-dot-secondary: var(--theme-primary-200);
    --color-slider-dot-tertiary: var(--color-white-true);
    --color-slider-active-dot-default: var(--color-black);
    --color-slider-active-dot-primary: var(--theme-attention);
    --color-slider-active-dot-secondary: var(--theme-attention);
    --color-slider-active-dot-tertiary: var(--theme-attention);

    // Flyout
    --bg-flyout-button: var(--theme-attention);

    // Shop Switch
    --fw-shop-switch-headline: 800;
    --fw-shop-switch-sub: 300;
    --fw-shop-switch-link: 400;
    --fs-shop-switch-flag: 25px;

    --color-shop-switch-link: #3789de;
    --color-shop-switch-button-bg-focus: var(--color-gray-400);
    --color-shop-switch-button-text-focus: var(--color-black);

    --color-shop-switch-button-bg-normal: var(--color-white-true);
    --color-shop-switch-button-text-normal: var(--color-gray-600);

    // Voucher Validity
    --color-voucher-overlay-bg: var(--color-white-true);

    // Club Customer Price
    --fs-club-price-text-small: var(--fs-xs);
    --fs-club-price-text-medium: var(--fs-base);
    --fs-club-price-icon-small: var(--fs-s);
    --fs-club-price-icon-medium: var(--fs-l);

    // Select Box
    --bg-select-box-selected: var(--theme-primary);
    --bg-select-box-hover: #deebff;

    // Variant Selector
    --variant-selector-border-color: #e4e4e4;

    // Modals
    --modal-overlay-bg-color: rgba(0, 0, 0, 0.6);

    // Background-Image with text
    --color-background-image-with-text-headline: var(--color-white-true);
    --color-background-image-with-text-subheadline: var(--color-white-true);

    // Select Button
    --color-select-button: var(--color-white-true);
}
