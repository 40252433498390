.arrow-button {
    appearance: none;
    background: var(--bg-slider-button);
    color: var(--color-slider-button);
    border: none;
    cursor: pointer;
    padding-top: 2px;
    height: 45px;
    width: 55px;
    line-height: 45px;
    font-size: 18px;

    @include mq($until: 375px) {
        margin-bottom: 20px;
    }

    &:focus {
        outline: none;
    }
}

.arrow-button--left {
    position: absolute;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.arrow-button--right {
    position: absolute;
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
}
