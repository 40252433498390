.customer-club-card-extension-modal__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.customer-club-card-extension-modal__attention-wrapper {
    background-color: var(--theme-primary);
    color: var(--theme-accent);
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    margin-bottom: 8px;
}

.customer-club-card-extension-modal__attention-icon {
    font-size: 5rem;
    padding: 10px 15px 15px;
}

.customer-club-card-extension-modal__text {
    text-align: center;
}

.customer-club-card-extension-modal__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include mq($from: tablet) {
        flex-direction: row;
    }
}

.customer-club-card-extension-modal__cta {
    margin: 4px 0;
    padding: 11px;

    @include mq($from: desktop) {
        margin: 0;
    }
}
