.review__details {
    font-size: 14px;
    display: flex;
    gap: 12px;

    .review-stars {
        position: relative;
        top: 1px;
    }
}

.review__text {
    margin: 0.5rem 0 1rem;
}
