.slider-navigation-item {
    appearance: none;
    border: none;
    margin: 6px;
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}
