.radio-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 85px;
    margin-right: 15px;
}

.radio-button__label {
    width: 28px;
    height: 28px;
    border: 1px solid var(--bg-radio-button);
    border-radius: 50%;
    position: relative;
}

.radio-button__input {
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    position: absolute;
}

.radio-button__button {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.radio-button__input:checked + .radio-button__button {
    background-color: var(--bg-radio-button);
    border: 4px solid var(--color-white-true);
}

.radio-button__text {
    margin-left: 5px;
    font-size: var(--fs-base);
}

.radio-button__error-text {
    padding-top: 2px;
    color: var(--input-error);
    font-size: var(--fs-sm);
}

.radio-button--error {
    border-color: var(--input-error);
}

.radio-button--error > .radio-button__input:checked + .radio-button__button {
    background-color: var(--input-error);
}
