.op-checkout-addresses {
    position: relative;
}

.op-checkout-addresses__hint {
    margin-bottom: 25px;
    padding-left: 25px;
    font-size: var(--fs-xs);
    color: var(--op-checkout-hint);
}

.op-checkout-addresses__loading {
    position: absolute;
    inset: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.7);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.op-checkout-addresses__loading-text {
    margin-top: 10px;
    font-size: 30px;
}
