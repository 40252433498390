/**
 * Use the height-property on .select to give the whole thing a height to your liking.
 * Padding should be set in the .select__select
 */
.select {
    position: relative;
    font-size: 16px;
    height: 45px;
    background: var(--bg-select);
    color: var(--color-select);
    font-weight: var(--fw-select);
}

.select--small {
    font-weight: var(--fw-normal);
}

.select--medium {
    height: 50px;
    width: 105px;
    font-size: 18px;
}

.select--large {
    font-size: 18px;
    height: 54px;
}

.select--blue {
    border: solid 1px var(--theme-primary);
}

.select > select:required:invalid {
    color: var(--input-text);
}

.select > select > option[value=''][disabled] {
    color: var(--input-text);
}

.select > select > option {
    color: var(--color-black);
}

.select__select {
    appearance: none;
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius-default);
    background: none;
    display: block;
    width: 100%;
    height: 100%;
    font-weight: inherit;
    padding: 0 52px 0 15px;
}

.select__select--error {
    border: 1px solid var(--theme-error);
}

.select__arrow {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-select-arrow);
    right: 15px;
    font-size: 25px;

    .select--disabled & {
        opacity: 0.5;
    }
}

.select__arrow--error {
    color: var(--theme-error);
}
