.usps {
    padding: 10px;
    min-height: 85px;
    display: flex;
}

.usps__container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.usps__item {
    display: flex;
    align-items: center;

    @include mq($until: tablet) {
        gap: 6px;
        flex-direction: column;
    }
}

.usps__icon-wrapper picture {
    width: 30px;
    height: 30px;
    display: flex;
    place-content: center;

    @include mq($from: tablet) {
        width: 40px;
        height: 40px;
    }

    img {
        aspect-ratio: auto 1/1;
    }
}

.usps__icon-wrapper--clickable:hover {
    cursor: pointer;
}

.usps__text-container {
    font-weight: var(--fw-normal);
    display: flex;
    align-content: center;
    align-items: center;

    @include mq($from: tablet) {
        margin-left: 5px;
    }

    @include mq($from: desktop) {
        margin-left: 20px;
    }
}

.usps__text {
    word-break: break-word;
    line-height: 1.15;
    font-size: 13px;

    @include mq($from: tablet) {
        word-break: unset;
        font-size: 15px;
    }

    @include mq($until: tablet) {
        text-align: center;
    }
}

.background--primary .usps__text {
    color: var(--color-white-true);
}

.usps__modal-background {
    position: fixed;
    inset: 0;
    z-index: var(--zi-modal);
    background: var(--modal-overlay-bg-color);
}
