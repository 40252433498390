.mobile-menu-item {
    position: relative;
    padding: 10px;
    margin: 0 0 4px;
    background: var(--color-teaser-right-active-box);
    list-style-type: none;
    font-size: var(--fs-main-navigation-mobile);
    font-weight: var(--fw-semibold);
    cursor: pointer;

    a {
        color: var(--menu-color-primary);
    }

    &:not(.mobile-menu-item__has-children) {
        a {
            display: block;
        }
    }

    &__has-children::before {
        position: absolute;
        right: 0;
        left: auto;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.1em solid var(--theme-primary);
        border-top: 0.1em solid var(--theme-primary);
        margin-right: 0.5em;
        font-size: 1.8em;
        color: var(--theme-primary);
    }

    &__arrow-up {
        &::before {
            transform: rotate(-45deg);
            top: 22px;
        }
    }

    &__arrow-down {
        &::before {
            transform: rotate(135deg);
            top: 13px;
        }
    }
}

a.mobile-menu-item--active {
    color: var(--menu-color-primary-hover);
}

.mobile-menu-item__submenu {
    background: var(--color-white-true);
    margin: 10px -10px 0;
    padding: 10px;
}

.mobile-menu-item__submenu-item {
    margin: 0;
    padding: 10px;
    list-style-type: none;
    font-size: var(--fs-sub-navigation-desktop);

    a {
        display: block;
    }
}

.mobile-menu-item__off-canvas {
    background: transparent;
}
