.burger-menu-quick-links-item {
    font-size: var(--burger-menu-font-size);
    font-weight: var(--desktop-navigation-item-font-weight);
    list-style: none;
    margin: 0;
    padding: 4px 0;
}

.burger-menu-quick-links-item__link {
    color: var(--burger-menu-quick-links-item-font-color);

    &:hover {
        color: var(--main-navigation-custom-active-color);
    }
}
