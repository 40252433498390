.deal-of-the-day-countdown {
    background-color: var(--theme-error);
    color: var(--color-white-true);
    box-shadow: var(--box-shadow-default);
    text-align: center;
    width: 100%;
    padding: 10px;

    @include mq($from: tablet) {
        border-radius: var(--border-radius-default);
    }
}

.deal-of-the-day-countdown__time {
    font-size: 20px;
}
