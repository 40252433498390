.link-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 170px;
    padding: 10px 4px;
    text-align: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
    letter-spacing: 1.25px;
    font-weight: var(--fw-semibold);
    text-transform: uppercase;
    border-radius: var(--border-radius-button);
    background: var(--bg-button);
    color: var(--color-button);
}

.link-button--full-width {
    width: 100%;
}

.link-button--primary {
    background: var(--bg-button-primary);
    color: var(--color-button-primary);
}

.link-button--secondary {
    background: var(--bg-button-secondary);
    color: var(--color-button-secondary);
}

.link-button--tertiary {
    background: var(--bg-button-tertiary);
    color: var(--color-button-tertiary);
}

.link-button--success {
    background: var(--bg-button-success);
    color: var(--color-button-success);
}

.link-button--warning {
    background: var(--bg-button-warning);
    color: var(--color-button-warning);
}

.link-button--error {
    background: var(--bg-button-error);
    color: var(--color-button-error);
}

.link-button--gray {
    background: var(--color-gray-500);
    color: var(--color-white-true);
}

.link-button--large {
    font-size: 20px;
    font-weight: var(--fw-bold);
    padding: 18px 6px;
}

.link-button--loading {
    opacity: 0.5;
    cursor: progress;

    &:active {
        pointer-events: none;
    }

    &.link-button--medium {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &.link-button--large {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.link-button--with-icon-left {
    padding-left: 35px;
}

.link-button--with-icon-right {
    padding-right: 35px;
}

.link-button__icon--medium {
    font-size: 0.9rem;
}

.link-button__icon--small {
    font-size: 0.6rem;
}

.link-button .link-button__icon-right,
.link-button .link-button__icon-left {
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
}

.link-button__icon-right {
    right: 8px;
}

.link-button__icon-left {
    left: 8px;
}

.link-button--animate {
    animation: wiggle 0.5s ease-in-out 1 normal forwards;
    animation-delay: 3s;
    -webkit-transform-origin: 50% 50%;
}

@keyframes wiggle {
    0%,
    100% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-2px, 0);
    }

    50% {
        transform: translate(2px, 0);
    }

    75% {
        transform: translate(-1px, 0);
    }
}
