.order-detail-line-item {
    border-top: 1px solid var(--theme-primary);
    display: grid;

    @include mq($from: tablet) {
        grid-template-columns: 20% 50% 20% 10%;
        grid-template-areas:
            'Image Description Items Price'
            'Image Description Actions Actions';
    }

    grid-template-columns: 30% 70%;
    grid-template-areas:
        'Image Description'
        'Image Items'
        'Image Price'
        'Actions Actions';
    padding: 20px 0;
}

.order-detail-line-item__image {
    grid-area: Image;
    padding: 10px;
}

.order-detail-line-item__name {
    color: var(--theme-primary);
    padding-bottom: 15px;
    font-family: var(--font-base);
    font-weight: var(--fw-semibold);
    font-size: 21px;
}

.order-detail-line-item__description {
    grid-area: Description;
}

.product-stage-description__preview--oversized::after {
    background: none;
}

.order-detail-line-item__quantity {
    grid-area: Items;
    font-size: 20px;
    font-weight: var(--fw-semibold);
    margin-top: 15px;

    @include mq($from: tablet) {
        margin-top: 62px;
    }
}

.order-detail-line-item__price {
    grid-area: Price;
    font-size: 20px;
    font-weight: var(--fw-semibold);
    color: var(--theme-primary);
    margin-top: 15px;
    margin-bottom: 20px;

    @include mq($from: tablet) {
        margin-top: 62px;
        margin-bottom: 0;
        text-align: right;
    }
}

.order-detail-line-item__actions {
    grid-area: Actions;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid var(--theme-primary);

    @include mq($from: desktop) {
        align-self: self-end;
        text-align: right;
        margin-top: 0;
        border-top: none;
    }
}

.order-detail-line-item__unavailable {
    grid-area: Actions;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: var(--fw-semibold);

    .order-detail-line-item__unavailable-indicator {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: var(--color-red);
    }
}

.order-detail-line-item__add-to-cart-button {
    font-size: 14px;
    border-radius: var(--border-radius-default);
    padding-left: 35px;
    padding-right: 35px;
}
