// Make sure, these align with @mediashop/app/config/breakpoints
$mq-responsive: true;
$mq-breakpoints: (
    mobile: 375px,
    tablet: 768px,
    desktop: 1200px,
    wide: 1300px,
);

@import '~sass-mq';

$breakpoint-mobile: map-get($mq-breakpoints, mobile) !default;
$breakpoint-tablet: map-get($mq-breakpoints, tablet) !default;
$breakpoint-desktop: map-get($mq-breakpoints, desktop) !default;
$breakpoint-wide: map-get($mq-breakpoints, wide) !default;

:root {
    --breakpoint-mobile: #{map-get($mq-breakpoints, mobile)};
    --breakpoint-tablet: #{map-get($mq-breakpoints, tablet)};
    --breakpoint-desktop: #{map-get($mq-breakpoints, desktop)};
    --breakpoint-wide: #{map-get($mq-breakpoints, wide)};
}
