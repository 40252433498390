.container-inline {
    display: grid;
    align-items: center;
    gap: 50px;
    grid-auto-columns: 1fr;

    @include mq($from: tablet) {
        grid-auto-flow: column;
        padding: 30px 0;
    }
}

.container-inline__item .container-wrapper {
    width: auto;
}

.container-account-wrapper {
    .content-width-wrapper--desktop {
        .content-width-wrapper--holder {
            display: block;

            @include mq($from: tablet) {
                display: grid;
                grid-template-columns: 21% 1fr;
                grid-gap: 30px;
            }
        }
    }
}
