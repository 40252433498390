@import '~sass-mq/mq';

.dynamic-fieldset {
    display: block;
}

.dynamic-fieldset__title {
    margin-bottom: 10px;
    margin-left: 4px;
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.dynamic-fieldset__fields {
    display: grid;
    align-items: flex-start;
    justify-content: stretch;
    gap: 15px;
    grid-template-columns: auto;
    grid-auto-flow: row;
    margin-bottom: 15px;
}

.dynamic-fieldset__fields--cols-2 {
    grid-template-columns: 1fr 1fr;
}

.dynamic-fieldset__fields--cols-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-1 {
    grid-template-columns: 1fr;
}

.dynamic-fieldset__fields--cols-mobile-2 {
    grid-template-columns: 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@include mq($from: tablet) {
    .dynamic-fieldset__fields--cols-tablet-1 {
        grid-template-columns: 1fr;
    }

    .dynamic-fieldset__fields--cols-tablet-2 {
        grid-template-columns: 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-tablet-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-tablet-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-tablet-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@include mq($from: desktop) {
    .dynamic-fieldset__fields--cols-desktop-1 {
        grid-template-columns: 1fr;
    }

    .dynamic-fieldset__fields--cols-desktop-2 {
        grid-template-columns: 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-desktop-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-desktop-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .dynamic-fieldset__fields--cols-desktop-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}
