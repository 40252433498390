.table {
    padding: 50px 0;
    width: 100%;
    margin: 0 auto;
}

.table__wrapper {
    position: relative;
}

.table__table {
    white-space: nowrap;
    border-spacing: 0;
}

.table__scroller {
    overflow: auto;
    width: var(--max-width);
    margin: 0 auto;

    @include mq($from: tablet) {
        width: auto;
    }
}

.table__background--default {
    --cell-bg: var(--bg-table-cell-default);
    --cell-border: var(--border-table-cell-default);
    --color-text: var(--color-table-cell-default);
    --color-headline: var(--color-headline-table-cell-default);
}

.table__background--primary {
    --cell-bg: var(--bg-table-cell-primary);
    --cell-border: var(--border-table-cell-primary);
    --color-text: var(--color-table-cell-primary);
    --color-headline: var(--color-headline-table-cell-primary);
}

.table__background--secondary {
    --cell-bg: var(--bg-table-cell-secondary);
    --cell-border: var(--border-table-cell-secondary);
    --color-text: var(--color-table-cell-secondary);
    --color-headline: var(--color-headline-table-cell-secondary);
}

.table__background--tertiary {
    --cell-bg: var(--bg-table-cell-tertiary);
    --cell-border: var(--border-table-cell-tertiary);
    --color-text: var(--color-table-cell-tertiary);
    --color-headline: var(--color-headline-table-cell-tertiary);
}

.table__table-body-row:nth-child(even) {
    .table__table-cell {
        background-color: var(--cell-bg);
    }
}

.table__table-body-row:nth-child(odd) {
    .table__table-cell {
        background-color: var(--color-white-true);
    }
}

.table__table-cell {
    border-right: 1px solid var(--cell-border);
    min-width: 120px;
    text-align: center;
    @include mq($from: tablet) {
        min-width: 170px;
    }
}

.table__table-head-cell {
    background-color: var(--cell-bg);
    color: var(--color-headline);
    position: sticky;
    top: 0;
}

.table__table-cell:last-child {
    border-right: 1px solid transparent;
}

.table__table-cell:first-child {
    color: var(--color-headline);
    position: sticky;
    left: 0;
    z-index: var(--zi-table-cell-first-child);
    font-weight: var(--fw-semibold);
}

.table__table-cell-shadow::after,
.table__table-head-cell-shadow::after {
    box-shadow: inset 11px 0 12px -15px #111;
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    right: -16px;
    width: 15px;
}
