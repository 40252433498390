.polaroid-collection__headlines {
    padding: 20px;
    text-align: left;
    @include mq($from: tablet) {
        text-align: center;
    }
}

.polaroid-collection__content-container {
    @include mq($from: tablet) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100px, auto);
        grid-gap: 60px;
        padding-top: 60px;
    }
}
