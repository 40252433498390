.subscription-management__headlines {
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--theme-primary);
    font-family: var(--ff-menu);
    font-weight: var(--fw-semibold);
}

.subscription-management__loading-indicator {
    margin-top: 20px;
}
