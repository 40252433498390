.bundle__title {
    font-size: 16px;
    color: var(--color-gray-800);
    font-weight: var(--fw-semibold);
    padding: 8px 0 13px;
}

.bundle__item-wrapper {
    border: 1px solid var(--color-gray-80);
    border-radius: var(--border-radius-default);
    overflow: hidden;
}
