/* stylelint-disable declaration-no-important */
/* stylelint-disable max-nesting-depth */
.product {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius-default);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    .loading-indicator::after {
        height: 14px;
        width: 14px;
    }
}

.product:has(.catalog-badge--aktion, .catalog-badge--sparset, .catalog-badge--sale) {
    border: 2px solid var(--color-red);
    border-radius: var(--border-radius-product-card);
}

.product__product-top {
    position: relative;
}

.badge {
    border-radius: var(--border-radius-default);
    padding: 0 0.75rem;
    font-size: 12px;

    &::after {
        clip-path: unset;
        background: none;
    }
}

.price-colored-gratis {
    color: var(--bg-badge-highlight);
}

.price-colored-xplusy {
    color: var(--color-badge-xplusy-background);
}

.price-colored-savings,
.price-colored-savings-pdp {
    color: var(--color-red) !important;
}

.price-colored-ratensavings {
    color: var(--color-badge-savings-text);
}
