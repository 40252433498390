.footer-text__main {
    background: var(--footer-background-color);
}

.footer-text__wrapper {
    color: var(--footer-font-color);
    padding: 30px 20px 20px;

    @include mq($from: desktop) {
        padding: 50px 20px 20px;
        display: flex;
        gap: 19px;
        flex-direction: row;
    }
}
