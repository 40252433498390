.special-button {
    text-align: center;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        height: 40px;
        font-size: var(--fs-18);
        font-weight: var(--fw-medium);
        background: #222;
        color: var(--special-button-font-color);
        padding: 7px 18px;
        border-radius: var(--border-radius-button);
        border: 1px solid var(--special-button-border-color);

        &:hover {
            border-color: var(--special-button-border-color-hover);
            color: var(--special-button-font-color);
        }
    }
}
