.mpc-order-overview {
    margin: 30px 10px 40px;
}

.mpc-order-overview__summary-line {
    display: grid;
    grid-template-columns: auto 140px;
    margin: 15px 0;
    width: 100%;

    .mpc-order-overview__label {
        text-align: left;
        hyphens: auto;
    }

    .mpc-order-overview__value {
        font-family: var(--font-base);
        text-align: right;
    }

    .voucher-row__label {
        font-weight: var(--fw-normal);
    }

    .voucher-row__value {
        font-weight: var(--fw-normal);
    }
}

.mpc-order-overview__subtotal {
    font-weight: var(--fw-bold);

    .mpc-order-overview__label,
    .mpc-order-overview__value {
        font-weight: var(--fw-bold);
    }
}

.mpc-order-overview__total {
    padding-top: 15px;
    word-break: break-word;
    border-top: 2px solid var(--color-borders-checkout);

    .mpc-order-overview__total-label {
        font-weight: var(--fw-bold);
        font-size: var(--fs-l);
        display: inline-block;
        margin-right: 1ch;
    }

    .mpc-order-overview__tax-label {
        font-size: var(--fs-s);
        color: #999;
        white-space: nowrap;
    }

    .mpc-order-overview__total-price {
        font-size: var(--fs-l);
        font-weight: var(--fw-bold);
        color: var(--theme-primary);
    }
}
