.product-description__title {
    margin-bottom: 4px;

    @include mq($until: tablet) {
        word-break: break-word;
    }

    &.product-description__title--default {
        color: var(--color-product-title);
    }

    a {
        font-family: var(--font-base);
        font-weight: var(--fw-semibold);
        color: var(--color-product-title);
        font-size: var(--fs-product-title-desktop);
        line-height: var(--lh-small);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    &--lines-3 a {
        -webkit-line-clamp: 3;
    }

    &--lines-4 a {
        -webkit-line-clamp: 4;
    }
}

.product-description__description {
    height: auto;
    font-size: var(--fs-product-description-mobile);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: 8px;
    font-family: var(--ff-base);

    &--lines-3 {
        -webkit-line-clamp: 3;
    }

    &--lines-4 {
        -webkit-line-clamp: 4;
    }

    &--lines-5 {
        -webkit-line-clamp: 5;
    }

    @include mq($from: tablet) {
        word-break: unset;
        font-size: var(--fs-product-description-desktop);
    }

    p {
        margin: 0;
    }
}
