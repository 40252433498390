.container-account__content-wrapper {
    .content-width-wrapper--holder {
        display: block;

        @include mq($from: tablet) {
            display: grid;
            grid-template-areas:
                '. header'
                'navigation content';
            grid-template-columns: 21% 1fr;
            grid-gap: 30px;
        }
    }
}

.container-account__account-header {
    grid-area: header;
}
