.desktop-navigation {
    height: 100%;
    position: relative;
}

.desktop-navigation__list {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: initial;
    list-style: none;
    margin: 0;
    padding: 0;

    li:last-child {
        position: relative;

        .desktop-sub-navigation {
            min-width: 100%;
        }
    }
}

body:has(.desktop-navigation--hover) {
    &::after {
        opacity: 1;
        pointer-events: all;
    }
}
