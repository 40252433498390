.wko-upsell-product {
    position: relative;
    display: grid;
    grid-template-areas:
        'productImage productDetail'
        'productImage orderInfo';
    grid-template-columns: 25% auto;
    grid-gap: 15px;
    padding: 10px 10px 16px;
    margin: 0 20px 26px;
    border: 1px solid var(--color-gray-80);
    border-radius: var(--border-radius-default);
    background: var(--color-white-true);
    width: 100%;

    @include mq($from: tablet) {
        background: var(--color-white-true);
        padding: 16px;
        margin: 0 0 26px;
    }

    .wko-upsell-product__badge {
        display: none;
        position: absolute;
        top: 50px;
        left: 0;
    }
}

.wko-upsell-product__image-container {
    border-radius: var(--border-radius-default);
    grid-area: productImage;
    overflow: hidden;
    aspect-ratio: 1/1;
    display: flex;
    align-items: stretch;
}

.wko-upsell-product__image {
    object-fit: cover;
}

.wko-upsell-product__details {
    grid-area: productDetail;
    margin-right: 12px;
    font-size: var(--fs-s);
}

.wko-upsell-product__order-info {
    grid-area: orderInfo;
    align-self: end;
}

.wko-upsell-product__detail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 10px 5px 0;
}

.wko-upsell-product__title {
    font-size: 16px;
    color: var(--theme-primary);
    font-weight: 700;
}

.wko-upsell-product-description__preview--oversized {
    overflow-y: hidden;
}

.wko-upsell-product__price-info-container {
    display: flex;
    justify-content: space-between;

    &--sale {
        color: var(--color-red);
    }

    @include mq($until: tablet) {
        display: block;
    }
}

.wko-upsell-product__price {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mq($until: 440px) {
        flex-direction: column;
        align-items: baseline;
        gap: 8px;
    }

    .price-box__old-price {
        color: var(--color-black-true);
        margin-bottom: 10px;
    }

    .price-box__price {
        color: var(--pre-checkout-product-price);
        font-size: 25px;
    }
}

.wko-wpsell-product__promotion {
    margin-bottom: 12px;
    padding: 3px 16px;
    border-radius: var(--border-radius-precheckout);
    font-weight: 600;
    font-size: 13px;
    display: none;

    @include mq($from: tablet) {
        display: block;
    }
}

.wko-upsell-product__badge-wrapper {
    display: none;
    width: 100%;
    position: absolute;

    @include mq($from: desktop) {
        display: flex;
    }
}

.wko-upsell-product__badge-container--top {
    display: flex;
    flex-direction: row;
    width: inherit;
    position: absolute;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;

    :first-child {
        border-radius: var(--border-radius-default) 0 0 0;
    }

    :nth-child(2) {
        border-radius: 0 var(--border-radius-default) 0 0;
    }
}

.wko-upsell-product__cta-btn-cart {
    border-radius: var(--border-radius-default);
    padding: 11px;
    font-size: 18px;
    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);
    max-height: 45px;
    align-self: end;
    min-width: 140px;

    @include mq($until: 440px) {
        padding: 8px 4px;
        font-size: var(--fs-s);
        align-self: baseline;
    }
}

.wko-upsell-product__product-details__usps {
    margin-bottom: 8px;

    ::before {
        border-radius: 50%;
        height: 6px;
        width: 6px;
        margin-top: 2px;
    }
}

.wko-upsell-product__product-details__wrapper {
    display: flex;
    flex-direction: column;
}

.wko-upsell-product__product-details__claim,
.wko-upsell-product__product-details__deliverables {
    margin-bottom: 8px;
}

.wko-upsell-product__product-details__deliverables-text {
    font-weight: var(--fw-semibold);
}

.wko-upsell-product__product-details__cta-btn {
    border-radius: var(--border-radius-default);
    margin: 8px 0 12px;
    padding: 11px;
    width: 100%;
    font-size: 18px;
    align-self: center;

    text-transform: unset;
    border: 1px solid var(--theme-primary);
    background: var(--color-white-true);
    color: var(--theme-primary);

    @include mq($until: desktop) {
        width: 50%;
        align-self: baseline;
        font-size: var(--fs-s);
        padding: 8px 4px;
        min-width: 140px;
    }
}

.wko-upsell-product__product-details__rating {
    align-self: baseline;
    margin-bottom: 8px;
    font-size: 16px;

    .wko-upsell-product__product-details__review-count {
        font-size: 12px;
    }
}
