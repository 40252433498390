.dynamic-textarea {
    margin-bottom: 24px;
    width: 100%;
    height: 10em;
}

.dynamic-textarea__textarea {
    width: 100%;
    height: 100%;
    resize: none;
    padding: 8px;
}
