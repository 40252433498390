.sticky-button {
    position: fixed;
    z-index: var(--zi-sticky-button);
    bottom: 0;
    height: 70px;
    left: 0;
    right: 0;
}

.sticky-button--top {
    top: 0;
}

.sticky-button--bottom {
    bottom: 0;
}

// this here bc .sticky-button position: sticky is not working
.sticky-button__dummy {
    height: 70px;
}

.sticky-button__content {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.sticky-button__column-left {
    display: none;

    @include mq($from: tablet) {
        display: flex;
    }
}

.sticky-button__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.sticky-button__headline-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sticky-button__headline {
    text-align: start;
    padding: 0;
    line-height: 1.3;
}

.sticky-button__column-right {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 10px;

    @include mq($from: tablet) {
        width: auto;
        justify-content: flex-start;
    }
}

.sticky-button__price-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.sticky-button__original-price {
    font-size: var(--fs-xs);
}

.sticky-button__price {
    font-size: var(--fs-xl);
    font-weight: var(--fw-bold);
    margin-top: -8px;
    margin-bottom: -4px;
}

.sticky-button__savings {
    color: var(--theme-tertiary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-s);
}

.sticky-button__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
