.accordion {
    padding: 20px 0 30px;

    &:has(.headlines) {
        padding-top: 0;
    }
}

.accordion-item__headline-container {
    padding: 10px 0;
}

.accordion__background--primary {
    --color-border: var(--border-color-accordion-primary);
}

.accordion__background--secondary {
    --color-border: var(--border-color-accordion-secondary);
}

.accordion__background--tertiary {
    --color-border: var(--border-color-accordion-tertiary);
}

.accordion__background--default {
    --color-border: var(--border-color-accordion-default);
}
