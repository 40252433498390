.component-details-description__text {
    font-size: var(--fs-base);
    color: var(--color-black-true);
    margin-block-start: 5px;
}

.component-details-description__headlines {
    color: var(--color-headline);
}

.component-details-description__container-number {
    background: var(--bg-component-description-box);
    color: var(--color-component-description-box);
    font-size: var(--fs-l);
    font-weight: var(--fw-medium);
    margin: 0 10px 42px 0;
    height: 30px;
    text-align: center;
    min-width: 30px;
}

.component-details-description__article--disabled {
    opacity: 0.5;
}

.component-details-description__article--active {
    background-color: var(--theme-tertiary);
}

.component-details-description__article {
    transition: 0.3s;
    display: flex;
    padding: 8px;
    margin: 8px 0;
    cursor: pointer;
}
