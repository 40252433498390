.checkout-payment {
    padding-bottom: 8px;
}

.checkout-payment__hint {
    padding: 10px 0 22px;
    white-space: pre-wrap;
}

.checkout-payment__submit {
    margin-bottom: 20px;
}

.checkout-payment__required-hint {
    margin: 5px 0 20px;
    font-size: var(--fs-s);
    color: var(--color-gray-500);
}
