.phone-number-input__phone-input-holder {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
}

.phone-number-input__country-code-select > select {
    border: 1px solid var(--border-input);
    font-size: var(--fs-sm);
}
