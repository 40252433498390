.account-header {
    @include mq($until: tablet) {
        padding: 20px;
    }
}

.account-header__customer-name {
    font-family: var(--ff-menu);
    line-height: var(--lh-small);
    font-size: 26px;
    font-weight: 500;
}

.account-header__skeleton {
    height: 1lh;
    width: 50%;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: var(--color-gray-400);
    }

    100% {
        background-color: var(--color-gray-300);
    }
}
