.content-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.content-navigation__item-wrap {
    &::-webkit-scrollbar {
        display: none; /* Webkit */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    flex-wrap: nowrap;
    font-family: var(--font-base);
    font-size: 15px;
    font-weight: var(--fw-normal);
    position: relative;
    scroll-behavior: smooth;
    overflow-x: scroll;
    touch-action: pan-x;
}

.content-navigation__background-color-wrapper {
    height: 42px;
    border-bottom: 2px solid var(--content-navigation-line);
    position: relative;
    z-index: 3;
    width: 100%;
    top: 0;
}

.content-navigation__item {
    padding: 0 12px;
    color: var(--content-navigation-font-color);
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            background: var(--content-navigation-line);
        }
    }
}

.content-navigation__item-link {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    padding: 10px 0 8px;
    white-space: nowrap;
    border-bottom: 2px solid transparent;

    &--active {
        color: var(--content-navigation-cta);
        border-bottom-color: var(--content-navigation-cta);
    }

    svg {
        font-size: 12px;
    }
}

.content-navigation__arrow {
    position: absolute;
    z-index: 100;
    top: 0;
    height: 42px;
    width: 39px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--content-navigation-bg);
    cursor: pointer;
    user-select: none;
    color: var(--content-navigation-cta);
    border-bottom: 2px solid var(--content-navigation-line);

    &:hover {
        background: var(--content-navigation-line);
    }

    &--hidden {
        display: none;
    }

    &-left {
        left: 0;
    }

    &-right {
        right: 0;
    }
}
