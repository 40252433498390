.accordion-pdp-item__column {
    width: 100%;
    border-bottom: 1px solid var(--color-gray-80);
    padding: 16px;
}

.accordion-pdp-item__heading {
    font-size: var(--fs-accordionpdp-title);
    cursor: pointer;
}

.attributes-item {
    border-collapse: collapse;
    @include mq($from: tablet) {
        display: flex;
        flex-direction: row;
        padding: 16px 72px;
    }
}

.attributes-item__row:nth-child(odd) {
    background-color: var(--color-gray-300);
}

.attributes-item__data-label {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
    word-wrap: break-word;
}

.attributes-item__data-value {
    text-align: left;
    padding: 8px;
    width: 50%;
    overflow: hidden;
    word-wrap: break-word;
}

.accordion-pdp-item__item {
    padding: 20px;
}

.attributes-item table {
    width: 100%;
    table-layout: fixed;

    @include mq($from: tablet) {
        width: 50%;
        table-layout: auto;
    }
}

.attributes-item__textile-care-symbols {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: var(--color-gray-900);

    div {
        display: flex;
        gap: 8px;
        align-items: center;

        img {
            height: 48px;
        }
    }
}
