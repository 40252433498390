.checkout-addresses-read-only {
    margin-bottom: 20px;
}

.checkout-addresses-read-only__headline {
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
}

.checkout-addresses-read-only__edit-button {
    padding: 10px;
}
