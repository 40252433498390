.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip__text {
    visibility: hidden;
    background-color: var(--color-black);
    color: var(--color-white);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -40px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip__text {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltip__text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-black) transparent transparent transparent;
}
