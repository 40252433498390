.burger-menu {
    display: none;

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--main-navigation-custom-background-color);
    border-top: 1px solid var(--main-navigation-custom-separator-color);

    @include mq($from: tablet, $until: desktop) {
        max-width: calc(100% / 3 * 2);
    }

    @include mq($from: desktop) {
        max-width: 430px;
        right: 0;
        left: auto;
    }

    &--open {
        display: block;
    }

    .special-button {
        padding-bottom: 14px;
    }
}

.burger-menu__inner-wrapper {
    padding: 14px;
}

body:has(.burger-menu--open) {
    &::after {
        opacity: 1;
        pointer-events: all;
    }
}

.burger-menu--open:has(.country-select-container--open) {
    &::after {
        position: absolute;
        content: '';
        inset: 0 -1px -1px;
        background: rgba(0, 0, 0, 0.6);
        opacity: 1;
    }
}
