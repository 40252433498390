body:has(.sticky-add-to-cart-banner) {
    margin-bottom: 104px;

    @include mq($from: tablet) {
        margin-bottom: 86px;
    }
}

.sticky-add-to-cart-banner {
    position: fixed;
    left: 0;
    right: 0;
    z-index: var(--zi-sticky-bar);
    background: var(--color-white-true);
    transition: all 0.3s ease-out;
    bottom: -105px;

    @include mq($from: tablet) {
        bottom: -87px;
    }

    &--active {
        bottom: 0;
        box-shadow: 0 -2px 20px var(--color-gray-900-20);
    }
}

.sticky-add-to-cart-banner__wrapper {
    height: 104px;
    padding: 10px 20px;

    @include mq($from: tablet) {
        display: grid;
        place-items: center;
        height: 86px;
        grid-template-areas: 'image description button';
        grid-template-columns: 58px auto 330px;
    }
}

.sticky-add-to-cart-banner__image {
    display: none;
    grid-area: image;

    @include mq($from: tablet) {
        display: block;
    }

    img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
        padding: 1px;
        border: 1px solid var(--color-gray-80);
    }
}

.sticky-add-to-cart-banner__content {
    grid-area: description;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 8px;

    @include mq($from: tablet) {
        flex-direction: column;
        padding: 0 40px 0 18px;
        justify-content: normal;
        gap: 0;
        margin-bottom: 0;
    }
}

.sticky-add-to-cart-banner__title-wrapper {
    @include mq($until: tablet) {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
    }
}

.sticky-add-to-cart-banner__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-size: 12px;
    color: #777;
    font-weight: var(--fw-normal);

    @include mq($from: tablet) {
        -webkit-line-clamp: 1;
        font-size: 16px;
        font-weight: var(--fw-light);
    }
}

.sticky-add-to-cart-banner__price {
    font-family: var(--font-product-price);
    font-size: 20px;
    font-weight: var(--fw-semibold);

    &--sale {
        color: var(--color-red);
    }
}

.sticky-add-to-cart-banner__button-wrapper {
    grid-area: button;
    width: 100%;
}

.sticky-add-to-cart-banner__buy-button {
    background: var(--pdp-background-buy-button);
    border-radius: var(--border-radius-default);

    .select-button__button {
        display: flex;
        font-size: 16px;
        height: 38px;
        color: var(--pdp-color-buy-button);
        text-transform: none;

        @include mq($from: tablet) {
            height: 46px;
            font-size: 20px;
        }
    }
}
