.order-overview-images {
    display: flex;
    gap: 8px;

    @include mq($until: tablet) {
        display: none;
    }
}

.order-overview-images__image {
    max-width: 120px;
    border: 2px solid var(--theme-primary);
}

.order-overview-images__line-extras {
    position: relative;
}

.order-overview-images__line-overlay {
    position: absolute;
    background-color: rgba(110, 110, 110, 0.7);
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 7px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
}
