.accordion-item {
    border-top: 1px solid;
    border-bottom: 0;

    &:last-child {
        border-bottom: 1px solid;
    }
}

.accordion-item__image {
    margin-bottom: 20px;
    width: auto;
}

.accordion-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
}

.accordion-item__header-text {
    display: flex;
    place-items: center center;
}

.accordion-item__icon-container {
    flex-shrink: 0;
    position: relative;
    height: 50px;
    width: 50px;
    margin-right: 20px;

    .accordion-item__icon {
        position: relative;
        color: currentcolor;
    }
}

.accordion-item__arrow {
    color: var(--color-text);
    margin-left: 20px;
}

.accordion-item__content {
    padding: 0 20px 20px;
}

.accordion-item__content--has-icon {
    padding: 20px 100px;

    @include mq($until: tablet) {
        padding: 20px 15px;
    }
}

.accordion-item__content--closed {
    display: none;
}

.accordion-item__headlines {
    text-align: start;
}

.accordion-item__html {
    p {
        margin-top: 0;
    }

    a {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: inherit;
    }

    li {
        margin-left: 0;
        margin-bottom: 4px;
    }
}
