.live-tv-button {
    display: none;

    @include mq($from: desktop) {
        display: block;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 117px;
        padding: 8px;
        font-size: var(--fs-18);
        font-weight: var(--fw-medium);
        background: var(--color-red);
        color: var(--live-tv-button-font-color);
        border-radius: var(--border-radius-button);
        border: 1px solid var(--color-red);
        gap: 8px;

        &:hover {
            color: var(--live-tv-button-font-color);
        }
    }
}

.live-tv-button__dot {
    width: 16px;
    height: 16px;
    border-radius: var(--border-radius-max);
    background: var(--color-white-true);
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    }

    100% {
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    }
}
