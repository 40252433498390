.cta__container {
    padding: 15px 10px;

    @include mq($until: tablet) {
        padding: 10px;
    }
}

.cta__headlines {
    padding-top: 0;
}

.cta__headlines .headlines__headline {
    font-size: 2rem;

    @include mq($until: tablet) {
        font-size: var(--fs-h1-mobile);
    }
}

.cta__content-container {
    display: grid;
    justify-content: center;
}

.cta__text-container {
    text-align: center;

    p {
        margin-top: 0;
    }
}

.cta__button-primary-container {
    text-align: center;
}

.cta__button-primary {
    padding: 8px 16px;
}

.cta__button-secondary-container {
    margin-top: 10px;
    text-align: center;
    --td-links: underline;
}

.cta__button-secondary {
    font-weight: var(--fw-links);
    text-decoration: var(--td-links);
    white-space: nowrap;
    color: var(--color-highlight);
}
