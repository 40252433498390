.pre-checkout-message {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include mq($from: tablet) {
        flex-direction: column;
    }
}

.pre-checkout-message__success {
    color: var(--theme-success);
}

.pre-checkout-message__error {
    color: var(--theme-error);
}

.pre-checkout-message__icon-wrapper {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($from: tablet) {
        width: 75px;
        height: 75px;
    }
}

.pre-checkout-message__check-icon-wrapper {
    background-color: rgba(56, 170, 3, 0.2);
}

.pre-checkout-message__x-icon-wrapper {
    background-color: rgba(170, 3, 3, 0.2);
}

.pre-checkout-message__icon {
    font-size: 40px;
    padding: 10px 5px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.pre-checkout-message__message {
    margin: 0 10px;
    font-weight: var(--fw-bold);
    font-size: 12px;

    @include mq($from: tablet) {
        margin: 20px 0;
        font-size: 15px;
    }
}
