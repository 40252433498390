.splp-cart__progress-bar-container-margin {
    margin-bottom: 16px;

    @include mq($from: tablet) {
        margin-bottom: 26px;
    }
}

.splp-cart__progress-bar-container {
    padding: 17px 15px;
    border: 1px solid var(--color-gray-80);
    border-radius: var(--border-radius-default);
    background: var(--color-white-true);
    min-width: 230px;
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;

    @include mq($from: tablet) {
        margin-left: 0;
        margin-right: 0;
    }

    @include mq($until: tablet) {
        font-size: var(--fs-s);
    }

    .splp-cart__progress-bar-header {
        text-align: center;
        margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
        margin-bottom: 10px;

        .splp-cart__progress-bar-price-difference {
            color: var(--theme-primary);
            font-weight: 700;
        }
    }

    .splp-cart__progress-bar {
        text-align: center;
        width: 100%;
        height: 28px;
        overflow: hidden;
        background-color: var(--bg-shipping-cost-bar);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .splp-cart__text {
            color: var(--color-white-true);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 1;
            min-width: fit-content;
            gap: 5px;
            cursor: pointer;

            a {
                color: var(--color-white-true);
            }

            p {
                margin: 0;
            }

            .splp-cart__price {
                font-weight: 800;
                font-size: var(--fs-18);
                margin-left: auto;
            }
        }

        .splp-cart__progress {
            width: 0%;
            height: 100%;
            background-color: var(--theme-primary);
            transition: width 0.3s ease;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: flex;
            justify-content: flex-end;
            position: relative;

            @include mq($from: tablet) {
                cursor: pointer;
            }
        }

        .splp-cart__arrow {
            position: absolute;
            top: 0;
            height: 100%;
            width: 20px;
            transition: left 0.3s ease;
            transform: scale(1.4);
            right: -7px;

            @include mq($from: tablet) {
                cursor: pointer;
            }

            .splp-cart__polygon {
                fill: var(--theme-primary);
                z-index: 0;
            }
        }

        .splp-cart__progress-bar-goal {
            color: var(--theme-primary);
            font-weight: 800;
            font-size: var(--fs-18);
            padding-left: 15px;
            padding-right: 8px;
        }
    }
}

.splp-cart__bold {
    font-weight: 700;
}

.splp-cart__text-green {
    color: var(--theme-success);
}

.splp-cart__underline {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}
