.text {
    padding: 20px;

    a {
        text-decoration: none;
        border-bottom: 1px solid;
        overflow-wrap: break-word;
        color: var(--color-text);
    }

    a:hover {
        cursor: pointer;
    }

    .headlines--padding {
        padding: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.text--align-left {
    text-align: left;
}

.text--align-right {
    text-align: right;
}

.text--align-center {
    text-align: center;
}

.text--align-justify-left,
.text--align-justify-right,
.text--align-justify-center,
.text--align-justify {
    text-align: justify;
}

.text--no-bottom-padding {
    padding-bottom: 0;
}
