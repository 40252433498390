.desktop-menu-item {
    &:hover {
        .desktop-menu-item__menu-item {
            color: var(--menu-color-primary-hover);
            border-bottom-color: var(--menu-color-primary-hover);
        }
    }
}

.desktop-menu-item__menu-item {
    display: block;
    height: 68px;
    padding: 20px 0;
    margin: 0 10px;
    text-align: center;
    color: var(--menu-color-primary);
    font-family: var(--ff-menu);
    font-size: var(--fs-main-navigation-desktop);
    font-weight: var(--fw-semibold);
    font-feature-settings: 'liga' off;
    border-bottom: 2px solid transparent;

    &--active {
        color: var(--menu-color-primary-hover);
        border-bottom-color: var(--menu-color-primary-hover);
    }
}

.desktop-menu-item__submenu {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% - 16px);
    left: 0;
    padding: 16px;
    margin-left: 0;
    background: var(--color-white-true);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    z-index: 999;
    grid-template:
        'subCatOne subCatTwo subCatThree subImage'
        '. . . subImage'
        '. . . subImage'
        '. . . subImage'
        '. . . subImage';
    grid-gap: 10px;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
}

.desktop-menu-item__submenu-item {
    list-style-type: none;
    margin: 0;
    padding: 10px;
}

.desktop-menu-item__submenu-item-link {
    color: var(--menu-color-primary-hover);
    font-family: var(--ff-menu);
    font-size: var(--fs-sub-navigation-desktop);
    font-weight: var(--fw-semibold);
}

@media (hover: hover) {
    .desktop-menu-item:hover .desktop-menu-item__submenu {
        display: grid;
    }
}
