.burger-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    cursor: pointer;
    user-select: none;
    font-size: 32px;
    width: 45px;

    @include mq($from: desktop) {
        font-size: var(--fs-sm);
        height: 34px;
        width: auto;
        padding: 8px 12px;
        gap: 8px;
        border-radius: 16px;
        background: var(--color-gray-300);
    }

    svg {
        font-size: 22px;

        @include mq($from: desktop) {
            font-size: 14px;
        }
    }
}

.burger-menu-button__text {
    display: none;

    font-weight: var(--fw-medium);

    @include mq($from: desktop) {
        display: block;
    }
}
