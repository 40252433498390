.bundle-item {
    cursor: pointer;
    padding: 0 12px;

    &:not(:last-child) .bundle-item__inner-wrapper {
        border-bottom: 1px solid #ebebeb;
    }

    &:hover {
        background: var(--color-gray-250);

        .bundle-item__icon {
            color: var(--theme-primary);
        }
    }
}

.bundle-item__inner-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px 0;
}

.bundle-item__image-container {
    img {
        vertical-align: bottom;
    }
}

.bundle-item__info-container {
    flex: 1;
}

.bundle-item__title {
    font-family: var(--font-base);
    font-weight: var(--fw-semibold);
    color: var(--color-product-title);
    font-size: var(--fs-product-title-desktop);
    margin-bottom: 4px;

    @include mq($until: 420px) {
        word-break: break-word;
        hyphens: auto;
    }
}

.bundle-item__claim {
    font-size: var(--fs-product-description-desktop);
    margin-bottom: 6px;
}

.bundle-item__price {
    font-weight: var(--fw-semibold);
    margin-right: 8px;

    &--saving {
        color: var(--color-red);
    }
}


.bundle-item__icon {
    display: none;
    font-size: 18px;
    color: var(--color-gray-500);
    align-self: center;

    @include mq($from: tablet) {
        display: block;
    }
}
