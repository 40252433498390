.download-with-image-and-text {
    padding: 20px 0;

    @include mq($from: tablet) {
        padding: 40px 0;
    }
}

.download-with-image-and-text__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.download-with-image-and-text__slide {
    height: 100%;
    min-width: 200px;
}
