.splp-navigation {
    padding: 20px;
    @include mq($from: tablet) {
        text-align: center;
    }
}

.splp-navigation__wrapper {
    &::-webkit-scrollbar {
        display: none; /* Webkit */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    overflow: scroll;
}

.splp-navigation__logo {
    display: block;
    padding: 10px;

    &:focus {
        outline: none;
    }
}

.splp-navigation__logo-image {
    max-height: 75px;
    width: auto;
    margin: 0 auto;
}

.splp-navigation__navigation-bar {
    &::-webkit-scrollbar {
        display: none; /* Webkit */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    display: block;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    overflow: scroll;
    width: max-content;

    @include mq($from: desktop) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: initial;
    }
}
