.product-details-item__text-container {
    @include mq($from: tablet) {
        padding: 16px 72px;
    }

    > * {
        margin: 0 0 16px;
    }

    ul {
        margin-left: 20px;
    }
}

.product-details-item__text-container a {
    color: var(--color-black);
    text-decoration: none;
    border-bottom: 1px solid;
}
