.account-navigation {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }

    .headlines {
        text-align: left;
        padding-left: 0;

        @include mq($from: tablet) {
            border-bottom: 1px solid var(--theme-primary);
        }
    }
}

.account-navigation__logout-text {
    text-align: center;
}

.account-navigation__logout {
    margin: 0 auto;
    display: block;
    width: 100%;
    border-radius: var(--border-radius-default);
}

.account-navigation__logout-wrapper {
    text-align: center;
}

.account-navigation__attention-icon {
    font-size: 40px;
    color: var(--theme-tertiary);
}

.account-navigation__attention-wrapper {
    padding: 20px 25px;
    border-radius: var(--border-radius-default);
    display: inline-block;
    background: var(--theme-primary);
}

.account-navigation__logout-header {
    margin-top: 15px;
}

.account-navigation__items {
    list-style: none;
    margin: 0;
}

.account-navigation__menu-item {
    padding-left: 0;
    border-top: 1px solid var(--theme-primary);

    @include mq($from: tablet) {
        border-top: 0 solid var(--theme-primary);
        margin: 0;
    }

    &:last-of-type {
        border-bottom: 1px solid var(--theme-primary);

        @include mq($from: tablet) {
            border-bottom: 0 solid var(--theme-primary);
        }
    }

    &::before {
        list-style-type: none;
        background: transparent;
        position: absolute;
        right: 0;
        left: auto;
        top: 20px;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.1em solid var(--theme-primary);
        border-top: 0.1em solid var(--theme-primary);
        margin-right: 0.5em;
        font-size: 1.8em;
        color: var(--theme-primary);
        transform: rotate(45deg);

        @include mq($from: tablet) {
            margin-right: 0;
            width: unset;
            height: unset;
            border: none;
            position: relative;
        }
    }
}

.account-navigation__menu-item-link {
    color: var(--menu-color-primary);
    font-family: var(--ff-menu);
    font-weight: 700;
    font-size: 20px;
    display: block;
    width: 100%;
    padding-top: 14px;
    margin-bottom: 14px;

    @include mq($from: tablet) {
        font-weight: 600;
        padding-top: 0;
        margin-bottom: 0;
    }

    &:focus {
        outline: none;
    }

    &.account-navigation__menu-item-link-active {
        color: var(--menu-color-primary-hover);
    }

    &:hover {
        color: var(--menu-color-primary-hover);
    }
}
