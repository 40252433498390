.header-logo-custom,
.header-logo-custom img {
    max-width: var(--header-logo-custom-min-width-mobile-logo);
    width: 100%;

    @include mq($from: tablet) {
        max-width: var(--header-logo-custom-min-width-tablet-logo);
    }

    @include mq($from: desktop) {
        max-width: var(--header-logo-custom-min-width-desktop-logo);
    }
}

.header-logo-custom {
    img {
        max-height: 87px;
    }
}
