.text-box__content {
    position: relative;
    border: 8px solid #000;
    height: 30vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    margin: 0 5vw;

    span {
        letter-spacing: 0.3em;
        font-family: var(--fw-bold);
    }

    h4 {
        font-weight: var(--fw-bold);
    }
}

.text-box__close-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    color: var(--color-gray-800);
}
