.product-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 375px;
    padding: 0 15px 20px;

    @include mq($until: tablet) {
        max-height: 572px;
    }
}
