.account-settings__customer-number-text {
    margin-left: 10px;
}

.account-settings {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }

    .headlines {
        padding-left: 0;
        padding-right: 0;
    }

    .headlines__headline {
        text-align: left;
        padding: 20px 0;
        border-bottom: 1px solid var(--theme-primary);
        font-family: var(--ff-menu);
        font-weight: var(--fw-semibold);
    }
}

.account-settings__headlines {
    font-size: 26px;
    padding: 0;
}

.account-settings__newsletter-headlines,
.account-settings__delete-account-headlines {
    padding-bottom: 0;
}

.account-settings__button-holder {
    margin-top: 25px;

    button {
        padding: 10px 15px;
    }
}

.account-settings__newsletter-wrapper {
    padding-bottom: 20px;
}
