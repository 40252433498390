.product-details-header__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-details-header__title {
    color: var(--pdp-color-product-title);
    margin-bottom: 12px;
}

.product-details-header__subtitle {
    margin-bottom: 10px;

    h2 {
        font-size: 18px;
    }
}

.product-details-header__ratings-holder {
    display: flex;
    align-items: center;
    margin: 8px 0 16px;
    cursor: pointer;
}

.product-details-header__rating {
    margin-right: 10px;

    .star-rating__star {
        font-size: 1.5em;
    }
}

.product-details-header__review-count {
    margin-left: 8px;
    border-bottom: 1px solid var(--color-black);
}
