.cart-addon-product {
    --color-text: var(--color-cart-addon-product);

    position: relative;
    display: grid;
    grid-template-areas:
        'productImage productDetail'
        'orderInfo orderInfo';
    grid-gap: 15px;
    padding: 10px;
    color: var(--color-cart-addon-product);
    background: var(--bg-cart-addon-product);

    @include mq($from: tablet) {
        grid-template-columns: 80px auto auto;
        grid-template-areas: 'productImage productDetail orderInfo';
        padding: 20px;
        background: var(--bg-cart-addon-product);
    }
}

.cart-addon-product__image-container {
    grid-area: productImage;
}

.cart-addon-product__detail {
    grid-area: productDetail;
}

.cart-addon-product__order-info {
    grid-area: orderInfo;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: 'addButton price';
    justify-content: space-between;
    align-items: center;

    @include mq($from: tablet) {
        grid-template-columns: auto;
        grid-template-areas:
            'price'
            'addButton';
        justify-content: end;
        align-items: flex-start;
    }
}

.cart-addon-product__price {
    --color-price-box-price: var(--color-text);
    line-height: 1;
    grid-area: price;
}

.cart-addon-product__add-button {
    grid-area: addButton;
    margin-top: 10px;

    @include mq($until: tablet) {
        margin-top: 0;
    }
}

.cart-addon-product__description {
    grid-area: description;
    --bg-li-marker: var(--color-text);

    li {
        margin-bottom: 0;
    }
}

.cart-addon-product__price-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1;
}
