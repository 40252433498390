.desktop-menu {
    display: none;

    @include mq($from: desktop) {
        display: block;

        > ul {
            display: flex;
            list-style-type: none;
            margin: 0;
            justify-content: space-between;
            align-items: center;

            > li {
                margin: 0;
                padding: 0;
                position: initial;
            }
        }
    }
}

.desktop-menu-quicklink {
    border-radius: var(--border-radius-default);
    background: var(--quicklink-button-color);
    height: 42px;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom-color: var(--menu-color-primary-hover);
    }

    > a {
        color: var(--quicklink-text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 185px;
        padding: 0 10px;
        margin: 0;
        white-space: nowrap;
    }

    &--custom-hover {
        border: none;

        &:hover {
            border-bottom: 2px solid;
        }
    }
}
