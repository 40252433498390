.content-width-wrapper {
    &--mobile {
        @include mq($until: tablet) {
            width: 100%;
            margin: 0 auto;
        }
    }

    &--tablet {
        @include mq($from: tablet) {
            width: 100%;
            max-width: var(--breakpoint-tablet);
            margin: 0 auto;
        }
    }

    &--desktop {
        @include mq($from: desktop) {
            width: 100%;
            max-width: var(--breakpoint-desktop);
            margin: 0 auto;
        }
    }

    &--wide {
        @include mq($from: wide) {
            width: 100%;
            max-width: var(--breakpoint-wide);
            margin: 0 auto;
        }
    }
}
