.customer-club-user-info {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin: 15px 0;
    position: relative;
}

.customer-club-user-info__image-container {
    margin-top: 8px;
    width: 360px;
    display: flex;
}

.customer-club-user-info__customer-name {
    position: absolute;
    bottom: 48px;
    margin-left: 10px;
}
