.product-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: var(--bg-product-card);
}

.product-card--large {
    max-width: 688px;
    max-height: 355px;
}

.product-card--small {
    max-height: 204px;
    max-width: 380px;
    margin: 0 auto;
}

.product-card--large-portrait {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-height: none;
    max-width: 345px;
}

.product-card__image-wrapper {
    position: relative;
}

.product-card__image-wrapper--small {
    padding: 15px 0;
}

.product-card__image {
    height: 100%;
    max-height: 280px;
    object-fit: contain;
}

.product-card__image--small {
    max-height: 160px;
}

.product-card__image--large-portrait {
    max-height: 200px;
}

.badge.product-card__image-sticker {
    position: absolute;
    top: 10px;
}

.product-card__detail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--color-black-true);
}

.product-card__detail-wrapper--large {
    padding: 50px 15px;
}

.product-card__detail-wrapper--small {
    padding: 15px;
}

.product-card__detail-wrapper--large-portrait {
    padding: 15px;
}

.product-card__product-name {
    font-size: var(--fs-l);
    font-weight: var(--fw-bold);
}

.product-card__rating-container {
    margin-top: 7px;
    display: flex;
    align-content: center;
    align-items: center;
}

.product-card__rating-container--large-portrait {
    margin: 7px 0 10px;
}

.product-card__rating {
    margin-right: 6px;
}

.product-card__rating-count {
    font-size: var(--fs-xs);
    line-height: 15px;
}

.product-card__price-container {
    display: flex;
    justify-content: space-between;
    margin: 4px 0 10px;
}

.product-card__price-container--small {
    margin: 8px 0 5px;
}

.product-card__price-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: normal;
    margin-top: 4px;
}

.product-card__price-left--small {
    margin: 0;
}

.product-card__price-left--large-portrait {
    margin: 4px 0 10px;
}

.product-card__savings {
    color: var(--theme-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-s);
}

.product-card__savings--small {
    margin-top: -5px;
}

.product-card__price-right {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.product-card__original-price--large {
    width: 100%;
    font-size: var(--fs-xs);
    text-align: end;
}

.product-card__original-price--small {
    width: 100%;
    font-size: var(--fs-xs);
    text-align: end;
}

.product-card__price-per-unit {
    font-size: var(--fs-xs);
    width: 100%;
    text-align: end;
}

.product-card__price {
    margin: -4px 0 -6px;
    font-size: var(--fs-xl);
    font-weight: var(--fw-bold);
    color: var(--color-price-box-price);
}
