.contact-block-item {
    margin-bottom: 8px;
    --td-links: underline;
}

.contact-block-item__value {
    font-weight: var(--fw-links);
    text-decoration: var(--td-links);
    white-space: nowrap;
    color: inherit;
}

.contact-block-item__prefix {
    font-weight: var(--fw-light);

    &::after {
        content: ' ';
    }
}

.contact-block-item__suffix {
    font-weight: var(--fw-light);

    &::before {
        content: ' ';
    }
}
