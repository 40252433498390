.tab-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-white);
    padding: 15px 5px;
    border: 1px solid var(--color-gray-400);
    cursor: pointer;
    font-size: 15px;
    border-radius: var(--border-radius-product-image-container);
    height: 100%;

    @include mq($from: desktop) {
        padding: 15px 75px;
    }
}

.tab-title:hover {
    background-color: var(--color-white-true);
}

.tab-title--active {
    color: var(--theme-primary);
    font-weight: var(--fw-semibold);
    background-color: var(--color-white-true);
    border: none;
    border-top: 4px solid var(--theme-primary);
    transition: 0.1s;
}

.tab-title__border-left {
    border-left: 1px solid var(--color-gray-400);
}

.tab-title__border-right {
    border-right: 1px solid var(--color-gray-400);
}
