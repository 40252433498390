.burger-menu-country {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #f5f5f5;
    color: #666;
    user-select: none;
}

.burger-menu-country__wrapper--clickable {
    cursor: pointer;
}
