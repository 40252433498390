.testimonial-slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    text-align: center;

    @include mq($from: desktop) {
        padding: 3em;
    }

    .testimonial-slide__image {
        padding: 0 2em;
        width: 60vw;
        height: auto;
        align-self: flex-start;
        margin: 0 auto;
    }
}

.testimonial-slide__name-container {
    margin-top: 1em;
}

.testimonial-slide__name {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-h3);
}

.testimonial-slide__position {
    font-weight: var(--fw-regular);

    &::before {
        content: ' ';

        @include mq($until: desktop) {
            content: '\a';
            white-space: pre-wrap;
        }
    }
}

.testimonial-slide__quote {
    font-style: italic;
    min-height: 80px;
}

.testimonial-slide__stars {
    margin-top: 15px;
    font-size: 24px;
}
