/* stylelint-disable max-nesting-depth */
.product-image {
    &--hover-effect-zoom {
        :hover {
            @include mq($from: desktop) {
                .product-image__image-container__image {
                    transform: scale(1.05) translateY(-2.4%);
                }
            }
        }
    }

    &--hover-effect-flip {
        :hover {
            @include mq($from: desktop) {
                .product-image__image-container__image--initial-opacity-1 {
                    opacity: 0;
                }

                .product-image__image-container__image--initial-opacity-0 {
                    opacity: 1;
                }
            }
        }
    }
}

.product-image__image-container {
    position: relative;
    height: 0;
    padding-bottom: 100%;

    &--hover-effect-flip {
        img {
            transition: opacity 400ms ease;
        }
    }
}

.product-image__image-container__image {
    position: absolute;
    object-fit: cover;
    height: 100%;
    border-radius: var(--border-radius-product-image-container);
    transform: scale(1);
    transition: transform 0.3s ease;

    &--initial-opacity-1 {
        opacity: 1;
    }

    &--initial-opacity-0 {
        opacity: 0;
    }
}

.product-image__image-container__image-rounded {
    .product-image__image {
        border-radius: var(--border-radius-default);
    }
}

.product-image__badge-wrapper {
    display: flex;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;

    .catalog-badge--corner-bottom-right {
        line-height: 15px;
        padding: 1px 10px;
        word-break: break-word;
    }
}

.product-image__badge-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.product-image__badge-container--bottom {
    margin-top: auto;
    align-items: flex-end;

    .catalog-badge {
        max-width: 75%;
    }
}

.product-image__badge-container--top {
    align-items: flex-start;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :first-child {
        border-radius: var(--border-radius-default-inner) 0 0 0;
    }

    :nth-child(2) {
        border-radius: 0 var(--border-radius-default-inner) 0 0;
    }
}
