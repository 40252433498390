.available-content-space {
    width: 100%;

    @include mq($from: desktop) {
        margin: 0 auto;

        &--width-90 {
            width: 90%;
        }

        &--width-80 {
            width: 80%;
        }

        &--width-70 {
            width: 70%;
        }

        &--width-60 {
            width: 60%;
        }

        &--width-50 {
            width: 50%;
        }

        &--width-40 {
            width: 40%;
        }
    }
}
