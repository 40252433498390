.headlines {
    text-transform: none;
}

.headlines--padding {
    padding: 20px;
}

.headlines--normalcase {
    text-transform: none;
}

.headlines--uppercase {
    text-transform: uppercase;
}

.headlines--lowercase {
    text-transform: lowercase;
}

.headlines--text-align-left {
    text-align: left;
}

.headlines--text-align-center {
    text-align: center;
}

.headlines--text-align-right {
    text-align: right;
}

.headlines--mobile-start {
    @include mq($until: tablet) {
        padding: 20px 15px;
    }
}

.headlines--tablet-start {
    @include mq($from: tablet, $until: desktop) {
        padding: 20px 15px;
    }
}

.headlines--desktop-start {
    @include mq($from: desktop) {
        padding: 20px 15px;
    }
}

.headlines__headline {
    color: var(--color-headline);
}

.headlines__headline--mobile-start,
.headlines__subheadline--mobile-start {
    @include mq($until: tablet) {
        text-align: start;
    }
}

.headlines__headline--tablet-start,
.headlines__subheadline--tablet-start {
    @include mq($from: tablet, $until: desktop) {
        text-align: start;
    }
}

.headlines__headline--desktop-start,
.headlines__subheadline--desktop-start {
    @include mq($from: desktop) {
        text-align: start;
    }
}
