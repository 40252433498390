.hero-teaser {
    overflow: hidden;
    position: relative;
    @include mq($from: tablet) {
        height: 100%;
    }
}

.hero-teaser__image-container {
    @include mq($from: tablet) {
        height: 100%;
    }
}

.hero-teaser__image {
    width: 100%;
    height: auto;

    @include mq($from: tablet) {
        border-top-left-radius: var(--border-radius-default);
        border-bottom-left-radius: var(--border-radius-default);
        height: 100%;
        object-fit: cover;
    }

    @include mq($from: desktop) {
        width: auto;
    }
}

 .hero-teaser__video {
     position: relative;
     aspect-ratio: 16/9;

     @include mq($from: desktop) {
         aspect-ratio: auto;
         position: absolute;
         inset: 0;
     }
 }

.hero-teaser__box {
    font-family: var(--ff-menu);
    background: var(--color-teaser-cta-box);
    width: 100%;
    padding: 0 15px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mq($from: tablet) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 50px;
        border-top-right-radius: var(--border-radius-wide);
        border-top-left-radius: var(--border-radius-wide);
        max-width: 350px;
    }

    .headlines {
        padding-top: 0;
    }

    .headlines__headline {
        color: var(--color-subheadline);
        margin-bottom: var(--headline-margin-bottom);
        line-height: var(--lh-small);

        @include mq($from: tablet) {
            line-height: var(--lh-small);
        }
    }

    .headlines__subheadline {
        color: var(--color-headline);
        font-size: var(--hero-teaser-box-headline-font-size-mobile);
        line-height: var(--lh-small);

        @include mq($from: tablet) {
            font-size: var(--hero-teaser-box-headline-font-size-desktop);
            line-height: var(--lh-small);
        }
    }
}

.hero-teaser__box--right {
    @include mq($from: tablet) {
        right: 5%;
        border-bottom-right-radius: var(--border-radius-wide);
    }
}

.hero-teaser__box--left {
    @include mq($from: tablet) {
        left: 5%;
        border-bottom-right-radius: var(--border-radius-wide);
    }
}

.hero-teaser__button {
    padding: 16px 40px;
    border-radius: var(--border-radius-wide);
    text-transform: initial;
    display: flex;
    margin: 0 auto;
    background: var(--hero-teaser-button-color);
    color: var(--hero-teaser-button-text-color);
    justify-content: center;
    font-weight: 600;
    letter-spacing: 1.25px;
    min-width: 170px;

    @include mq($from: tablet) {
        padding: 16px;
        width: 100%;
    }
}
