.newsletter-unsubscribe {
    text-align: center;
    padding: 30px 20px;

    @include mq($from: desktop) {
        padding: 30px 0;
    }

    &:has(.headlines) {
        padding-top: 10px;
    }
}

.newsletter-unsubscribe__message {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &--success {
        color: var(--theme-success);
    }

    &--error {
        color: var(--theme-error);
    }
}

.newsletter-unsubscribe__message-icon {
    font-size: 24px;
}
