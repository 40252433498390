.op-checkout {
    display: grid;
    grid-template:
        'divider'
        'address'
        'payment'
        'summary';
    gap: 20px;
    padding: 0 0 50px;
    align-items: flex-start;

    @include mq($from: desktop) {
        grid-template:
            'address summary'
            'payment summary' / 1fr 333px;
        padding: 50px 0;
    }
}

.op-checkout__panel {
    padding: 25px 15px 30px;
    color: var(--color-text);

    @include mq($from: tablet) {
        background: var(--bg);
    }
}

.op-checkout__headline {
    font-size: var(--fs-18);
    font-weight: var(--fw-bold);
    color: var(--color-gray-800);

    @include mq($from: tablet) {
        font-size: var(--fs-l);
    }
}

.op-checkout__header {
    color: var(--color-headline);
}

.op-checkout__shipping-headline {
    padding: 50px 0 20px;
}

.op-checkout__cart {
    grid-area: cart;
}

.op-checkout__divider {
    grid-area: divider;
    display: none;
    align-items: center;
    font-size: var(--fs-h2);
    font-weight: var(--fw-semibold);
    text-transform: uppercase;
    color: var(--theme-primary);

    &::before,
    &::after {
        content: '';
        flex: 1 0 auto;
        display: block;
        height: 2px;
        background: var(--theme-primary);
    }

    &::before {
        margin-right: 8px;
    }

    &::after {
        margin-left: 8px;
    }
}

.op-checkout__address {
    grid-area: address;
}

.op-checkout__payment {
    grid-area: payment;
    border-top: 3px solid var(--color-borders-checkout);

    @include mq($from: tablet) {
        border-top: 0;
    }
}

.op-checkout__summary {
    grid-area: summary;
    border-top: 3px solid var(--color-borders-checkout);

    @include mq($from: tablet) {
        border-top: 0;
    }
}

.op-checkout__loading-indicator {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.op-checkout__loading-indicator-text {
    font-size: 30px;
    margin-top: 20px;
}

.op-checkout__background--primary {
    --bg: var(--theme-primary);
    --color-text: var(--theme-on-primary);
    --color-highlight: var(--theme-on-primary-highlight);
    --color-headline: var(--theme-on-primary-headline);
    --color-subheadline: var(--theme-on-primary-headline);
    --color-border: var(--theme-on-primary-border);
}

.op-checkout__background--secondary {
    --bg: var(--theme-secondary);
    --color-text: var(--theme-on-secondary);
    --color-highlight: var(--theme-on-secondary-highlight);
    --color-headline: var(--theme-on-secondary-headline);
    --color-subheadline: var(--theme-on-secondary-headline);
    --color-banner: var(--bg-info-banner-secondary);
    --color-border: var(--theme-on-secondary-border);
}

.op-checkout__background--tertiary {
    --bg: var(--theme-tertiary);
    --color-text: var(--theme-on-tertiary);
    --color-highlight: var(--theme-on-tertiary-highlight);
    --color-headline: var(--theme-on-tertiary-headline);
    --color-subheadline: var(--theme-on-tertiary-headline);
    --color-banner: var(--bg-info-banner-tertiary);
    --color-banner-close: var(--color-white-true);
    --color-border: var(--theme-on-tertiary-border);
}

.op-checkout__background--default {
    --bg: var(--bg-default-checkout-cart);
    --color-text: var(--theme-on-default);
    --color-highlight: var(--theme-on-default-highlight);
    --color-headline: var(--theme-on-default-headline);
    --color-subheadline: var(--theme-on-default-subheadline);
    --color-banner: var(--bg-info-banner);
    --color-border: var(--theme-on-default-border);
}
