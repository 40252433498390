.delivery-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: var(--fw-bold);
    margin-top: 20px;
    color: var(--color-checkout-delivery-info);
}

.delivery-info__icon {
    margin-right: 5px;
    font-size: var(--fs-xl);
}
