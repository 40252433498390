@mixin full-width {
    @include mq($from: wide) {
        left: calc((var(--max-width) - var(--breakpoint-wide)) / -2);
        grid-template-columns: auto var(--breakpoint-wide) auto;
    }
}

.responsive-full-page-width-wrapper {
    &--desktop {
        @include mq($from: wide) {
            @include full-width;
            @include overflow;
        }
    }

    &--wide {
        @include mq($from: wide) {
            @include full-width;
            @include overflow;
        }
    }
}
