// Custom Properties in activite
:root {
    // Colors
    /////////////////////////////////////////////
    --theme-primary: #1e4093;
    --theme-secondary: #36a9e1;
    --theme-tertiary: #dbf1fc;
    --theme-beige: #dbf1fc;
    --theme-primary-opaque: rgb(30, 64, 147, 0.05);

    --theme-attention: #1e4093;
    --theme-cta: #ef7d00;
    --theme-cta-font-color: #fff;

    --background-product-buy-button: var(--theme-cta);
    --color-product-buy-button: var(--theme-cta-font-color);
    --newsletter-color-button: var(--color-white);

    // Hero Slider Button
    --hero-teaser-button-color: var(--theme-primary);
    --hero-teaser-slider-cta-color: var(--theme-dark);
    --hero-teaser-slider-subheadline-color: var(--theme-dark);
    --theme-on-default-subheadline: var(--theme-dark);
    --hero-teaser-button-text-color: var(--theme-cta-font-color);

    --bg-button-primary: var(--theme-cta);

    // Dots
    --color-slider-active-dot-default: var(--theme-cta);

    // CTA
    --color-teaser-right-active-box: var(--theme-tertiary);
    --color-teaser-right-box-border: var(--theme-tertiary);
    --color-teaser-cta-box: var(--theme-tertiary);

    // Footer
    --footer-font-color: var(--theme-primary);

    // PDP
    --pdp-video-thumbnail-background: var(--theme-tertiary);
    --pdp-background-buy-button: var(--theme-cta);
    --pdp-background-caption: var(--theme-tertiary);

    // Navigation
    --main-logo-height-mobile: 56px;
    --main-logo-max-height-mobile: 56px;

    // Headlines
    --theme-on-secondary-subheadline: var(--theme-dark);
    --theme-on-tertiary-subheadline: var(--theme-dark);
}
