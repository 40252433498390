.ambiente-shopping {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 24px;
    padding: 20px;

    @include mq($until: desktop) {
        flex-direction: column;
    }

    > div {
        border-radius: var(--border-radius-default);
    }

    &--with-box-shadow {
        > div {
            box-shadow: var(--box-shadow-default);
        }
    }

    &--with-square-corners {
        > div {
            border-radius: 0;
        }

        .ambiente-shopping__image-wrapper img {
            border-radius: 0;
        }
    }
}

.ambiente-shopping__image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    flex: 2 1 0;
}

.ambiente-shopping__marker {
    background-color: var(--theme-primary);
    border-radius: 100%;
    position: absolute;
    cursor: pointer;
    text-align: center;
    color: #fff;
    user-select: none;
    z-index: 1;
    box-shadow: var(--box-shadow-default);

    &--open {
        transform: rotate(45deg);
        z-index: 4;
    }
}

.ambiente-shopping__marker--gray {
    background-color: var(--ambiente-marker-inactive-background-color);
}

.ambiente-shopping__image-wrapper img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: var(--border-radius-default);
}

.ambiente-shopping__image-wrapper .tooltip {
    display: flex;
    background-color: var(--color-white-true);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 162px;
    gap: 10px;
    position: absolute;
    border-radius: var(--border-radius-default);
    text-align: center;
    padding: 12px 8px;
    z-index: var(--zi-btn);
}

.ambiente-shopping__tooltip-title {
    color: var(--ambiente-tooltip-title-color);
    font-size: var(--ambiente-tooltip-title-font-size);
    font-weight: var(--fw-bold);
    line-height: var(--lh-small);
}

.ambiente-shopping__tooltip-link {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: var(--ambiente-tooltip-link-color);
    cursor: pointer;
}

.ambiente-shopping__tooltip-price .price-box__price span {
    color: var(--ambiente-tooltip-price-color);
}

.ambiente-shopping__image-wrapper .tooltip-hide {
    display: none;
}

.ambiente-shopping__card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 10px;
    flex: 1 1 0;
    background: #fff;

    .headlines {
        padding: 0;

        .headlines__headline {
            margin-bottom: 8px;
        }
    }

    p {
        margin-top: 0;
    }
}

.ambiente-shopping__card-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
