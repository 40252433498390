.social-share {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
}

.social-share__button-container {
    display: flex;
    justify-content: space-around;
}

.social-share__button {
    width: 80px;
    height: 80px;
    min-width: 80px;

    @include mq($from: tablet) {
        flex-basis: auto;
        min-width: 200px;
        min-height: 30px;
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.social-share__button-icon {
    height: 20px;
    width: 20px;
    color: var(--color-social-icon);

    @include mq($from: tablet) {
        height: 50px;
        width: 50px;
        margin-right: 15px;
    }
}

.social-share__button-text {
    display: none;
    text-transform: none;
    font-size: var(--fs-base);
    color: var(--color-social-text);

    @include mq($from: tablet) {
        display: inline;
    }
}

.social-share__button-mobile {
    @include mq($from: tablet) {
        display: none;
    }
}
