.password-forgotten {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: auto;
    padding: 0 16px;

    @include mq($from: tablet) {
        padding: 0;
    }
}

.password-forgotten__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.password-forgotten__title {
    padding: 20px 0;

    @include mq($until: tablet) {
        padding: 10px 0;
    }
}

.password-forgotten__title .headlines__headline {
    color: var(--theme-primary);
}

.password-forgotten__title .headlines__subheadline {
    font-size: 18px;
}

.password-forgotten__description {
    margin-bottom: 10px;
}

.password-forgotten__input-field {
    padding: 15px 0;
    cursor: pointer;
}

.password-forgotten__login-link {
    text-align: center;
    margin-bottom: 16px;

    a {
        text-decoration: underline;
        font-size: 14px;
        color: var(--color-black-true);
    }
}

.password-forgotten__btn {
    margin-bottom: 12px;
}

.password-forgotten__reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 420px;
    margin: 16px;

    @include mq($from: tablet) {
        margin: 0 auto;
    }
}

.password-forgotten__reset-title {
    font-size: var(--fs-h2);
    font-weight: var(--fw-semibold);
    color: var(--theme-primary);
    padding: 20px 0 0;

    @include mq($until: tablet) {
        padding: 10px 0;
    }
}

.password-forgotten__reset-tips {
    padding-bottom: 20px;
}

.password-forgotten__reset-tips-title {
    font-size: 14px;
    font-weight: var(--fw-semibold);
    padding-bottom: 10px;
}

.password-forgotten__reset-tips li {
    padding-left: 10px;
}

.password-forgotten__reset-description {
    margin: 10px 0 20px;
}

.password-forgotten__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(138, 138, 138, 0.3);
    z-index: 2;
}

.password-forgotten__loader {
    width: 120px;
    height: 120px;
    border: 16px solid var(--theme-tertiary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.password-forgotten__full-name {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
