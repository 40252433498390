.polaroid {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 40px;
}

.polaroid:nth-child(odd) {
    align-items: flex-start;

    @include mq($from: tablet) {
        flex-direction: column-reverse;
    }

    .polaroid__image-container {
        border-left-width: 0;

        @include mq($from: tablet) {
            border-left-width: 20px;
        }
    }
}

.polaroid:nth-child(even) {
    align-items: flex-end;

    .polaroid__image-container {
        border-right-width: 0;

        @include mq($from: tablet) {
            border-right-width: 20px;
        }
    }
}

.polaroid__image-container {
    border: var(--color-border-polaroid) 20px solid;
    width: 90%;

    img {
        width: 100%;
    }

    @include mq($from: tablet) {
        width: 100%;
    }
}

.polaroid__content {
    padding: 20px;
    width: 90%;

    @include mq($from: tablet) {
        width: 100%;
    }
}

.polaroid__title {
    font-weight: var(--fw-bold);
    font-size: var(--fs-h3);
}
