.search-bar {
    width: 100%;
    grid-column: 2;
    grid-row: 1;
    grid-area: searchBar;

    @include mq($from: desktop) {
        padding: 8px 0;
    }
}

.search-bar__input-container {
    position: relative;
    z-index: var(--zi-search-bar);
}

.search-bar__wrapper {
    position: relative;
    width: 100%;
    grid-column: 2;
    grid-row: 1;
    grid-area: searchBar;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

body.scroll-lock--overflow {
    @include mq($until: tablet) {
        overflow: hidden;
        height: 100%;
    }

    @include mq($from: tablet) {
        &::after {
            opacity: 1;
            pointer-events: unset;
        }
    }
}

body.scroll-lock--search-bar {
    position: relative;

    @include mq($until: tablet) {
        overflow: hidden;
    }

    @include mq($from: tablet) {
        &::after {
            opacity: 1;
            pointer-events: unset;
        }
    }
}

body.scroll-lock--search-bar-custom {
    position: relative;

    @include mq($from: desktop) {
        &::after {
            opacity: 1;
            pointer-events: unset;
        }
    }
}

body.scroll-lock--modal {
    position: fixed;
    inset: 0;
}

body::after {
    content: '\A';
    position: absolute;
    overflow: visible;
    inset: 0;
    pointer-events: none;
    top: var(--overlay-background-top);
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    z-index: var(--zi-search-backdrop);
}

.search-bar__close-icon {
    display: flex;
    cursor: pointer;
    background: none;
    border: none;
    width: 46px;
    height: 46px;
    justify-content: center;
    align-items: center;
}

.search-bar__search-overlay-portal {
    z-index: var(--zi-search-overlay-portal);
    background-color: var(--modal-overlay-bg-color);
    position: fixed;
    inset: 0;
    height: 100%;

    .search-bar {
        background: var(--color-white-true);
        border-bottom: 1px solid var(--theme-primary);
    }

    .search-bar__input-container {
        display: flex;
        padding: 8px 0;
    }

    .search-bar__input {
        position: relative;
        width: 100%;
    }

    .search-input__input--autocomplete {
        position: absolute;
    }

    &--custom {
        .search-suggestion__autosuggest {
            top: 63px;
        }
    }
}
