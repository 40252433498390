.video-with-text {
    padding: 30px 0 10px;

    &:has(.video-with-text__top-headline) {
        padding: 10px 0;
    }

    > div {
        padding-bottom: 20px;
    }

    &:has(.video-with-text__video-headline) {
        .video-with-text__video-wrapper {
            padding-bottom: 0;
        }
    }
}

.video-with-text__text {
    padding: 0 20px;

    @include mq($from: tablet) {
        padding: 0;
    }

    p {
        margin: 0 0 1rem;

        &:last-child {
            margin: 0;
        }
    }
}
