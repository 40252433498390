.special-deal-countdown {
    width: 100%;
}

.special-deal-countdown__digits {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: var(--color-white-true);
    font-size: var(--fs-l);
    font-weight: var(--fw-semibold);

    :last-child::after {
        display: none;
    }

    sup {
        font-size: 55%;
        position: relative;
        vertical-align: sub;
        margin-left: 4px;
    }
}

.special-deal-countdown__time {
    &::after {
        content: ' ';
        position: absolute;
        height: 22px;
        width: 1px;
        background: var(--color-gray-600);
        transform: translate(12px, 3px);
    }
}

.special-deal-countdown__expired {
    font-style: italic;
    font-size: 15px;
    text-align: center;
    color: var(--color-white-true);
}
