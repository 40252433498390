.logo-slider {
    padding-bottom: 20px;
}

.logo-slider__slider-wrapper {
    display: flex;
    --swiper-theme-color: var(--theme-primary);

    @include mq($from: tablet) {
        position: relative;
        align-items: center;
    }

    .swiper-container {
        width: 100%;
    }

    .swiper-slide-next,
    .swiper-slide-prev {
        opacity: 1;
    }
}
