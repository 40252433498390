.separator__space-above {
    height: 20px;
    background: #fff;
}

.separator__line {
    height: 1px;
    background: #ebe4d9;
}

.separator__space-below {
    height: 20px;
    background: #fff;
}
