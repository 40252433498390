.product-suggestions__autosuggest-result {
    display: flex;
    margin-bottom: 10px;

    & img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }
}

.product-suggestions__search-result-text {
    font-size: var(--fs-base);
    font-weight: var(--fw-semibold);
    color: var(--theme-primary);
}
